import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector } from 'reselect';
import 'video-react/dist/video-react.css';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  deleteEvaluation,
  getRubricForTask,
  getTaskResponse,
  getTaskResponseEvaluations,
  getTaskResponseEvaluationsByReviewer,
  getTranscript,
  taskResponseSelected,
  updateEvaluationComments,
} from '../../actions/campusApiActions';
import { primaryColor } from '../../config/constants';
import {
  Attempt,
  Comment,
  EvaluationBasic,
  IActivityLog,
  TaskResponseBasic,
  getAttemptVideoUrl,
  getTranscriptText,
  isSubmitted,
  isUploaded,
} from '../../model/ApiTypes';
import { DURATION_UNKNOWN, TIME_INITIAL, formatCampusLongDate, seconds2mssmm } from '../../utils/utils';
import StudentTaskPanel from './StudentTaskPanel';

interface IProps extends PropsFromRedux {
  match: any;
  history: any;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  mainVideoURL: string;
  mainAttemptIndex: number;
  evalExpanded: boolean[];
  videoDuration: number;
  currentTime: number;
  videoComponent4SubmittedAttempt: any;
}

class StudentTaskResults extends React.PureComponent<IProps, IState> {
  static NO_EVALUATION_YET: EvaluationBasic[] = [];
  qMsg1 = () =>
    `${this.props.currentResponse.user.id} - ${this.props.currentResponse.user.username} - ${this.props.currentResponse.user.email}    `;

  qMsg3 = () => `Question`;
  qTranscript = () => `Transcript of the Submitted Video`;

  qEndMsg = () => (this.props.uploadedAttempts.length ? `Attempts` : null);

  onViewSubmission = (url, attemptIndex) => (event) => {
    if (this.state.mainAttemptIndex !== attemptIndex) {
      event.preventDefault();
      event.stopPropagation();
      this.videoComponents4Attempts.forEach((video) => {
        if (video && !video.paused) {
          video.pause();
        }
      });
      this.setState({ mainVideoURL: url, mainAttemptIndex: attemptIndex });
    }
  };

  constructor(props: IProps) {
    super(props);
    console.log('StudentTaskResults ctor,  props ', props);
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    return {
      mainAttemptIndex: -1,
      mainVideoURL: null,
      evalExpanded: null,
      videoComponent4SubmittedAttempt: React.createRef(),
      videoDuration: DURATION_UNKNOWN,
      currentTime: TIME_INITIAL,
      videoComponents4Attempts: [],
    };
  };

  componentDidMount = () => {
    this.load(this.props.match.params.id);
  };
  componentWillUnmount = () => {
    console.log('StudentTaskResults will unmount ', this.props);
    this.cleanupOnExitTask();
  };

  cleanupOnExitTask = () => {};
  crtGridRef = null;
  videoComponents4Attempts: any[] = [];
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      console.log('TaskResults will load another task ID ', id);
      this.cleanupOnExitTask();
      this.load(id);
    }

    if (this.props.evaluations !== prevProps.evaluations || this.props.taskRubric !== prevProps.taskRubric) {
      if (this.props.evaluations && this.props.taskRubric) {
        const res = new Array(this.props.evaluations.length);
        res.fill(false);
        this.setState({ evalExpanded: res });
      } else {
        this.setState({ evalExpanded: null });
      }
    }
    if (
      this.state.videoComponent4SubmittedAttempt &&
      this.state.videoComponent4SubmittedAttempt !== prevState.videoComponent4SubmittedAttempt
    ) {
      this.state.videoComponent4SubmittedAttempt.addEventListener('timeupdate', (event) => {
        this.setState({ currentTime: this.state.videoComponent4SubmittedAttempt.currentTime });
      });
    }
    if (prevState.currentTime !== this.state.currentTime) {
      if (!this.crtGridRef) return;
      let nodeToScrollTo: Element = null;
      let node: any = null;
      let scrollIdx = -1;
      console.log('this.infoForScrolling ', this.infoForScrolling);

      if (this.infoForScrolling.mostRecentCommentTime >= 0) {
        scrollIdx = this.infoForScrolling.mostRecentCommentIdx;
      } else if (this.infoForScrolling.nextCommentIdx >= 0) {
        scrollIdx = this.infoForScrolling.nextCommentIdx;
      }
      if (scrollIdx >= 0) {
        const selector = this.buildCommentItemElementId(scrollIdx);
        node = this.crtGridRef.querySelector(`#${selector}`);
        if (node) {
          if (node.length > 1) {
            nodeToScrollTo = node[0];
          } else {
            nodeToScrollTo = node;
          }
          console.log('should scrollIntoView ', nodeToScrollTo);
          nodeToScrollTo.scrollIntoView({
            block: 'nearest',
          });
        }
      }
    }
    // if (
    //   this.props.uploadedAttempts &&
    //   (!prevProps.uploadedAttempts || prevProps.uploadedAttempts.length !== this.props.uploadedAttempts.length)
    // ) {
    //   const arr = new Array(this.props.uploadedAttempts.length);
    //   arr.fill(null);
    //   this.videoComponents4Attempts = arr;
    // }
    if (this.props.uploadedAttempts !== prevProps.uploadedAttempts) {
      if (this.props.uploadedAttempts) {
        this.videoComponents4Attempts = new Array(this.props.uploadedAttempts.length);
      } else {
        this.videoComponents4Attempts = [];
      }
    }
  }

  load(id) {
    this.props.taskResponseSelected(id);
    this.props.getTaskResponse(id, this.props.authentication.token).then((taskResponse: TaskResponseBasic) => {
      if (taskResponse != null && taskResponse.task != null) {
        this.props.getRubricForTask(taskResponse.task.id, this.props.authentication.token);
        const submitted = getSubmittedAttemptINfo(taskResponse);
        if (submitted.attempt) {
          this.props.getTranscript(id, submitted.attempt, this.props.authentication.token);
          this.setState({ mainVideoURL: getAttemptVideoUrl(submitted.attempt) });
        }
      }
    });
    this.props.getTaskResponseEvaluations(id, this.props.authentication.token);
  }

  render() {
    let usefullHeight = window.innerHeight - 250;
    console.log('StudentTaskResults render, usefull height ', usefullHeight, this.props);
    return this.props.currentResponse ? (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Task</span>
          </div>
          <div className="row w-100">
            <div className="col-12 no-padding" style={{ height: usefullHeight + 110, overflowY: 'auto' }}>
              <div className="fig-generic-pane-right-side  ">
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400, maxHeight: usefullHeight + 110 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '50%', maxWidth: '70%' }}>
                      <span>{this.props.currentResponse.task.type}</span>
                      <span>{this.props.currentResponse.task.course.code}</span>
                      <span>{this.props.currentResponse.task.name}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <span style={{ marginRight: 10 }}>Timer Started @ {formatCampusLongDate(this.props.currentResponse.starttime)}</span>
                    </div>
                  </div>
                  <>
                    <b>
                      <br />
                      {this.qMsg1()}
                      {/* {' ' + formatCampusLongDate(this.props.currentResponse.starttime)} */}
                    </b>
                    <br />
                    <br />
                    <b>{this.qMsg3()}</b>
                    <br />
                    <br />
                    {this.props.currentResponse.question ? this.props.currentResponse.question.text : ''}
                    <br />
                    <br />
                    <b>{this.qTranscript()}</b>
                    <br />
                    <br />
                    {this.props.transcript ? this.props.transcript : 'Transcription in Progress'}
                    <br />
                    <br />
                  </>
                  {this.props.evaluations
                    ? this.props.evaluations.map((evaluation, idx) => this.renderRubricEvaluation(evaluation, idx))
                    : null}
                  {this.renderAttempts()}
                  {this.renderActivityLogs()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  getMainButtonInfo = () => {};

  renderAttempts() {
    return (
      <Accordion key={'summary_attempts'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Attempts</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div>
              <div className="w-100 h-100">
                <Grid container spacing={5} style={{ /*overflowY: 'auto',*/ alignItems: 'center' }}>
                  {this.props.uploadedAttempts.map((attempt, idx) => this.renderAttempt(attempt, idx))}
                </Grid>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderAttempt = (attempt: Attempt, index: number) => {
    const submitted = isSubmitted(attempt);
    const url = getAttemptVideoUrl(attempt);
    const isShowing = url === this.state.mainVideoURL;

    const isSelected = this.state.mainAttemptIndex === index;
    return (
      <Grid item key={url} xs={12} sm={4} md={3}>
        <div className="textarea-box">
          <div className="w-100 h-100">
            <div className="row no-gutters h-100">
              <div className="col-12">
                <div
                  className="no-padding"
                  style={{
                    flex: `0 0 ${100}%`,
                    visibility: 'visible',
                  }}
                >
                  <div onClick={this.onViewSubmission(url, index)}>
                    <video
                      style={
                        isShowing
                          ? {
                              borderWidth: 3,
                              borderStyle: 'solid',
                              borderColor: `rgba(155, 167, 188, 0.5) `, //TODO GINA ffs button border color scss
                              borderRadius: 4,
                              // cursor: 'pointer',
                            }
                          : { cursor: 'pointer' }
                      }
                      ref={(internalComp) => {
                        if (submitted) {
                          this.setState({ videoComponent4SubmittedAttempt: internalComp });
                        }
                        this.videoComponents4Attempts[index] = internalComp;
                      }}
                      src={url}
                      // style={{ border: 1, borderStyle: 'solid', borderColor: 'red', margin: 15, padding: 15 }}
                      width={'100%'}
                      autoPlay={false}
                      controls={isSelected}
                      muted={false}
                    ></video>
                  </div>

                  <div className="button-holder w-100">
                    <Button
                      className="fig-button in-modal-dialog  w-100"
                      variant="outlined"
                      onClick={this.onViewSubmission(url, index)}
                      disabled={isSelected}
                    >
                      {/* {`${!isSelected ? 'View' : ''} ${submitted ? 'Submitted' : `Attempt #${index + 1}`}`} */}
                      {`${submitted ? 'Submitted' : `Attempt #${index + 1}`}`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  getAverageGrade = (evaluation: EvaluationBasic) => {
    if (evaluation && this.props.taskRubric) {
      const res =
        this.props.taskRubric.questions.reduce((accumulator, question, idx) => accumulator + question.weight * evaluation.rubric[idx], 0) /
        this.getScore();
      const hasNull = evaluation.rubric.indexOf(null) != -1;
      return hasNull || isNaN(res) || res === 0 /*FFS 0 should be allowed but cases*/ ? null : res;
    } else {
      return null;
    }
  };

  getScore = () => {
    if (this.props.taskRubric) {
      return this.props.taskRubric.questions[0].score;
    } else {
      return null;
    }
  };

  renderRubricEvaluation(evaluation: EvaluationBasic, evalIdx: number) {
    const rubric = this.props.taskRubric;
    const globalScore = this.getScore();
    const averageGrade = this.getAverageGrade(evaluation);
    return (
      <Accordion
        key={`evaluation_${evaluation.id}`}
        className="fig-accordion"
        expanded={this.state.evalExpanded && this.state.evalExpanded[evalIdx]}
        onChange={() => {
          if (this.state.evalExpanded) {
            const neweval = [...this.state.evalExpanded];
            neweval[evalIdx] = !neweval[evalIdx];
            this.setState({ evalExpanded: neweval });
          }
        }}
      >
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Evaluation by {evaluation.user ? evaluation.user.username : 'UNKNOWN'}</b>
          </span>
          &nbsp;
        </AccordionSummary>

        <AccordionDetails>
          <div className="col-12 fig-accordion-details no-padding">
            <div className="row">
              <div className="col-7 fig-video-details-matrix-main-container">
                <div className="row no-gutters h-100">
                  <div className="col-3 no-padding">
                    <div className=" no-padding fig-evaluation-header ">
                      <div>Grade</div>
                    </div>
                    <div className="average-grade-container">
                      {averageGrade}
                      {averageGrade != null ? (globalScore === 100 ? '%' : `/ ${globalScore}`) : ''}
                    </div>
                  </div>
                  <div
                    className="col-9 no-padding fig-evaluation-header"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                  >
                    <div className="row">
                      <div>Rubric</div>
                      {rubric && rubric.questions ? (
                        <div className="row">
                          {rubric.questions.map((question, idxQuestion) =>
                            this.renderQuestionDetails(idxQuestion, question.text, question.weight, question.score, evaluation, evalIdx)
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 fig-video-details-matrix-main-container">{this.renderCommentsInEval(evaluation, evalIdx)}</div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderCommentsInEval(evaluation: EvaluationBasic, evalIdx) {
    this.infoForScrolling = { ...StudentTaskResults.noInfoForScrolling };

    const isShowingTheSubmittedAttempt = this.props.submittedAttempt
      ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
      : false;
    if (!isShowingTheSubmittedAttempt) this.crtGridRef = null;
    return (
      <div className="row  no-gutters h-100">
        <div className="col-12 no-padding fig-evaluation-header ">
          {/* <div>Shown : {isShowingTheSubmittedAttempt ? 'Submitted' : `Attempt #${this.state.mainAttemptIndex + 1}`} video</div> */}
          <div>Comments</div>
          {isShowingTheSubmittedAttempt ? (
            <div
              ref={(e) => {
                this.crtGridRef = e;
              }}
              style={{ height: 160, overflowY: 'auto', direction: 'rtl' }} //FFS workaround, to move the scrollbar to the left, far from the main container's one
              // TODO FFS ADI height
            >
              {evaluation && evaluation.comments
                ? evaluation.comments.map((comment, idx) => {
                    const ctime = comment.time;
                    if (ctime > this.infoForScrolling.mostRecentCommentTime && ctime <= this.state.currentTime) {
                      this.infoForScrolling.mostRecentCommentTime = ctime;
                      this.infoForScrolling.mostRecentCommentIdx = idx;
                    }
                    if (ctime < this.infoForScrolling.nextCommentTime && ctime >= this.state.currentTime) {
                      this.infoForScrolling.nextCommentTime = ctime;
                      this.infoForScrolling.nextCommentIdx = idx;
                    }
                    const isShowingTheSubmission = this.props.submittedAttempt
                      ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
                      : false;
                    return (
                      <div
                        className="row "
                        style={{ direction: 'ltr' }} //FFS workaround, tohave  the scrollbar to the left, far from the main container's one, but nomal ltr writing
                        key={comment.time + '_' + idx}
                        id={this.buildCommentItemElementId(idx)}
                      >
                        {this.renderComment(comment, isShowingTheSubmission)}
                      </div>
                    );
                  })
                : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  buildCommentItemElementId = (nth: number) => {
    return `Comments_${nth}`;
  };
  private static noInfoForScrolling = {
    mostRecentCommentTime: -1,
    mostRecentCommentIdx: -1,
    nextCommentTime: 1000000,
    nextCommentIdx: -1,
  };
  infoForScrolling = { ...StudentTaskResults.noInfoForScrolling };

  renderComment = (item: Comment, isShowingTheSubmission: boolean) => {
    let isPlaying = false;
    if (this.state.videoComponent4SubmittedAttempt) {
      // console.log('render comment, crtTime ', this.state.currentTime);
      isPlaying = item.time >= Math.floor(this.state.currentTime) && item.time < Math.floor(this.state.currentTime) + 1;
    }
    if (isShowingTheSubmission) {
      const style = isPlaying
        ? {
            color: primaryColor,
            cursor: 'pointer',
          }
        : {
            cursor: 'pointer', //'#050505'
          };
      return (
        <div>
          <Tooltip title={`Go To ${seconds2mssmm(item.time)}`}>
            <span onClick={this.gotoCommentMomment(item)} style={style}>
              {seconds2mssmm(item.time)}
            </span>
          </Tooltip>
          &nbsp;&nbsp; - &nbsp;&nbsp;
          <Tooltip title={`Go To ${seconds2mssmm(item.time)}`}>
            <span onClick={this.gotoCommentMomment(item)} style={style}>
              {item.text}
            </span>
          </Tooltip>
        </div>
      );
    } else {
      const style = isPlaying
        ? {
            color: primaryColor,
          }
        : {};

      return (
        <div>
          <span className="item" style={style}>
            {seconds2mssmm(item.time)}
          </span>
          &nbsp;&nbsp; - &nbsp;&nbsp;
          <span className="item" style={style}>
            {item.text}
          </span>
        </div>
      );
    }
  };
  gotoCommentMomment = (comment: Comment) => (event) => {
    const isShowingTheSubmittedAttempt = this.props.submittedAttempt
      ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
      : false;

    if (isShowingTheSubmittedAttempt) {
      if (this.state.videoComponent4SubmittedAttempt) {
        this.state.videoComponent4SubmittedAttempt.currentTime = comment.time; //seek the video
      }
    }
  };

  renderQuestionDetails = (
    idxQuestion: number,
    question: string,
    weight: number,
    rubricScore: number,
    evaluation: EvaluationBasic,
    evalIdx: number
  ) => {
    return this.renderQuestionWScore(
      idxQuestion,
      question,
      weight,
      rubricScore,
      evaluation && evaluation.rubric ? evaluation.rubric[idxQuestion] : null
    );
  };

  renderQuestionWScore = (idxQuestion: number, question: string, weight: number, rubricScore: number, grade: number) => {
    let itemToRender = (
      <div className="row w-100 fig-question-row" key={`_${idxQuestion}`}>
        <div className="col-7 no-padding fig-question-text ">
          <div className="item">
            <b>{idxQuestion + 1}.</b> {question} &nbsp;(weight {weight}%)
          </div>
        </div>
        <div className="col-5 no-padding fig-matrix-scores">{this.renderGradePerScore(grade, rubricScore, idxQuestion)}</div>
      </div>
    );
    return itemToRender;
  };

  renderGradePerScore(grade, rubricScore: number, idxQuestion) {
    return (
      <>
        <TextField
          className="col-6 fig-modal-dialog-input  no-padding"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          value={grade == null || isNaN(grade) ? '' : grade}
          required
          disabled
          inputProps={{ style: { textAlign: 'right' } }}
        />
        <TextField
          className="col-6  fig-modal-dialog-input  no-padding"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          disabled
          value={'/ ' + rubricScore}
          inputProps={{ readOnly: true }}
        />
      </>
    );
  }

  renderActivityLogs() {
    return (
      <Accordion key={'summary_logs'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Activity Logs</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            {this.props.activity.map((item, idx) => (
              <div className="row" key={item.time + '_' + idx}>
                <span>{item.time}</span>&nbsp;&nbsp; - &nbsp;&nbsp;<span>{item.message}</span>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const selectorCrtResponse = (state: IRootState) =>
  state.taskResponses.selectedResponseId && state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    ? state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    : null;

const retrieveUploadedAttempts = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return StudentTaskPanel.EMPTY_ATTEMPTS;
  const res = crtResponse.attempts.filter((attempt) => isUploaded(attempt));
  return res.length > 0 ? res : StudentTaskPanel.EMPTY_ATTEMPTS;
});

const retrieveSubmittedAttempt = createSelector([selectorCrtResponse], (crtResponse) => getSubmittedAttemptINfo(crtResponse));

const getSubmittedAttemptINfo = (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return { attempt: null, position: -1 };
  let position = -1;
  const res = crtResponse.attempts.find((attempt, index) => {
    if (isSubmitted(attempt)) {
      position = index;
      return true;
    } else return false;
  });
  return { attempt: res ? res : null, position };
};

const selectorCrtTranscript = (state: IRootState) =>
  state.taskResponses.selectedResponseId && state.taskResponses.responseTranscriptMap[state.taskResponses.selectedResponseId]
    ? state.taskResponses.responseTranscriptMap[state.taskResponses.selectedResponseId]
    : null;

const retrieveTranscript = createSelector([selectorCrtTranscript], (transcriptResult) => {
  if (transcriptResult == null) return null;
  else return getTranscriptText(transcriptResult);
});

const getActivityLogs = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null) return StudentTaskPanel.EMPTY_LOGS;
  const res = [] as IActivityLog[];
  if (crtResponse.starttime) {
    res.push({ message: 'Started Task', time: new Date(crtResponse.starttime).toLocaleString() });
  }
  if (!crtResponse.attempts || crtResponse.attempts.length === 0) return res;

  let idxSubmitted = null;
  crtResponse.attempts.forEach((attempt, index) => {
    if (attempt.starttime) res.push({ message: `Started Attempt #${index + 1}`, time: new Date(attempt.starttime).toLocaleString() });
    if (attempt.stoptime) res.push({ message: `Completed Attempt #${index + 1}`, time: new Date(attempt.stoptime).toLocaleString() });
    if (attempt.uploadtime) res.push({ message: `Uploaded Attempt #${index + 1}`, time: new Date(attempt.uploadtime).toLocaleString() });
    if (idxSubmitted == null && isSubmitted(attempt)) {
      idxSubmitted = index;
    }
  });
  if (crtResponse.submissiontime != null && idxSubmitted != null) {
    res.push({ message: `Submitted Attempt #${idxSubmitted + 1}`, time: new Date(crtResponse.submissiontime).toLocaleString() });
  }

  return res;
});
const getEvaluations = (state: IRootState) => {
  let res = state.taskResponses.responseEvaluationsMap
    ? Object.values(state.taskResponses.responseEvaluationsMap).sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA < dateB ? -1 : dateB == dateA ? 0 : 1;
      })
    : null;
  if (res && res.length > 0) {
    return res;
  } else if (res) {
    return StudentTaskResults.NO_EVALUATION_YET;
  } else return null;
};

const mapStateToProps = (state: IRootState) => {
  console.log('StudentTaskResults mapStateToProps ', state, state.utils.windowHeight);
  return {
    authentication: state.authentication,
    windowHeight: state.utils.windowHeight,
    currentResponse: selectorCrtResponse(state),
    uploadedAttempts: retrieveUploadedAttempts(state),
    submittedAttempt: retrieveSubmittedAttempt(state).attempt,
    submittedIndex: retrieveSubmittedAttempt(state).position,
    transcript: retrieveTranscript(state),
    activity: getActivityLogs(state),
    evaluations: getEvaluations(state),

    taskRubric: state.courses.taskRubric,
    currentResponseId: state.taskResponses.selectedResponseId,
  };
};

const mapDispatchToProps = {
  setGlobalAlert,
  taskResponseSelected,
  getTaskResponse,
  getTranscript,
  getTaskResponseEvaluations,
  getTaskResponseEvaluationsByReviewer,
  getRubricForTask,
  updateEvaluationComments,
  deleteEvaluation,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(StudentTaskResults);

export const getDefaultDeadline = () => {
  return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
};
