// {
//   analysisIdx: 0,
//   stream: 'joy',
//   analysisTag: 'Biden',

import {
  AUTH_CLEAR,
  AuthClearAction,
  SETTINGS_DATAGROUPS,
  SETTINGS_GLOBAL_RUBRICS,
  SettingsActionTypes,
} from '../actions/campusApiActions/types';
import { fakeRubric } from '../model/ApiTypes';
import { RubricsMap } from './rubricsReducer';

// },
const initialState = {
  // all categories defined in datagroups

  globalRubricsMap: null as RubricsMap,
};

export type SettingsState = Readonly<typeof initialState>;

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: SettingsState = initialState, action: SettingsActionTypes | AuthClearAction): SettingsState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case SETTINGS_DATAGROUPS:
      return {
        ...state,
      };

    case SETTINGS_GLOBAL_RUBRICS: {
      const map = {};
      action.payload.rubrics.forEach((rubric, idx) => {
        let id = generateFakeRubricId(idx);
        map[id] = fakeRubric(rubric, id);
      });
      return {
        ...state,
        globalRubricsMap: map,
      };
    }

    default:
      return state;
  }
};
export const generateFakeRubricId = (idx: number) => {
  return -1 - idx;
};
export const isGlobalRubric = (rubricId: number) => {
  return rubricId < 0;
};
