import { Button } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { deleteMembership, getCoursesMemberships, getTaskResponsesForUser } from '../../actions/campusApiActions';
import {
  ADMIN,
  API_ROLE_ID_LEAD,
  CONTINUE,
  getResponseStateAsStudent,
  INCOMPLETE,
  INSTRUCTOR,
  NOT_STARTED,
  REVIEWER,
  STUDENT,
  SUBMITTED,
} from '../../model/ApiTypes';
import CoursesTable from './CoursesTable';
import TasksResponsesTable from './TasksResponsesTable';

interface IProps extends PropsFromRedux {
  history: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

class Dashboard extends React.PureComponent<IProps, IState> {
  componentDidMount = () => {
    this.props.getTaskResponsesForUser(this.props.authentication.user.id, this.props.authentication.token);
    this.props.getCoursesMembership(this.props.authentication.user.id, this.props.authentication.token);
  };
  componentWillUnmount = () => {
    console.log('Dashboard will unmount ', this.props);
  };

  render() {
    let usefullHeight = window.innerHeight - 125;
    console.log('TaskForm render, usefull height ', usefullHeight, this.props);

    console.log('Dashboard render ', this.props);

    const responses = this.props.taskResponses;
    const responseRows = responses
      ? Object.keys(responses).map((taskId) => {
          const taskReq = responses[taskId].task;
          return {
            id: taskId,
            name: taskReq.name,
            ends_at: taskReq.endtime,
            starts_at: taskReq.starttime,
            type: taskReq.type,
            course: taskReq.course.code,
            submittedID: responses[taskId].submissiontime,
            submitted2: responses[taskId].submissiontime,
            state: getResponseStateAsStudent(responses[taskId]),
            taskOptions: taskReq.options,
          };
        })
      : [];
    const combinedCoursesRows = this.getCombinedCoursesMembershipRows();
    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Dashboard</span>
          </div>
          <div className="fig-generic-pane-right-side" key="header1" style={{ height: usefullHeight, overflowY: 'auto' }}>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div
                  className="row"
                  style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '100%', paddingRight: '20px' }}
                >
                  <span>Current Courses</span>

                  {this.props.authentication.role && API_ROLE_ID_LEAD === this.props.authentication.role.id ? (
                    <Button
                      className={'fig-button in-modal-dialog'}
                      variant="outlined"
                      onClick={() => {
                        this.props.history.push('/addcourse');
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add a
                      Course&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="fig-rubrics-table-holder" key="courses">
              <CoursesTable
                rows={combinedCoursesRows}
                handleViewCourse={this.viewCourse}
                onDeleteMembership={this.deleteMembership}
              ></CoursesTable>
              <br />
            </div>

            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '30%', maxWidth: '50%' }}>
                  <span>Your Tasks</span>
                </div>
              </div>
            </div>
            <div className="fig-rubrics-table-holder" key="tasks">
              <TasksResponsesTable rows={responseRows} handleRespond={this.respond}></TasksResponsesTable>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCombinedCoursesMembershipRows = () => {
    const coursesRows = this.props.membership
      ? Object.keys(this.props.membership).map((memberId) => {
          const member = this.props.membership[memberId];
          const courseAttrib = member.attributes.course.data.attributes;
          return {
            name: courseAttrib.name,
            code: courseAttrib.code,
            courseId: member.attributes.course.data.id,
            semester: courseAttrib.semester,
            role: member.attributes.role,
            id: memberId,
          };
        })
      : [];

    const studentMemberships = {};
    const combinedMemberships = {};
    coursesRows.forEach((membershipRow) => {
      if (membershipRow.role === STUDENT) {
        studentMemberships[membershipRow.courseId] = { ...membershipRow, role: [membershipRow.role] };
        delete studentMemberships[membershipRow.courseId].id;
      } else {
        const existing = combinedMemberships[membershipRow.courseId];
        if (existing) {
          combinedMemberships[membershipRow.courseId] = { ...existing, role: [...existing.role, membershipRow.role] };
        } else {
          combinedMemberships[membershipRow.courseId] = { ...membershipRow, role: [membershipRow.role] };
          delete combinedMemberships[membershipRow.courseId].id;
        }
      }
    });

    const combinedCoursesRows = [...Object.values(combinedMemberships), ...Object.values(studentMemberships)];
    //sort by course name/code/id; for the same id, Student roles after other roles.
    combinedCoursesRows.sort((a: any, b: any) => {
      if (a.courseName < b.courseName) return -1;
      else if (a.courseName > b.courseName) return 1;
      else {
        if (a.courseId === b.courseId) {
          if (a.role.includes(STUDENT)) return 1;
          else return -1;
        } else {
          if (a.courseCode > b.courseCode) return 1;
          else if (a.courseCode < b.courseCode) return -1;
          else return a.courseId - b.courseId;
        }
      }
    });
    return combinedCoursesRows;
  };
  createTask = () => {
    this.props.history.push(`/tasks/new`);
  };
  deleteTask = (id) => {
    // this.props.deleteTask(id, this.props.authentication.token);
  };
  deleteMembership = (id) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.deleteMembership(id, this.props.authentication.token);
  };

  viewCourse = (courseId: number, role: string[]) => {
    if (!role) return;
    // if (role.includes(INSTRUCTOR) || role.includes(ADMIN)) {
    //   this.props.history.push(`/instructorcourses/${courseId}`);
    // } else if (role.includes(REVIEWER)) {
    //   this.props.history.push(`/courses/${courseId}`);
    // }
    if (role.includes(INSTRUCTOR) || role.includes(ADMIN) || role.includes(REVIEWER)) {
      this.props.history.push(`/courses/${courseId}`);
    }
  };

  respond = (id) => {
    const state = getResponseStateAsStudent(this.props.taskResponses[id]);
    switch (state) {
      case NOT_STARTED:
        break;
      //do nothing
      case INCOMPLETE:
      case CONTINUE:
        this.props.history.push(`/tasks/${id}`);
        break;
      case SUBMITTED:
        this.props.history.push(`/taskresults/${id}`);
        break;
    }
  };
}

const mapStateToProps = (state: IRootState) => {
  console.log('Dashboard mapStateToProps ', state);
  return {
    authentication: state.authentication,
    taskResponses: state.taskResponses.taskResponseMap,
    membership: state.courses.membershipMap,
    windowHeight: state.utils.windowHeight,
  };
};

const mapDispatchToProps = {
  getTaskResponsesForUser: getTaskResponsesForUser,
  //resetTask: resetTaskResponse,
  getCoursesMembership: getCoursesMemberships,
  deleteMembership,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Dashboard);
