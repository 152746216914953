import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  Shortcut,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';

import 'video-react/dist/video-react.css';
import { playerHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { TIME_INITIAL } from '../../utils/utils';

type IProps = PropsFromRedux & {
  ref: React.RefObject<any>;
  url: string;

  timeReached: (time: number) => void;
  durationComputed: (duration: number) => void;
};

export interface IVideoPlayer {
  seek(seconds: number, cb?: any): void;
  pause(): void;
  getBoundingClientRect(): any;
  play(): void;
  hasStarted(): boolean;
  getCurrentTime(): number;
}

interface IState {
  videoWidthLimit: number;
  hasStarted: boolean;

  internalVideoWidth: number;
  internalVideoHeight: number;
  seekedCallback: any;
  // videoDuration: number;
  // currentTime: number;
}

// https://video-react.js.org/

// renders the video which is current in ReduxState,
// and updates crtTime in ReduxState while playing
// updates videoDuration in ReduxState
class VideoRenderer extends React.PureComponent<IProps, IState> implements IVideoPlayer {
  player: any = null;
  annotationsContainer: any = null;
  controlBar: any = null;
  currentTime = TIME_INITIAL;

  constructor(props) {
    super(props);
    this.state = {
      // playerState: null,
      videoWidthLimit: 100,
      hasStarted: false,
      internalVideoHeight: 0,
      internalVideoWidth: 0,
      seekedCallback: null,
      // videoDuration: DURATION_UNKNOWN,
      // currentTime: TIME_INITIAL,
    };
  }
  getCurrentTime(): number {
    return this.currentTime;
  }

  getBoundingClientRect = () => {
    if (this.annotationsContainer !== null) {
      console.log('REAL annotationsContainer getBoundingClientRect');
      return this.annotationsContainer.getBoundingClientRect();
    }
    return null;
  };
  seek = (seconds, cb?) => {
    // console.log('got command to seek to: ', seconds);
    if (cb) {
      this.setState({ seekedCallback: cb }, () => {
        this.player !== null && this.player.seek(seconds);
      });
    } else {
      if (this.player !== null) this.player.seek(seconds);
    }
  };
  pause = () => {
    this.player !== null && this.player.pause();
  };
  play = () => {
    this.player !== null && this.player.play();
  };
  hasStarted = () => {
    //console.log('VideoRenderer player hase started ? player ', this.player, this.player.state);
    return this.player !== null && this.state.hasStarted;
  };

  onClick = (e: React.MouseEvent) => {
    //console.log('click ', e, e.target);
  };

  onDoubleClick = (e) => {
    //    console.log('dblclick ', e, e.target);
  };

  onMouseDown = (e) => {
    //console.log('mousedown ', e.target.nodeName, e, e.target /*, this.relativeCoords(e)*/);
  };
  onMouseMove = (e) => {
    //console.log('mousemove ', e.target.nodeName, e, e.target);
  };

  onMouseUp = (e) => {};

  componentDidUpdate(prevProps, prevState) {
    if (this.state.videoWidthLimit !== prevState.videoWidthLimit) {
      console.log('VideoRenderer did update width');
      const playerRelCoords = this.getBoundingClientRect();
      if (playerRelCoords) {
        console.log('after videoWidthLimit updated,  videoComponentCoords/height: ', playerRelCoords, playerRelCoords.height);
        this.props.playerHeightUpdated(playerRelCoords.height);
      }
    }
  }
  componentDidMount() {
    // eslint-disable-next-line no-console
    // console.log('Avideo mounted ', this, ' player: ', this.player);
    // we need to see the state of the player - i.e. currentTime, etc
    this.player !== null && this.player.subscribeToStateChange(this.handlePlayerStateChange);
  }

  // TODO how to unsubscribe ?

  // called by the internal player when its state changed; params have nothing to do with our this.state or our redux.state
  handlePlayerStateChange = (internalPlayerState, prevInternalPlayerState) => {
    this.currentTime = internalPlayerState.currentTime;

    // eslint-disable-next-line no-console
    // console.log(
    //   'statechange, with prevInternalPlayerState.duration: ',
    //   prevInternalPlayerState.duration,
    //   ' newDuration',
    //   internalPlayerState.duration
    // );

    // GINA console.log('statechange, with prevInternalPlayerState ', prevInternalPlayerState, ' new', internalPlayerState);
    // console.log('statechange, currenttime: ', state.currentTime, ' duration', state.duration);
    // copy player state to this component's state, just in case we need it
    // this.setState({
    //   playerState: internalPlayerState,
    // });
    if (prevInternalPlayerState.paused !== internalPlayerState.paused) {
      // console.log('player paused changed to : ' + internalPlayerState.paused);
      // update video time in Redux state
      // this.props.videoPaused(internalPlayerState.paused);
    }
    if (prevInternalPlayerState.currentTime !== internalPlayerState.currentTime) {
      // eslint-disable-next-line no-console
      // console.log('currenttime: ' + internalPlayerState.currentTime);
      // update video time in Redux state
      this.props.timeReached(internalPlayerState.currentTime);
    }
    if (
      /*this.state.videoDuration === DURATION_UNKNOWN || */ prevInternalPlayerState.duration !== internalPlayerState.duration &&
      internalPlayerState.duration >= 0
    ) {
      // console.log('statechange, announcing DURATION***');
      // eslint-disable-next-line no-console
      // console.log(prevState.duration + ' => ' + state.duration);
      // update video duration in Redux state
      this.props.durationComputed(internalPlayerState.duration);
    }
    this.setState({
      hasStarted: internalPlayerState.hasStarted,
      internalVideoHeight: internalPlayerState.videoHeight,
      internalVideoWidth: internalPlayerState.videoWidth,
      // videoDuration: internalPlayerState.duration,
      // currentTime: internalPlayerState.currentTime,
    });
  };
  render() {
    const start = new Date().getTime();
    // console.log('VideoRenderer render at ', start);
    let rez = (
      <div className="fig-video-details-right-side__video">
        <div style={{ maxWidth: `${this.state.videoWidthLimit}%`, margin: 'auto' }}>
          <div
            style={{
              position: 'relative',
              zIndex: 99,
            }}
            ref={(internalComp) => {
              this.annotationsContainer = internalComp;
            }}
            id="CONTAINER"
            onClick={this.onClick}
            onDoubleClick={this.onDoubleClick}
          >
            {this.renderVideoPlayer()}
          </div>
        </div>
      </div>
    );
    // console.log('VideoRenderer returned ', rez, ', took: ms ', new Date().getTime() - start);
    return rez;
  }

  increaseVideoWidthLimit = () => {
    const limit = this.state.videoWidthLimit;
    let newValue = limit + 33 > 100 ? 100 : limit + 33;
    this.setState({ videoWidthLimit: newValue });
  };
  decreaseVideoWidthLimit = () => {
    const limit = this.state.videoWidthLimit;
    let newValue = limit - 33 < 20 ? 20 : limit - 33;
    this.setState({ videoWidthLimit: newValue });
  };

  onSeeked = () => {
    if (this.state.seekedCallback) {
      let cb = this.state.seekedCallback;
      this.setState({ seekedCallback: null }, () => cb());
    }
  };

  renderVideoPlayer() {
    //const url = this.props.selectedVideoUrl;
    // const startTime = this.props.curentTime;
    return (
      <Player
        ref={(internalComp) => {
          this.player = internalComp;
        }}
        playsInline
        crossOrigin="anonymous"
        src={this.props.url}
        // startTime={startTime}
        fluid={true}
        onSeeked={this.onSeeked}
      >
        {/* <source src={this.props.selectedVideoStream} type="video/webm" /> */}
        <ControlBar autoHide={false}>
          <ReplayControl
            seconds={30}
            order={1.1}
            ref={(el) => {
              this.controlBar = el;
            }}
          />
          <ForwardControl seconds={30} order={1.2} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[2, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4]} order={7.1} />
          <VolumeMenuButton />
        </ControlBar>
        <Shortcut clickable dblclickable />
      </Player>
    );
  }
}
export const relativeCoords = (event) => {
  let bounds = event.target.getBoundingClientRect();

  // if (isEventFromVideoElement(event)) {
  bounds = event.target.getBoundingClientRect();
  const x = (event.clientX - bounds.left) / bounds.width;
  const y = (event.clientY - bounds.top) / bounds.height;
  return { x: 100 * x, y: 100 * y };
  //   // also e.nativeEvent.offsetX, e.nativeEvent.offsety + boundsleft etc
  // } else {
};

const mapStateToProps = (state: IRootState) => {
  return {};
};
const mapDispatchToProps = {
  playerHeightUpdated,
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideoRenderer);
