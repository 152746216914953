import { Button, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { getUnauthedHomePath } from '../../../../app';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { successNotification } from '../../../utils/utils';
import { resetPassword } from '../../actions/campusApiActions';

interface IProps extends PropsFromRedux {
  history: any;
  match: any;
  location: any;
}

interface IState {
  newPassword?: string;
  newPasswordRetry?: string;
  attempted: boolean;
  resetCode: string;
}

class ResetPasswordPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    console.log('ResetPasswordPanel ctor,  props ', props);
    let code = new URLSearchParams(props.location.search).get('code');
    console.log('code: ', code);

    this.state = {
      attempted: false,
      newPassword: '',
      newPasswordRetry: '',
      resetCode: code,
    };
  }

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <div className="header-container no-margin text-center w-100">
              <p className="no-margin">Reset Campus Account Password</p>
            </div>
          </div>
          <form className="login-wrapper__form">{this.renderUserPasswordReset()}</form>
        </Paper>
      </div>
    );
  }

  onSuccesfullReset = () => {
    successNotification('Password reset successfuly!');
    this.props.history.push(getUnauthedHomePath());
  };

  onResetPassword = () => {
    if (this.state.newPassword !== this.state.newPasswordRetry) {
      this.props.setGlobalAlert("Passwords don't match");
    } else {
      resetPassword(this.state.resetCode, this.state.newPassword).then(
        () => this.onSuccesfullReset(),
        (err) => {
          console.log('Reset password error ', err);
        }
      );
    }
    this.setState({
      attempted: true,
    });
  };

  private renderUserPasswordReset() {
    return (
      <div className="fig-vertical-middle fig-direction-column w-100">
        <TextField
          className="w-100"
          id="new-password"
          value={this.state.newPassword}
          onChange={(e) =>
            this.setState({
              newPassword: e.target.value,
            })
          }
          type="password"
          //label="Password"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={'PASSWORD'}
          margin="normal"
          variant="outlined"
          error={this.state.attempted && (!this.state.newPassword || this.state.newPassword.length === 0)}
          required
        />

        <TextField
          className="w-100"
          id="new-password-again"
          value={this.state.newPasswordRetry}
          onChange={(e) =>
            this.setState({
              newPasswordRetry: e.target.value,
            })
          }
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={'PASSWORD AGAIN'}
          type="password"
          //label="Password Again"
          margin="normal"
          variant="outlined"
          required
          error={this.state.attempted && (!this.state.newPasswordRetry || this.state.newPasswordRetry.length === 0)}
        />

        <Button className="fig-button ghost-to-dark-blue margin-top--30" onClick={this.onResetPassword}>
          Reset Password
        </Button>
      </div>
    );
  }
}
const mapStateToProps = (state: IRootState) => {
  return {};
};
const mapDispatchToProps = {
  setGlobalAlert,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ResetPasswordPanel);
