import axios, { AxiosError } from 'axios';
import { errorNotification, successNotification, warningNotification } from '../../../utils/utils';
import campusApi, {
  confirmEMailURL,
  coursesURL,
  evaluationsURL,
  forgotPasswordURL,
  loginURL,
  membersURL,
  questionsURL,
  registerURL,
  resendConfirmationEMailURL,
  resetPasswordURL,
  responsesURL,
  rubricsURL,
  settingsURL,
  tasksURL,
  usersURL,
} from '../../apis/campusApi';
import {
  ACTIVE,
  API_ROLE_USER,
  AWSUploadFields,
  Attempt,
  Comment,
  Course,
  CourseBasic,
  Evaluation,
  EvaluationBasic,
  FRubric,
  FRubricBase,
  INSTRUCTOR,
  LoginRequest,
  LoginResponse,
  Member,
  QuestionBasic,
  QuestionInner,
  REVIEWER,
  Rubric,
  RubricQuestion,
  STUDENT,
  SettingsDatagroups,
  TaskRequest,
  TaskRequestBasic,
  TaskResponse,
  TaskResponseBasic,
  TranscriptResult,
  UserBasic,
  getAttemptTranscriptUrl,
  makeEvaluationBasic,
  makeRubricBasic,
  makeTaskRequestBasic,
  makeTaskResponseBasic,
} from '../../model/ApiTypes';

import {
  AUTH_CLEAR,
  AUTH_ERROR,
  AUTH_ROLE,
  AUTH_USER,
  AuthActionTypes,
  COURSE,
  COURSE_MEMBERSHIPS,
  COURSE_MEMBERSHIP_DELETED,
  COURSE_SELECTED,
  COURSE_TASK_REQUESTS,
  CourseAction,
  CoursesActionTypes,
  GlobalRubricsAction,
  OWN_COURSES,
  OWN_FRUBRICS,
  OWN_ROLES,
  OwnRolesAction,
  ROLE_SELECTED,
  RubricsActionTypes,
  SETTINGS_DATAGROUPS,
  SETTINGS_GLOBAL_RUBRICS,
  SettingsActionTypes,
  TASK_QUESTIONS,
  TASK_REQUEST,
  TASK_REQUEST_EVALUATIONS,
  TASK_REQUEST_SELECTED,
  TASK_RESPONSE,
  TASK_RESPONSES,
  TASK_RESPONSE_EVALUATION,
  TASK_RESPONSE_EVALUATIONS,
  TASK_RESPONSE_EVALUATION_DELETED,
  TASK_RESPONSE_SELECTED,
  TASK_RESPONSE_TRANSCRIPT,
  TASK_RUBRICS,
  TaskQuestionsAction,
  TaskRequestAction,
  TaskResponseEvaluationAction,
  TaskResponseEvaluationDeletedAction,
  TaskResponsesActionTypes,
} from './types';

const authOptions = {
  headers: { 'Content-Type': ' application/json', Accept: 'application/json' },
};

export const logErrorResponse = (error, ownMessage) => {
  if (error && error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.error(ownMessage);
    console.error('error: ', error);
    if (error.response.data) console.error('error.response.data: ', error.response.data);
    let text = null;
    if (error.response.data && error.response.data.error) {
      if (error.response.data.error.message) {
        text = error.response.data.error.message;
      } else if (error.response.data.error.name) {
        text = error.response.data.error.name;
      }
    }
    console.error('error message: ', text);
    errorNotification(`${ownMessage}: ${error} ${text ? text : ''}`);
  } else {
    console.error(ownMessage, error);
    errorNotification(`${ownMessage}: ${error ? error : ''}`);
  }
};

export const login = (loginInfo: LoginRequest) => async (dispatch: (action: AuthActionTypes | any) => void) => {
  try {
    const response = await campusApi.post(loginURL, { ...loginInfo });
    const loginResponse: LoginResponse = response.data;
    //TODO FFS
    loginResponse.user.role = {
      id: 1,
      name: API_ROLE_USER,
      description: 'TODO_FFS_user_desc',
      type: 'user',
    };
    dispatch({ type: AUTH_USER, payload: loginResponse });
    return Promise.resolve();
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { message: 'Invalid username or password', error: err },
    });
    logErrorResponse(err, 'Invalid username or password. ');
    return Promise.reject(err);
  }
};

export const logout = () => async (dispatch: (action: AuthActionTypes) => void) => {
  try {
    dispatch({ type: AUTH_CLEAR });
  } catch (err) {
    console.error('Unexpected logout err ', err);
  }
  return Promise.resolve();
};

export const getWhoAmI = (token: string) => async (dispatch: (action: AuthActionTypes) => void) => {
  try {
    console.log(' getWhoAmI : starting   ');
    const response = await campusApi.get(`users/me?populate=role`, withAuthorizationToken(authOptions, token));
    const userInfo: UserBasic = response.data;
    dispatch({ type: AUTH_ROLE, payload: userInfo.role });
    return Promise.resolve(userInfo.role);
  } catch (err) {
    logErrorResponse(err, 'getWhoAmI error: ');
    return Promise.reject(err);
  }
};

function withAuthorizationToken(authoptions, token) {
  const res = {
    headers: { ...authoptions.headers, Authorization: `Bearer ${token}` },
  };
  console.log(res);
  return res;
}

// get global settings for datagroups - FIGARO STYLE
export const getDatagroups = (token: string) => async (dispatch: (action: SettingsActionTypes) => void) => {
  try {
    console.log(' getDatagroups : retrieving info   ');
    const response = await campusApi.get(`${settingsURL}?key=datagroups`, withAuthorizationToken(authOptions, token));
    const settings: SettingsDatagroups[] = response.data;
    dispatch({
      type: SETTINGS_DATAGROUPS,
      payload:
        settings && settings.length > 0 && settings[0].options && settings[0].options.groups
          ? {
              allFaceCategories: [], //settings[0].options.groups.Face,
              allVoiceCategories: [], //settings[0].options.groups.Voice,
            }
          : { allFaceCategories: [], allVoiceCategories: [] },
    });
  } catch (err) {
    logErrorResponse(err, 'getDatagroups error: ');
  }
};
// END Datagroups

export const getRubricForTask = (taskId: number, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    console.log(' getRubricForTask starting');
    const response = await campusApi.get(
      `${rubricsURL}?sort=id:asc&pagination[limit]=1000&filters[task]=${taskId}`,
      withAuthorizationToken(authOptions, token)
    );

    const rubrics: Rubric[] = response.data.data;
    dispatch({
      type: TASK_RUBRICS,
      payload: { rubrics: rubrics.map((rubric) => makeRubricBasic(rubric)) },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubricForTask error: ');
  }
};

// Rubrics - FIGARO STYLE
export const getRubrics = (token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    console.log(' getRubrics starting');
    const response = await campusApi.get(`${rubricsURL}?_limit=1000000&_sort=id`, withAuthorizationToken(authOptions, token));
    const rubrics: FRubric[] = response.data;
    dispatch({
      type: OWN_FRUBRICS,
      payload: { rubrics: rubrics },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubrics error: ');
  }
};

////////////////////////////////////////////

export const getOwnRoles = (courseID: number, userId: number, token: string) => async (dispatch: (action: OwnRolesAction) => void) => {
  try {
    console.log(' getOwnRoles starting');
    const response = await campusApi.get(
      `${membersURL}?populate=course&filters[user]=${userId}&filters[course]=${courseID}`,
      withAuthorizationToken(authOptions, token)
    );

    const membr: Member[] = response.data.data;
    dispatch({
      type: OWN_ROLES,
      payload: { courseId: courseID, userId: userId, memberships: membr },
    });
    return Promise.resolve(membr.map((member) => member.attributes.role));
  } catch (err) {
    logErrorResponse(err, 'getOwnRoles error: ');
  }
};

export const getCoursesMemberships = (studentID: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' getCoursesMembership starting');
    const response = await campusApi.get(
      `${membersURL}?sort=id:desc&pagination[limit]=1000&populate=course&filters[user]=${studentID}`,
      withAuthorizationToken(authOptions, token)
    );

    const membr: Member[] = response.data.data;
    dispatch({
      type: OWN_COURSES,
      payload: { memberships: membr },
    });
  } catch (err) {
    logErrorResponse(err, 'getCoursesMembership error: ');
  }
};
export const courseSelected = (id: number) => async (dispatch: (action: CoursesActionTypes) => void) => {
  dispatch({
    type: COURSE_SELECTED,
    payload: { id: id },
  });
};
//this will affect courseMembershipMap - will reset it, then it will be repopulated when certain membership roles are inquired for
export const roleSelected = (role: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  dispatch({
    type: ROLE_SELECTED,
    payload: { role },
  });
};

export const addCourse = (courseInfo: CourseBasic, token: string) => async (dispatch: (action: CourseAction) => void) => {
  try {
    console.log(' addCourse starting');
    const response = await campusApi.post(
      coursesURL,
      {
        data: {
          semester: courseInfo.semester,
          code: courseInfo.code,
          name: courseInfo.name,
        },
      },
      withAuthorizationToken(authOptions, token)
    );
    const course: Course = response.data;
    //const res = makeTaskCourseBasic(course);
    dispatch({
      type: COURSE,
      payload: { course },
    });
    successNotification('Course added!');
    return Promise.resolve(course);
  } catch (err) {
    logErrorResponse(err, 'addCourse error: ');
    return Promise.reject(err);
  }
};

export const getCourse = (courseId: number, token: string) => async (dispatch: (action: CourseAction) => void) => {
  try {
    console.log(' getCourse starting');
    const response = await campusApi.get(`${coursesURL}/${courseId}`, withAuthorizationToken(authOptions, token));
    const course: Course = response.data;
    dispatch({
      type: COURSE,
      payload: { course },
    });
    return Promise.resolve(course);
  } catch (err) {
    logErrorResponse(err, 'getCourse error: ');
    return Promise.reject(err);
  }
};

export const addAsRole = (role: string, user, courseID, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(` addAsRole ${role} starting`);
    const response = await campusApi.post(
      membersURL,
      {
        data: {
          // user: user,
          email: user,
          course: courseID,
          state: ACTIVE,
          role: role,
        },
      },
      withAuthorizationToken(authOptions, token)
    );

    successNotification(`Succesfully added ${user}  as ${role} !`);
    // return Promise.resolve(membr);
  } catch (err) {
    // console.log(` addAsRole ${role} error for ${user}`);
    logErrorResponse(err, ` addAsRole ${role} error for ${user}`);
    return Promise.reject(err);
  }
};

//https://campus.alpha.api.figanalytics.com/api/members
// { "data": {
//   "user": 756,
//   "course": 207,
//   "state": "active",
//   "role": "reviewer"
// }
// }
export const addReviewer = (user, courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(addAsRole(REVIEWER, user, courseID, token));
};

export const addInstructor = (user, courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(addAsRole(INSTRUCTOR, user, courseID, token));
};

export const addStudent = (user, courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(addAsRole(STUDENT, user, courseID, token));
};

//TODO FFS ADD USER BY EMAIL - ask wing to return full user info course membership etc

export const deleteMembership = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' deleteMembership starting');
    const response = await campusApi.delete(`${membersURL}/${id}`, withAuthorizationToken(authOptions, token));
    const member: Member = response.data.data;

    dispatch({
      type: COURSE_MEMBERSHIP_DELETED,
      payload: { member: member },
    });
    warningNotification('Membership deleted!');
    return Promise.resolve();
  } catch (err) {
    logErrorResponse(err, 'deleteMembership error: ');
    return Promise.reject(err);
  }
};

// {
//   "data": {
//       "id": 791,
//       "attributes": {
//           "role": "reviewer",
//           "createdAt": "2023-07-03T10:35:57.647Z",
//           "updatedAt": "2023-07-03T10:35:57.647Z",
//           "state": "active",
//           "data": null
//       }
//   },
//   "meta": {}
// }

// https://campus.alpha.api.figanalytics.com/api/members?sort=id:desc&pagination[limit]=1000&populate=user&filters[course]=198
// https://campus.alpha.api.figanalytics.com/api/members?sort=id:desc&pagination[limit]=1000&populate=user&populate=course&filters[course]=198&filters[role]=student
// {
//   "data": [
//       {
//           "id": 799,
//           "attributes": {
//               "role": "student",
//               "createdAt": "2023-07-03T13:11:52.333Z",
//               "updatedAt": "2023-07-03T13:11:52.333Z",
//               "state": "active",
//               "data": null,
//               "user": {
//                   "data": {
//                       "id": 755,
//                       "attributes": {
//                           "username": "instructor-1-2023-06-05-02.19.40pm@example.com",
//                           "email": "instructor-1-2023-06-05-02.19.40pm@example.com",
//                           "provider": "local",
//                           "confirmed": true,
//                           "blocked": false,
//                           "createdAt": "2023-06-05T18:19:41.886Z",
//                           "updatedAt": "2023-06-05T18:19:41.886Z"
//                       }
//                   }
//               }
//           }
//       },]}

export const getCourseReviewers = (courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(getCourseMembershipsAsRole(courseID, REVIEWER, token));
};

export const getCourseInstructors = (courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(getCourseMembershipsAsRole(courseID, INSTRUCTOR, token));
};

export const getCourseStudents = (courseID, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  return dispatch(getCourseMembershipsAsRole(courseID, STUDENT, token));
};

export const getCourseMembershipsAsRole =
  (courseID: number, role: string, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
    try {
      console.log(' getCourseMembershipsAsRole starting');
      const response = await campusApi.get(
        `${membersURL}?sort=id:desc&pagination[limit]=1000&populate=user&populate=course&filters[course]=${courseID}&filters[role]=${role}`,
        withAuthorizationToken(authOptions, token)
      );

      const membr: Member[] = response.data.data;
      dispatch({
        type: COURSE_MEMBERSHIPS,
        payload: { memberships: membr },
      });
    } catch (err) {
      logErrorResponse(err, 'getCourseMembershipsAsRole error: ');
    }
  };

//TODO DACA /tasks ar return si course id/code ceva, as putea reincarca ttoul , incl curses, la refreh page, nu mi-ar trebui un anumit cntext dinainte
//sau ar tb sa am url gen /course/cutare/taskview/cutare
export const taskRequestSelected = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
  dispatch({
    type: TASK_REQUEST_SELECTED,
    payload: { id: id },
  });
  dispatch(getTaskRequest(id, token));
};

export const getTaskRequest = (id: number, token: string) => async (dispatch: (action: TaskRequestAction) => void) => {
  try {
    console.log(' getTaskRequest starting');
    const response = await campusApi.get(`${tasksURL}/${id}?populate=course`, withAuthorizationToken(authOptions, token));
    const req: TaskRequest = response.data.data;
    dispatch({
      type: TASK_REQUEST,
      payload: { taskRequest: makeTaskRequestBasic(req) },
    });
  } catch (err) {
    logErrorResponse(err, 'getTaskRequest error: ');
  }
};

export const getResponsesForTaskRequest = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' getResponsesForTaskRequest starting');
    const response = await campusApi.get(
      `${responsesURL}?sort=id:desc&pagination[limit]=1000&fields=id&populate=question&filters[task]=${id}`,
      withAuthorizationToken(authOptions, token)
    );
    const responses: TaskResponse[] = response.data.data;
    dispatch({
      type: TASK_RESPONSES,
      payload: { taskResponses: responses.map((response) => makeTaskResponseBasic(response)) },
    });
  } catch (err) {
    logErrorResponse(err, 'getResponsesForTaskRequest error: ');
  }
};
export const getCourseTaskRequests = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' getCourseTaskRequests starting');
    const response = await campusApi.get(
      `${tasksURL}?sort=id:desc&pagination[limit]=1000&fields=id&populate=task&filters[course]=${id}`,
      withAuthorizationToken(authOptions, token)
    );
    const requests: TaskRequest[] = response.data.data;
    dispatch({
      type: COURSE_TASK_REQUESTS,
      payload: { code: id, requests: requests.map((request) => makeTaskRequestBasic(request)) },
    });
  } catch (err) {
    logErrorResponse(err, 'getCourseTaskRequests error: ');
  }
};

export const addTaskRequest = (request: TaskRequestBasic, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' addTaskRequest starting');
    const response = await campusApi.post(
      tasksURL,
      {
        data: {
          name: request.name,
          desc: request.desc,
          type: request.type,
          course: request.course.id,
          starttime: request.starttime,
          endtime: request.endtime,
          options: request.options,
        },
      },
      withAuthorizationToken(authOptions, token)
    );
    const taskRequest: TaskRequestBasic = makeTaskRequestBasic(response.data.data);
    dispatch({
      type: TASK_REQUEST,
      payload: { taskRequest },
    });
    return Promise.resolve(taskRequest);
  } catch (err) {
    logErrorResponse(err, 'addTaskRequest error: ');
    return Promise.reject();
  }
};

export const updateRubric =
  (rubricId: number, questions: RubricQuestion[], token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
    try {
      console.log(' updateRubric starting');
      const response = await campusApi.put(
        `${rubricsURL}/${rubricId}`,
        {
          data: {
            questions,
          },
        },
        withAuthorizationToken(authOptions, token)
      );
      const rubric: Rubric = response.data.data;
      const baseRubric = makeRubricBasic(rubric);
      dispatch({
        type: TASK_RUBRICS,
        payload: { rubrics: [baseRubric] },
      });
      successNotification('Rubric updated!');
      return Promise.resolve(baseRubric);
    } catch (err) {
      logErrorResponse(err, 'updateRubric error: ');
      return Promise.reject(err);
    }
  };

export const addRubric2Task =
  (questions: RubricQuestion[], taskId: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
    try {
      const res = await campusApi.post(
        rubricsURL,
        {
          data: {
            task: taskId,
            questions: questions,
          },
        },
        withAuthorizationToken(authOptions, token)
      );
      console.log('addRubric2Task request: ', questions, ', response: ', res.data);
      successNotification('Rubric added to task!');
      return Promise.resolve(res.data.data);
    } catch (err) {
      logErrorResponse(err, 'addRubric2Task failed ');
      return Promise.reject();
    }
  };

/**deprecated */
export const addBankQuestions2Task =
  (questions: string[], taskId: number, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
    questions.forEach((question, idx) => {
      dispatch(addQuestion2Task(question, taskId, token));
    });
  };
export const addQuestion2Task =
  (question: string, taskId: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
    try {
      const res = await campusApi.post(
        questionsURL,
        {
          data: {
            task: taskId,
            text: question,
          },
        },
        withAuthorizationToken(authOptions, token)
      );
      console.log('addQuestion2Task request: ', question, ', response: ', res.data);
      successNotification('Question  added to task!');
      return Promise.resolve(/*response.id*/);
    } catch (err) {
      logErrorResponse(err, 'addQuestion2Task failed ');
      return Promise.reject();
    }
  };

export const placeBankQuestions2Task =
  (questions: string[], taskId: number, token: string) => async (dispatch: (action: CoursesActionTypes | any) => void) => {
    try {
      const res = await campusApi.post(
        `${questionsURL}/bulk`,
        {
          data: {
            task: taskId,
            questions,
          },
        },
        withAuthorizationToken(authOptions, token)
      );
      console.log('placeBankQuestions2Task request: ', questions, ', response: ', res.data);
      successNotification('Task Questions were set!');
      return Promise.resolve(taskId);
    } catch (err) {
      logErrorResponse(err, 'placeBankQuestions2Task failed ');
      return Promise.reject();
    }
  };

export const deleteRubric = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' deleteRubric starting');
    const response = await campusApi.delete(`${rubricsURL}/${id}`, withAuthorizationToken(authOptions, token));
    warningNotification('Rubric deleted!');
  } catch (err) {
    logErrorResponse(err, 'deleteRubric error: ');
  }
};

export const deleteQuestion = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' deleteQuestion starting');
    const response = await campusApi.delete(`${questionsURL}/${id}`, withAuthorizationToken(authOptions, token));
    // const member: Member = response.data.data;

    // dispatch({
    //   type: COURSE_MEMBERSHIP_DELETED,
    //   payload: { member: member },
    // });
    warningNotification('Question deleted!');
  } catch (err) {
    logErrorResponse(err, 'deleteQuestion error: ');
  }
};
export const getTaskQuestions =
  (taskID: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes | TaskQuestionsAction) => void) => {
    try {
      console.log(' getTaskQuestions starting');
      const response = await campusApi.get(
        `${questionsURL}?sort=id:asc&pagination[limit]=1000&filters[task]=${taskID}`,
        withAuthorizationToken(authOptions, token)
      );
      const qs: QuestionInner[] = response.data.data;
      dispatch({
        type: TASK_QUESTIONS,
        payload: {
          taskQuestions: qs.map(
            (innerQ) =>
              ({
                id: innerQ.id,
                text: innerQ.attributes.text,
                createdAt: innerQ.attributes.createdAt,
                updatedAt: innerQ.attributes.updatedAt,
              } as QuestionBasic)
          ),
        },
      });
    } catch (err) {
      logErrorResponse(err, 'getTaskQuestions error: ');
    }
  };

//TODO FFS MAYBE &populate=task as wing runTest1.test.js
export const getTaskResponsesForUser = (userID: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' getTaskResponses starting');
    // const response = await campusApi.get(`${tasksURL}?_limit=1000000&_sort=id`, withAuthorizationToken(authOptions, token));
    const response = await campusApi.get(
      `${responsesURL}?sort=id:desc&pagination[limit]=1000&fields=id&populate=task&filters[user]=${userID}`,
      withAuthorizationToken(authOptions, token)
    );
    const tasks: TaskResponse[] = response.data.data;
    dispatch({
      type: TASK_RESPONSES,
      payload: { taskResponses: tasks.map((task) => makeTaskResponseBasic(task)) },
    });
  } catch (err) {
    logErrorResponse(err, 'getTaskResponses error: ');
  }
};
export const getTaskResponse = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' getTaskResponse starting');
    const response = await campusApi.get(`${responsesURL}/${id}/`, withAuthorizationToken(authOptions, token));
    const task: TaskResponse = response.data.data;
    const res: TaskResponseBasic = makeTaskResponseBasic(task);
    dispatch({
      type: TASK_RESPONSE,
      payload: { task: res },
    });
    return Promise.resolve(res);
  } catch (err) {
    logErrorResponse(err, 'getTaskResponse error: ');
    return Promise.reject(err);
  }
};
export const resetTaskResponse = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' resetTaskResponse starting');
    const response = await campusApi.put(`${responsesURL}/${id}/resetTask`, withAuthorizationToken(authOptions, token));
    const task: TaskResponse = response.data.data;

    dispatch({
      type: TASK_RESPONSE,
      payload: { task: makeTaskResponseBasic(task) },
    });
  } catch (err) {
    logErrorResponse(err, 'resetTaskResponse error: ');
  }
};

//TODO ASK WING IF THIS IS THE RIGHT FN
export const resetTask = (id: number, token: string) => async (dispatch: (action: TaskRequestAction) => void) => {
  try {
    console.log(' resetTask starting');
    const response = await campusApi.put(`${tasksURL}/${id}/resetTask`, withAuthorizationToken(authOptions, token));
    const req: TaskRequest = response.data.data;
    dispatch({
      type: TASK_REQUEST,
      payload: { taskRequest: makeTaskRequestBasic(req) },
    });
  } catch (err) {
    logErrorResponse(err, 'resetTask error: ');
  }
};

export const updateTaskRequest = (request: TaskRequestBasic, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' updateTaskRequest starting');
    const response = await campusApi.put(
      `${tasksURL}/${request.id}`,
      {
        data: {
          name: request.name,
          // desc: request.desc,
          // type: request.type,
          // course: request.course.id,
          starttime: request.starttime,
          endtime: request.endtime,
          options: request.options,
        },
      },
      withAuthorizationToken(authOptions, token)
    );
    const taskRequest: TaskRequestBasic = makeTaskRequestBasic(response.data.data);
    dispatch({
      type: TASK_REQUEST,
      payload: { taskRequest },
    });
    return Promise.resolve(taskRequest);
  } catch (err) {
    logErrorResponse(err, 'updateTaskRequest error: ');
    return Promise.reject(err);
  }
};

export const startTaskResponse = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' startTaskResponse starting');
    const response = await campusApi.put(`${responsesURL}/${id}/startTask`, withAuthorizationToken(authOptions, token));
    const task: TaskResponseBasic = makeTaskResponseBasic(response.data.data);

    dispatch({
      type: TASK_RESPONSE,
      payload: { task },
    });
  } catch (err) {
    logErrorResponse(err, 'startTaskResponse error: ');
  }
};
export const startAttempt = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' startAttempt starting');
    const response = await campusApi.put(`${responsesURL}/${id}/startAttempt`, withAuthorizationToken(authOptions, token));
    const task: TaskResponseBasic = makeTaskResponseBasic(response.data.data);

    dispatch({
      type: TASK_RESPONSE,
      payload: { task },
    });
    return Promise.resolve(task);
  } catch (err) {
    logErrorResponse(err, 'startAttempt error: ');
    return Promise.reject(err);
  }
};

export const submitAttempt =
  (id: number, attemptIndex: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
    try {
      console.log(' submitAttempt starting');
      const response = await campusApi.put(
        `${responsesURL}/${id}/submitAttempt/${attemptIndex}`,
        withAuthorizationToken(authOptions, token)
      );
      const task: TaskResponseBasic = makeTaskResponseBasic(response.data.data);

      dispatch({
        type: TASK_RESPONSE,
        payload: { task },
      });
      return Promise.resolve(task);
    } catch (err) {
      logErrorResponse(err, 'submitAttempt error: ');
      return Promise.reject(err);
    }
  };

export const stopAttempt = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' stopAttempt starting');
    const response = await campusApi.put(`${responsesURL}/${id}/stopAttempt`, withAuthorizationToken(authOptions, token));
    const task: TaskResponseBasic = makeTaskResponseBasic(response.data.data);

    dispatch({
      type: TASK_RESPONSE,
      payload: { task },
    });
    return Promise.resolve(task);
  } catch (err) {
    logErrorResponse(err, 'stopAttempt error: ');
    return Promise.reject(err);
  }
};
export const uploadFile =
  (file: Blob, awsFields: AWSUploadFields, id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
    const { url, fields } = awsFields;
    const data = { ...fields, /*'Content-Type': 'video/webm',*/ file: file };
    const formData = new FormData();
    for (const name in data) {
      formData.append(name, data[name]);
    }
    try {
      const response = await axios.post(url, formData);
      console.log('ok Response from s3 ', response);
      try {
        const response = await campusApi.put(`${responsesURL}/${id}/uploadComplete`, {}, withAuthorizationToken(authOptions, token));
        const task: TaskResponseBasic = makeTaskResponseBasic(response.data.data);
        dispatch({
          type: TASK_RESPONSE,
          payload: { task },
        });
        return Promise.resolve(id);
      } catch (err) {
        console.log('after upload: uploadComplete() err: ', err);
        logErrorResponse(err, 'uploadComplete  failed ');
        let res: CampusApiError = { error: err, step: STEP_PROCESSING };
        return Promise.reject(res);
      }
    } catch (error) {
      console.warn('AWS post ', error);
      console.warn(error.request);
      console.warn(error.response.headers);
      console.warn(error.response.data);
      logErrorResponse(error, 'Uploading file failed ');
      let res: CampusApiError = { error, step: STEP_UPLOADING };
      return Promise.reject(res);
    }
  };

export const STEP_PROCESSING = 'processing';
export const STEP_UPLOADING = 'uploading';
export interface CampusApiError {
  error: any;
  step: string;
}

export const taskResponseSelected = (id: number) => (dispatch: (action: TaskResponsesActionTypes) => void) => {
  dispatch({
    type: TASK_RESPONSE_SELECTED,
    payload: { id: id },
  });
};

export const getTranscript =
  (id: number, attempt: Attempt, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
    try {
      const url = getAttemptTranscriptUrl(attempt);
      console.log(' getTranscript starting');
      const response = await axios.get(url);
      const transcript: TranscriptResult = response.data;

      dispatch({
        type: TASK_RESPONSE_TRANSCRIPT,
        payload: { id, transcript },
      });
      return Promise.resolve(transcript);
    } catch (err) {
      logErrorResponse(err, 'getTranscript error: ');
      return Promise.reject(err);
    }
  };

// {
//   "data": {
//       "id": 234,
//       "attributes": {
//           "name": "My Task 1 - 2023-06-05-02.19.40pm",
//           "desc": "Task 1 Description",
//           "type": "video",
//           "starttime": "2023-05-10T13:00:00.000Z",
//           "endtime": "2023-06-15T21:00:00.000Z",
//           "options": {
//               "attempts": 5,
//               "totaltime": 15,
//               "attempttime": 90,
//               "studentresultsaccess": true
//           },
//           "data": null,
//           "createdAt": "2023-06-05T18:19:45.140Z",
//           "updatedAt": "2023-06-24T08:55:58.030Z"
//       }
//   },
//   "meta": {}
// }

export const setStudentAccessToTaskGrades =
  (id: number, haveAccess: boolean, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
    try {
      console.log(' setStudentAccessToGrades starting');
      const response = await campusApi.put(
        `${tasksURL}/${id}/options?studentresultsaccess=${haveAccess}`,
        withAuthorizationToken(authOptions, token)
      );
      const taskRequest: TaskRequestBasic = makeTaskRequestBasic(response.data.data);
      dispatch({
        type: TASK_REQUEST,
        payload: { taskRequest },
      });
      successNotification('Student access to grades changed!');
      return Promise.resolve(taskRequest.options.studentresultsaccess);
    } catch (err) {
      logErrorResponse(err, 'setStudentAccessToGrades error: ');
      return Promise.reject(err);
    }
  };
export const setTaskAvailability =
  (id: number, haveAccess: boolean, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
    try {
      console.log(' setTaskAvailability starting');
      const response = await campusApi.put(
        `${tasksURL}/${id}/options?taskavaiability=${haveAccess}`,
        withAuthorizationToken(authOptions, token)
      );
      const taskRequest: TaskRequestBasic = makeTaskRequestBasic(response.data.data);
      dispatch({
        type: TASK_REQUEST,
        payload: { taskRequest },
      });
      successNotification('Task availability changed!');
      return Promise.resolve(taskRequest.options.taskavaiability);
    } catch (err) {
      logErrorResponse(err, 'setTaskAvailability error: ');
      return Promise.reject(err);
    }
  };
export const assignTaskQuestions2Students = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' assignTaskQuestions2Students starting');
    const response = await campusApi.put(`${tasksURL}/${id}/assignquestions`, withAuthorizationToken(authOptions, token));
    const taskRequest: TaskRequestBasic = makeTaskRequestBasic(response.data.data);
    //TODO FFS THIS RESPONSE DOES NOT CONTAIN COURSE INFO
    // and it seems it does not add info to what we already know about the task,
    // it merely looses info
    // WE BETTER IGNORE IT FOR NOW
    // dispatch({
    //   type: TASK_REQUEST,
    //   payload: { taskRequest },
    // });
    successNotification('Task: questions assigned to students!');
    return Promise.resolve(taskRequest);
  } catch (err) {
    logErrorResponse(err, 'assignTaskQuestions2Students error: ');
    return Promise.reject(err);
  }
};

//https://campus.alpha.api.figanalytics.com/api/evaluations?populate=user&filters[task]=234
//   "data": [
//     {
//         "id": 171,
//         "attributes": {
//             "rubric": [
//                 35,
//                 32.5
//             ],
//             "comments": [
//                 {
//                     "text": "Give more details",
//                     "time": 10
//                 },
//                 {
//                     "text": "Boring points",
//                     "time": 25
//                 },
//                 {
//                     "text": "Conclusion could be worse",
//                     "time": 60
//                 }
//             ],
//             "data": null,
//             "createdAt": "2023-06-24T14:21:05.829Z",
//             "updatedAt": "2023-06-24T14:21:06.048Z",
//             "user": {
//                 "data": {
//                     "id": 756,
//                     "attributes": {
//                         "username": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                         "email": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                         "provider": "local",
//                         "confirmed": true,
//                         "blocked": false,
//                         "createdAt": "2023-06-05T18:19:42.254Z",
//                         "updatedAt": "2023-06-05T18:19:42.254Z"
//                     }
//                 }
//             }
//         }
//     }
// ],
export const getTaskRequestEvaluations = (id: number, token: string) => async (dispatch: (action: CoursesActionTypes) => void) => {
  try {
    console.log(' getTaskRequestEvaluations starting');
    const response = await campusApi.get(
      `${evaluationsURL}?populate=user&populate=response&filters[task]=${id}`,
      withAuthorizationToken(authOptions, token)
    );
    const evals: Evaluation[] = response.data.data;
    const basicEvals = evals.map((crt) => makeEvaluationBasic(crt));
    dispatch({
      type: TASK_REQUEST_EVALUATIONS,
      payload: { evaluations: basicEvals },
    });
    return Promise.resolve(basicEvals);
  } catch (err) {
    logErrorResponse(err, 'getTaskRequestEvaluations error: ');
    return Promise.reject(err);
  }
};

export const getTaskResponseEvaluations = (id: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
  try {
    console.log(' getTaskResponseEvaluations starting');
    const response = await campusApi.get(
      `${evaluationsURL}?populate=user&filters[response]=${id}`,
      withAuthorizationToken(authOptions, token)
    );
    const evals: Evaluation[] = response.data.data;
    dispatch({
      type: TASK_RESPONSE_EVALUATIONS,
      payload: { evaluations: evals.map((crt) => makeEvaluationBasic(crt)) },
    });
  } catch (err) {
    logErrorResponse(err, 'getTaskResponseEvaluations error: ');
  }
};
export const getTaskResponseEvaluationsByReviewer =
  (id: number, userId: number, token: string) => async (dispatch: (action: TaskResponsesActionTypes) => void) => {
    try {
      console.log(' getTaskResponseEvaluationsByReviewer starting');
      const response = await campusApi.get(
        `${evaluationsURL}?populate=user&filters[response]=${id}&filters[user]=${userId}`,
        withAuthorizationToken(authOptions, token)
      );
      const evals: Evaluation[] = response.data.data;
      dispatch({
        type: TASK_RESPONSE_EVALUATIONS,
        payload: { evaluations: evals.map((crt) => makeEvaluationBasic(crt)) },
      });
    } catch (err) {
      logErrorResponse(err, 'getTaskResponseEvaluationsByReviewer error: ');
    }
  };
//evaluations?populate=user&filters[task]=${constSettigns.taskID}`,

export const updateEvaluationRubricGrades =
  (id: number, evalt: EvaluationBasic, token: string) => async (dispatch: (action: TaskResponseEvaluationAction) => void) => {
    try {
      const { rubric, user, responseId } = evalt;

      let data: any = {
        rubric: rubric,
        response: responseId,
      };
      if (user) {
        data = { ...data, user: user.id };
      }

      console.log(' updateEvaluationRubricGrades starting');
      const response = await campusApi.put(
        `${evaluationsURL}/${id}`,
        {
          data: {
            rubric: rubric,
            response: responseId,
          },
        },
        withAuthorizationToken(authOptions, token)
      );
      const evals: Evaluation = response.data.data;

      dispatch({
        type: TASK_RESPONSE_EVALUATION,
        payload: { evaluation: makeEvaluationBasic(evals) },
      });
      successNotification('Evaluation updated!');
    } catch (err) {
      logErrorResponse(err, 'updateEvaluationRubricGrades error: ');
    }
  };
//PUT  https://campus.alpha.api.figanalytics.com/api/evaluations/174

//   {
//     "data": {
//         "rubric": [
//             8,
//             6.5
//         ],
//         "user": 756,
//         "response": 237
//     }
// }

export const updateEvaluationComments =
  (id: number, comments: Comment[], token: string) => async (dispatch: (action: TaskResponseEvaluationAction) => void) => {
    try {
      let data: any = {
        comments,
      };
      console.log(' updateEvaluationComments starting');
      const response = await campusApi.put(
        `${evaluationsURL}/${id}`,
        {
          data,
        },
        withAuthorizationToken(authOptions, token)
      );
      const evals: Evaluation = response.data.data;

      dispatch({
        type: TASK_RESPONSE_EVALUATION,
        payload: { evaluation: makeEvaluationBasic(evals) },
      });
      successNotification('Comments updated!');
    } catch (err) {
      logErrorResponse(err, 'updateEvaluationComments error: ');
    }
  };

//PUT https://campus.alpha.api.figanalytics.com/api/tasks/234/options?taskavaiability=true
// {
//   "data": {
//       "id": 234,
//       "attributes": {
//           "name": "My Task 1 - 2023-06-05-02.19.40pm",
//           "desc": "Task 1 Description",
//           "type": "video",
//           "starttime": "2023-05-10T13:00:00.000Z",
//           "endtime": "2023-06-15T21:00:00.000Z",
//           "options": {
//               "attempts": 5,
//               "totaltime": 15,
//               "attempttime": 90,
//               "taskavaiability": true,
//               "studentresultsaccess": true
//           },
//           "data": null,
//           "createdAt": "2023-06-05T18:19:45.140Z",
//           "updatedAt": "2023-06-24T09:00:35.824Z"
//       }
//   },
//   "meta": {}
// }

export const deleteEvaluation = (id: number, token: string) => async (dispatch: (action: TaskResponseEvaluationDeletedAction) => void) => {
  //xios.delete(`${server}evaluations/${newData['evaluationForResponseByReviewer'][0].id}`
  try {
    console.log(' deleteEvaluation starting');
    const response = await campusApi.delete(`${evaluationsURL}/${id}`, withAuthorizationToken(authOptions, token));
    const evals: Evaluation = response.data.data;

    dispatch({
      type: TASK_RESPONSE_EVALUATION_DELETED,
      payload: { evaluation: makeEvaluationBasic(evals) },
    });
    warningNotification('Evaluation deleted!');
  } catch (err) {
    logErrorResponse(err, 'deleteEvaluation error: ');
  }
};

export const createEvaluation =
  (evaluation: EvaluationBasic, token: string) => async (dispatch: (action: TaskResponseEvaluationAction) => void) => {
    try {
      const { rubric, user, responseId, comments } = evaluation;

      let data: any = {
        rubric: rubric,
        response: responseId,
        comments,
      };
      if (user) {
        data = { ...data, user: user.id };
      }

      console.log(' createEvaluation starting');
      const response = await campusApi.post(`${evaluationsURL}`, { data: data }, withAuthorizationToken(authOptions, token));
      const evals: Evaluation = response.data.data;

      dispatch({
        type: TASK_RESPONSE_EVALUATION,
        payload: { evaluation: makeEvaluationBasic(evals) },
      });
      successNotification('Evaluation created!');
    } catch (err) {
      logErrorResponse(err, 'createEvaluation error: ');
    }
  };
// const res = await axios.post(`${server}evaluations`, payload, authOptions);
//   {"data":{"rubric":[2,3],
//   //     comments: [
// //       { time: 5, text: 'Good Start' },
// //       { time: 25, text: 'Interesting points' },
// //       { time: 60, text: 'Conclusion could be better' }
// //     ]
// response:236,
// user:756}}
//response
//   {
//     "data": {
//         "id": 179,
//         "attributes": {
//             "rubric": [
//                 2,
//                 3
//             ],
//             "comments": [],
//             "data": null,
//             "createdAt": "2023-06-28T14:23:58.158Z",
//             "updatedAt": "2023-06-28T14:24:37.793Z"
//         }
//     },
//     "meta": {}
// }

export const getGlobalRubrics = (token: string) => async (dispatch: (action: RubricsActionTypes | GlobalRubricsAction) => void) => {
  try {
    console.log(' getRubricSettings starting');
    const response = await campusApi.get(`${settingsURL}?key=rubrics`, withAuthorizationToken(authOptions, token));
    let rubrics: FRubricBase[] = [];
    const settings: any[] = response.data;
    if (settings.length > 0 && settings[0].options && settings[0].options.rubrics) {
      rubrics = settings[0].options.rubrics as FRubricBase[];
    }
    dispatch({
      type: SETTINGS_GLOBAL_RUBRICS,
      payload: { rubrics: rubrics },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubricSettings error: ');
  }
};

// export const deleteRubric = (rubricId: number, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
//   try {
//     const res = await campusApi.delete(`${rubricsURL}/${rubricId}`, withAuthorizationToken(authOptions, token));
//     console.log('deleteRubric request: ', rubricId, ', response: ', res.data);
//     dispatch({
//       type: RUBRIC_DELETED,
//       payload: { rubricId },
//     });
//     warningNotification('Rubric  deleted!');
//   } catch (err) {
//     logErrorResponse(err, 'deleteRubric failed ');
//   }
// };

// export const createRubric = (rubric: FRubricBase, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
//   try {
//     const res = await campusApi.post(`${rubricsURL}`, rubric, withAuthorizationToken(authOptions, token));
//     console.log('createRubric request: ', rubric, ', response: ', res.data);
//     const response = res.data as FRubric;
//     dispatch({
//       type: RUBRIC_ADDED,
//       payload: { rubricId: response.id, rubric: response },
//     });
//     successNotification('Rubric  created!');
//     return Promise.resolve(response.id);
//   } catch (err) {
//     logErrorResponse(err, 'createRubric failed ');
//     return Promise.reject();
//   }
// };

// Users
export const registerUser = async (username: string, email: string, password: string) => {
  try {
    const req = { username, password, email };
    const res = await campusApi.post(`${registerURL}`, req, { headers: { ...authOptions.headers } });
    console.log('registerUser request: ', req, ', response: ', res.data);
    successNotification('User registered!');
    return Promise.resolve(username);
  } catch (err) {
    logErrorResponse(err, 'registerUser failed ');
    return Promise.reject();
  }
};

export const confirmRegistration = async (confirmation: string) => {
  try {
    console.log(' confirmRegistration starting, ', confirmation);
    const response = await campusApi.get(`${confirmEMailURL}?confirmation=${confirmation}`, { headers: { ...authOptions.headers } });
    let users = response.data;

    console.log('confirmRegistration  response: ', users);
    return Promise.resolve(users);
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response) {
      return Promise.resolve(axiosErr.message);
      /*TODO REMOVE WHEN IT"S FIXED ON THE SERVER 
      A: As long as you hit that endpoint, it will work. I am aware that it returns an error
      */
    } else {
      logErrorResponse(err, 'confirmRegistration error ');
      return Promise.reject(err);
    }
  }
};

export const forgotPassword = async (email: string) => {
  try {
    console.log(' forgotPassword starting, ', email);
    const req = { email };
    const response = await campusApi.post(`${forgotPasswordURL}`, req, { headers: { ...authOptions.headers } });
    let forgotten = response.data;

    console.log('forgotPassword  response: ', forgotten);
    return Promise.resolve(forgotten);
  } catch (err) {
    logErrorResponse(err, 'forgotPassword error ');
    return Promise.reject(err);
  }
};
export const reRequestConfirmation = async (email: string) => {
  try {
    console.log(' requestConfirmation starting, ', email);
    const req = { email };
    const response = await campusApi.post(`${resendConfirmationEMailURL}`, req, { headers: { ...authOptions.headers } });
    let confirmation = response.data;

    console.log('requestConfirmation  response: ', confirmation);
    return Promise.resolve(confirmation);
  } catch (err) {
    logErrorResponse(err, 'requestConfirmation error ');
    return Promise.reject(err);
  }
};
export const resetPassword = async (code: string, password: string) => {
  try {
    console.log(' resetPassword starting, ', code, password);
    const req = { code, password, passwordConfirmation: password };
    const response = await campusApi.post(`${resetPasswordURL}`, req, { headers: { ...authOptions.headers } });
    let reset = response.data;

    console.log('resetPassword  response: ', reset);
    return Promise.resolve(reset);
  } catch (err) {
    logErrorResponse(err, 'resetPassword error ');
    return Promise.reject(err);
  }
};

export const getUsers = async (addressPart: string, token: string) => {
  try {
    let url = `${usersURL}?`;
    if (addressPart && addressPart.length > 0) {
      url += `email_contains=${addressPart}`;
    }
    console.log(' getUsers starting, ', addressPart);
    const response = await campusApi.get(`${url}&_sort=username:asc`, withAuthorizationToken(authOptions, token));
    let users: UserBasic[] = response.data;

    console.log('getUsers  response: ', users);
    return Promise.resolve(users);
  } catch (err) {
    logErrorResponse(err, 'getUsers error ');
    return Promise.reject(err);
  }
};

// export const createUser =
//   (username: string, email: string, password: string, token: string) => async (dispatch: (action: any /*UsersActionTypes*/) => void) => {
//     try {
//       const req = { username, password, email };
//       const res = await figaroApi.post(`${registerURL}`, req, withAuthorizationToken(authOptions, token));
//       console.log('registerUser request: ', req, ', response: ', res.data);
//       // const response = res.data;
//       // dispatch({
//       //   type: USER_ADDED,
//       //   payload: { rubricId: response.id, rubric: response },
//       // });
//       successNotification('User registered!');
//       // return Promise.resolve(response.id);
//     } catch (err) {
//       logErrorResponse(err, 'registerUser failed ');
//       // return Promise.reject();
//     }
//   };

// End Users

/*
createStream = ()=> async (dispatch, getState) => {
 ***const { userId } = getState().auth;
    const response = await ....
    dispatch(....);
*/

//get rubrics for task 234
// {
//   "data": [
//       {
//           "id": 159,
//           "attributes": {
//               "questions": [
//                   {
//                       "text": "Content",
//                       "score": 100,
//                       "weight": 60
//                   },
//                   {
//                       "text": "Presentation",
//                       "score": 100,
//                       "weight": 40
//                   }
//               ],
//               "createdAt": "2023-06-05T18:19:45.401Z",
//               "updatedAt": "2023-06-05T18:19:45.401Z"
//           }
//       }
//   ],
//   "meta": {
//       "pagination": {
//           "start": 0,
//           "limit": 100,
//           "total": 1
//       }
//   }
// }
//GET EVALUATIONS FOR RESPONSE
// https://campus.alpha.api.figanalytics.com/api/evaluations?populate=user&filters[response]=237
// {
//   "data": [
//       {
//           "id": 162,
//           "attributes": {
//               "rubric": [
//                   0.5,
//                   1
//               ],
//               "comments": [
//                   {
//                       "text": "Give more details",
//                       "time": 10
//                   },
//                   {
//                       "text": "Boring points",
//                       "time": 25
//                   },
//                   {
//                       "text": "Conclusion could be worse",
//                       "time": 60
//                   }
//               ],
//               "data": null,
//               "createdAt": "2023-06-24T09:50:26.305Z",
//               "updatedAt": "2023-06-24T09:50:26.629Z",
//               "user": {
//                   "data": {
//                       "id": 756,
//                       "attributes": {
//                           "username": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "email": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "provider": "local",
//                           "confirmed": true,
//                           "blocked": false,
//                           "createdAt": "2023-06-05T18:19:42.254Z",
//                           "updatedAt": "2023-06-05T18:19:42.254Z"
//                       }
//                   }
//               }
//           }
//       },
//       {
//           "id": 163,
//           "attributes": {
//               "rubric": [
//                   2.5,
//                   1
//               ],
//               "comments": [
//                   {
//                       "text": "Give more details",
//                       "time": 10
//                   },
//                   {
//                       "text": "Boring points",
//                       "time": 25
//                   },
//                   {
//                       "text": "Conclusion could be worse",
//                       "time": 60
//                   }
//               ],
//               "data": null,
//               "createdAt": "2023-06-24T09:50:48.818Z",
//               "updatedAt": "2023-06-24T09:50:49.151Z",
//               "user": {
//                   "data": {
//                       "id": 756,
//                       "attributes": {
//                           "username": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "email": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "provider": "local",
//                           "confirmed": true,
//                           "blocked": false,
//                           "createdAt": "2023-06-05T18:19:42.254Z",
//                           "updatedAt": "2023-06-05T18:19:42.254Z"
//                       }
//                   }
//               }
//           }
//       },
//       {
//           "id": 164,
//           "attributes": {
//               "rubric": [
//                   1.5,
//                   3.5
//               ],
//               "comments": [
//                   {
//                       "text": "Give more details",
//                       "time": 10
//                   },
//                   {
//                       "text": "Boring points",
//                       "time": 25
//                   },
//                   {
//                       "text": "Conclusion could be worse",
//                       "time": 60
//                   }
//               ],
//               "data": null,
//               "createdAt": "2023-06-24T09:54:14.854Z",
//               "updatedAt": "2023-06-24T09:54:15.163Z",
//               "user": {
//                   "data": {
//                       "id": 756,
//                       "attributes": {
//                           "username": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "email": "reviewer-1-2023-06-05-02.19.40pm@example.com",
//                           "provider": "local",
//                           "confirmed": true,
//                           "blocked": false,
//                           "createdAt": "2023-06-05T18:19:42.254Z",
//                           "updatedAt": "2023-06-05T18:19:42.254Z"
//                       }
//                   }
//               }
//           }
//       },

//   ],
//   "meta": {
//       "pagination": {
//           "page": 1,
//           "pageSize": 25,
//           "pageCount": 1,
//           "total": 5
//       }
//   }
// }

// POST https://campus.alpha.api.figanalytics.com/api/evaluations
// {
//   "data": {
//       "rubric": [
//           28,
//           46.5
//       ],
//       "comments": [
//           {
//               "text": "Give more details",
//               "time": 10
//           },
//           {
//               "text": "Boring points",
//               "time": 25
//           },
//           {
//               "text": "Conclusion could be worse",
//               "time": 60
//           }
//       ],
//       "user": 756,
//       "response": 237
//   }
// }

// {
//   "data": {
//       "id": 167,
//       "attributes": {
//           "rubric": [
//               28,
//               46.5
//           ],
//           "comments": [
//               {
//                   "text": "Give more details",
//                   "time": 10
//               },
//               {
//                   "text": "Boring points",
//                   "time": 25
//               },
//               {
//                   "text": "Conclusion could be worse",
//                   "time": 60
//               }
//           ],
//           "data": null,
//           "createdAt": "2023-06-24T10:32:17.701Z",
//           "updatedAt": "2023-06-24T10:32:17.701Z"
//       }
//   },
//   "meta": {}
// }
