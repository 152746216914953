import { Fade, Tooltip } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUnauthedHomePath } from '../../../../app';
import sprites from '../../../../icons/fig-icons.svg';

//SWITCH comment/uncomment these two lines to use directly the css / use scss
//mainScss.scss should be generated/updated by running npm run sass
import '../../../../styles/sass/mainScss.scss';
//import '../../../../styles/css/mainScss.css';

import { logout } from '../../../demo/actions/campusApiActions';
import { IRootState } from '../../../shared/reducers';

interface IProps extends PropsFromRedux {
  selectedMenu: string;
  history: any;
}

/*
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: '#dadde9',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  arrow: {
    color: '#dadde9',
  },
}))(Tooltip);
*/
class Header extends Component<IProps> {
  render() {
    let drawerClass = 'side-menu-list';

    let commonTooltipProps: any = {
      arrow: true,
      placement: 'left',
      TransitionComponent: Fade,
      TransitionProps: { timeout: 600 },
      PopperProps: {
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: '0px, -10px',
            },
          },
        },
      },
    };

    return (
      <div className="header-container">
        {/*<AppBar position="static" color={"transparent"}>
          <Toolbar className="header" variant="dense">
            <IconButton
              className="header-icon"
              // edge="start"
              aria-label="menu"
              onClick={() => this.onMenuChangeOpen()}
            >
              <MenuIcon />
            </IconButton>
            <Typography className="header-title" variant="h2" component="h2">
              Campus
            </Typography>
            {this.props.userName ? (
              <div className="header-content">
                <div className="header-name">
                  <span className="header-name-username">{this.props.userName}</span>
                  <span className="header-name-user-role">{this.props.userRole}</span>
                </div>
                <Icon>account_circle</Icon>
                {/* <Link to="/" color="inherit" onClick={this.onLogout}>Logout</Link>*/}
        {/* <Link to="/Settings" color="inherit">Settings</Link> */}
        {/* </div>
            ) : null}
          </Toolbar>*/}
        {/*</AppBar>*/}
        <Drawer variant="permanent" className="side-menu">
          <List className={drawerClass}>
            <ListItem button selected={this.props.selectedMenu === 'home'} key={`home`}>
              <ListItemIcon onClick={this.onMenuChangeOpen}>
                <svg className="fig-menu-icon">
                  <use href={sprites + '#logo-icon'} />
                </svg>
              </ListItemIcon>
            </ListItem>
            <ListItem button selected={this.props.selectedMenu === 'course'} key={`menu-item-course`} component={Link} to="/dashboard">
              <ListItemIcon>
                <Tooltip {...commonTooltipProps} title="Dashboard">
                  <svg className="fig-menu-icon">
                    <use href={this.getIconName('course')} />
                  </svg>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
            {/* <ListItem button selected={this.props.selectedMenu === 'rubrics'} key={`menu-item-rubrics`} component={Link} to="/rubrics">
              <ListItemIcon>
                <Tooltip title="Rubrics" {...commonTooltipProps}>
                  <svg className="fig-menu-icon">
                    <use href={this.getIconName('rubrics')} />
                  </svg>
                </Tooltip>
              </ListItemIcon>
            </ListItem> */}
            {/* <ListItem button selected={this.props.selectedMenu === 'clients'} key={`menu-item-clients`} component={Link} to="/clients">
              <ListItemIcon>
                <Tooltip title="Clients" {...commonTooltipProps}>
                  <svg className="fig-menu-icon">
                    <use href={this.getIconName('clients')} />
                  </svg>
                </Tooltip>
              </ListItemIcon>
            </ListItem> */}
            <ListItem button selected={this.props.selectedMenu === 'profile'} key={`menu-item-profile`} component={Link} to="/profile">
              <ListItemIcon>
                <Tooltip {...commonTooltipProps} title={'Profile'}>
                  <svg className="fig-menu-icon">
                    <use href={this.getIconName('profile')} />
                  </svg>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
            {/* <ListItem
              button
              key={`menu-item-add-video`}
              selected={this.props.selectedMenu === 'add-video'}
              component={Link}
              to="/add-video"
            >
              <ListItemIcon>
                <svg className="fig-menu-icon">
                  <use href={this.getIconName('add-video')} />
                </svg>
              </ListItemIcon>
            </ListItem> */}
            {/* <ListItem button selected={this.props.selectedMenu === 'settings'} key={`menu-item-settings`} component={Link} to="/settings">
              <ListItemIcon>
                <svg className="fig-menu-icon">
                  <use href={this.getIconName('settings')} />
                </svg>
              </ListItemIcon>
            </ListItem> */}
            <ListItem button key={`menu-item-logout`} onClick={this.onLogout}>
              <ListItemIcon>
                <Tooltip title="Logout" {...commonTooltipProps}>
                  <svg className="fig-menu-icon">
                    <use href={this.getIconName('logout')} />
                  </svg>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  }

  onMenuChangeOpen = () => {
    // this.setState({ open: !this.state.open }, () => {
    //   this.props.menuChangeCollapsing(this.state.open);
    // });
  };

  onLogout = () => {
    this.props.logout().then(
      (res) => {
        this.props.history.push(getUnauthedHomePath()); // FFS back/fwd
      },
      (err) => {
        this.props.history.push(getUnauthedHomePath());
      }
    );
  };

  getIconName(itemName) {
    return `${sprites}#${itemName}-icon-${this.props.selectedMenu === itemName ? 'on' : 'off'}`;
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    userName: state.authentication.user ? state.authentication.user.username : null,
    userRole: state.authentication.user && state.authentication.user.role ? state.authentication.user.role.name : null,
  };
};
const mapDispatchToProps = {
  logout,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
