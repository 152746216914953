import { IconButton, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import sprites from '../../../../icons/fig-icons.svg';

export default function CourseStudentsTable(props) {
  const { rows } = props;
  //StudentsNumber, firstName, lastName, Section
  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={row.id} selected={false}>
                    <TableCell align="left">{row.studentId}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.section}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="justify">
                      <Tooltip title="Delete Membership">
                        <IconButton
                          aria-label="delete"
                          className="fig-button fig-delete-rubric margin-top0"
                          size="small"
                          onClick={() => props.onDeleteMembership(row.id)}
                        >
                          <svg className="fig-icon-delete-rubric-row">
                            <use href={sprites + '#delete-icon'} />
                          </svg>
                          {/* <svg className="fig-close-icon">
                          <use href={sprites + '#close-icon'} />
                        </svg> */}
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
CourseStudentsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  onDeleteMembership: PropTypes.func.isRequired,
};
