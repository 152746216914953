import React from 'react';
import { connect } from 'react-redux';
import CoursePanel from '../../demo/components/course/CoursePanel';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';
interface IProps {
  history: any;
  match: any;
}

class CourseFrame extends React.Component<IProps> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="course" history={this.props.history} />
        <div className={className}>
          <CoursePanel {...this.props} />

          {/* {this.props.match.url.indexOf(instructorPathToken()) !== -1 ? (
            <CoursePanel {...{ ...this.props, instructor: true }} />
          ) : (
            <CoursePanel {...{ ...this.props, instructor: false }} />
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(CourseFrame);
