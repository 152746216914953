// Login

export interface LoginRequest {
  identifier: string;
  password: string;
}

export interface LoginResponse {
  jwt: string;
  user: UserBasic;
}

export interface UserBasic {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked?: boolean;
  role?: IRole;
  created_at: string;
  updated_at: string;
}
export interface User {
  data: {
    id: number;
    attributes: {
      username: string;
      email: string;
      provider: string;
      confirmed: boolean;
      blocked?: boolean;
      role?: IRole;
      created_at: string;
      updated_at: string;
    };
  };
}

export const makeUserBasic = (user: User): UserBasic => {
  let { username, email, provider, confirmed, blocked, role, created_at, updated_at } = user.data.attributes;

  return {
    id: user.data.id,
    username,
    email,
    provider,
    confirmed,
    blocked,
    role,
    created_at,
    updated_at,
  };
};

export const API_ROLE_ID_LEAD = 3;
export interface IRole {
  id: number;
  name: string;
  description: string;
  type: string;
}

export const API_ROLE_USER = 'User'; // TODO ASK Wing
export const API_ROLE_COACH = 'Coach'; // TODO ASK Wing

// Highlights

export interface AWSUploadFields {
  // "url": "https://s3.amazonaws.com/fig-rocan",
  // "fields": {
  //     "key": "dev1/videos/db80f558-e646-4d9a-bf60-149a536667b8/video",
  //     "bucket": "fig-rocan",
  //     "X-Amz-Algorithm": "AWS4-HMAC-SHA256",
  //     "X-Amz-Credential": "AKIARCOUHIHKEXIR6NPV/20210618/us-east-1/s3/aws4_request",
  //     "X-Amz-Date": "20210618T071753Z",
  //     "Policy": "eyJleHBpcmF0aW9uIjoiMjAyMS0wNi0xOFQxNToxNzo1M1oiLCJjb25kaXRpb25zIjpbWyJzdGFydHMtd2l0aCIsIiRDb250ZW50LVR5cGUiLCJ2aWRlby8iXSxbImNvbnRlbnQtbGVuZ3RoLXJhbmdlIiwwLDUxMjAwMDAwMF0seyJrZXkiOiJkZXYxL3ZpZGVvcy9kYjgwZjU1OC1lNjQ2LTRkOWEtYmY2MC0xNDlhNTM2NjY3YjgvdmlkZW8ifSx7ImJ1Y2tldCI6ImZpZy1yb2NhbiJ9LHsiWC1BbXotQWxnb3JpdGhtIjoiQVdTNC1ITUFDLVNIQTI1NiJ9LHsiWC1BbXotQ3JlZGVudGlhbCI6IkFLSUFSQ09VSElIS0VYSVI2TlBWLzIwMjEwNjE4L3VzLWVhc3QtMS9zMy9hd3M0X3JlcXVlc3QifSx7IlgtQW16LURhdGUiOiIyMDIxMDYxOFQwNzE3NTNaIn1dfQ==",
  //     "X-Amz-Signature": "54c468f2930bf2484efa8cd8454f71d7f8d08864d173c0a8a6c9f834235bae85"
  url: string;
  fields: {
    key: string;
    bucket: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    Policy: string;
    'X-Ams-Signature': string;
  };
}
export const getAttemptVideoUrl = (attempt: Attempt) => {
  return `${attempt.videoFile.url}/${attempt.videoFile.fields.key}`;
};
export const getAttemptTranscriptUrl = (attempt: Attempt) => {
  const videoUrl = getAttemptVideoUrl(attempt);
  return videoUrl.substring(0, videoUrl.length - 4) + '.json';
};

export interface SUser {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked?: boolean;
  role: number;
  created_at: string;
  updated_at: string;
}
// End Submissions

export interface SettingsDatagroups {
  id: number;
  key: string;
  options: DatagroupOptions;
  created_at: string;
  updated_at: string;
}

export interface DatagroupOptions {
  name: string;
  groups: string[]; //StreamGroups;
}

////////////////////////////////////////////////////////////
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICat {
  id: number;
  descr: string;
  text: string;
}
export interface IRubricEval {
  categories: ICat[];
  grades: number[]; //1:1 with categories
  grade: number; //avg
}
export interface IEvaluation {
  person: string;
  eval: IRubricEval;
  comments: IComment[];
}
export interface IComment {
  comment: string;
  videotime: number;
}

export interface IActivityLog {
  message: string;
  time: string;
}
/////////////////////////////////////////////////////////////

export const ACTIVE = 'active';

export const STUDENT = 'student';
export const INSTRUCTOR = 'instructor';
export const REVIEWER = 'reviewer';
export const ADMIN = 'admin';

export const canViewCourse = (roles: string[]) => {
  if (roles && (roles.includes(REVIEWER) || roles.includes(INSTRUCTOR) || roles.includes(ADMIN))) {
    return true;
  }
  return false;
};

export interface Member {
  id: number;
  attributes: {
    role: string;
    createdAt: string;
    updateAt: string;
    state: string;
    data: any;
    course?: Course;
    user?: User; //forced get for course memberships
  };
}
export interface Course {
  data: {
    id: number;
    attributes: {
      semester: string;
      code: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      data: null;
    };
  };
}
export interface CourseBasic {
  id?: number; //null at Post
  semester: string;
  code: string;
  name: string;
  createdAt?: string; //null at Post
  updatedAt?: string; //null at Post
  data?: null; //null at Post
}

//////////////////////////////////////////////////////////////////////////////////////////TASK RESP
// sample taskresponse        {
//             "id": 235,
//             "attributes": {
//                 "starttime": null,
//                 "submissiontime": null,
//                 "attempts": null,
//                 "archivedattempts": null,
//                 "analysis": null,
//                 "data": null,
//                 "createdAt": "2023-06-05T18:19:50.803Z",
//                 "updatedAt": "2023-06-05T18:19:50.803Z",
//                 "task": {
//                     "data": {
//                         "id": 234,
//                         "attributes": {
//                             "name": "My Task 1 - 2023-06-05-02.19.40pm",
//                             "desc": "Task 1 Description",
//                             "type": "video",
//                             "starttime": "2023-05-10T13:00:00.000Z",
//                             "endtime": "2023-06-15T21:00:00.000Z",
//                             "options": {
//                                 "attempts": 5,
//                                 "totaltime": 15,
//                                 "attempttime": 90
//                             },
//                             "data": null,
//                             "createdAt": "2023-06-05T18:19:45.140Z",
//                             "updatedAt": "2023-06-05T18:19:45.140Z"
//                         }
//                     }
//                 }
//             }
//         }
export interface TaskRequest {
  id: number;
  attributes: {
    name: string;
    desc: string;
    type: string;
    starttime: string;
    endtime: string;
    options: TaskOptions;
    data: any;
    createdAt: string;
    updatedAt: string;
    course?: Course;
  };
}

export interface Question {
  data: QuestionInner;
}
export interface QuestionInner {
  id: number;
  attributes: {
    text: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface QuestionBasic {
  id: number;
  text: string;
  createdAt: string;
  updatedAt: string;
}

export interface TaskRequestBasic {
  id: number;
  name: string;
  desc: string;
  type: string;
  starttime: string;
  endtime: string;
  options: TaskOptions;
  data: any;
  createdAt: string;
  updatedAt: string;
  course: CourseBasic;
}
export interface TaskOptions {
  attempts: number;
  totaltime: number;
  attempttime: number;
  taskavaiability?: boolean; //undefined means true
  studentresultsaccess?: boolean;
}

export interface TaskRequestBasicWithQuestionBankAndRubric extends TaskRequestBasic {
  questionBank: string[];
  rubric: RubricQuestion[];
  rubricID?: number;
}

export interface TaskResponse {
  id: number;
  attributes: {
    starttime: string;
    submissiontime: string;
    attempts: Attempt[];
    archivedattempts: Attempt[];
    analysis: any;
    data: any;
    createdAt: string;
    updatedAt: string;
    question?: Question;
    user?: User;
    task?: {
      //missing when the response is included in evaluations answer
      data: TaskRequest;
    };
  };
}
//sample TaskResponseBasic (without attrib)  in TASK or RESPONSE or Question
//"https://campus.alpha.api.figanalytics.com/api/responses/237/startTask"
// {
//     "id": 237,
//         "starttime": "2023-06-16T06:10:00.022Z",
//             "submissiontime": null,
//                 "attempts": null,
//                     "analysis": null,
//                         "data": null,
//                             "createdAt": "2023-06-05T18:19:51.717Z",
//                                 "updatedAt": "2023-06-16T06:10:00.029Z",
//                                     "task": {
//         "id": 234,
//             "name": "My Task 1 - 2023-06-05-02.19.40pm",
//                 "desc": "Task 1 Description",
//                     "type": "video",
//                         "starttime": "2023-05-10T13:00:00.000Z",
//                             "endtime": "2023-06-15T21:00:00.000Z",
//                                 "options": {
//             "attempts": 5,
//                 "totaltime": 15,
//                     "attempttime": 90
//         },
//         "data": null,
//             "createdAt": "2023-06-05T18:19:45.140Z",
//                 "updatedAt": "2023-06-05T18:19:45.140Z"
//     },
//     "question": {
//         "id": 324,
//             "text": "What is a Liability",
//                 "createdAt": "2023-06-05T18:19:45.583Z",
//                     "updatedAt": "2023-06-05T18:19:45.583Z"
//     }
// }

export interface TaskResponseBasic {
  id: number;
  starttime: string;
  submissiontime: string;
  attempts: Attempt[];
  archivedattempts: Attempt[];
  analysis: any;
  data: any;
  createdAt: string;
  updatedAt: string;
  question?: QuestionBasic;
  user?: UserBasic;
  task?: TaskRequestBasic;
}
export const makeTaskResponseBasic = (reponse: TaskResponse) => {
  let { starttime, submissiontime, attempts, archivedattempts, analysis, data, createdAt, updatedAt, task, question, user } =
    reponse.attributes;

  //todo ffs
  //commented this out because it seems if I add  populate=question to getTaskResponsesForUser
  // I do get the question but I no loger get the course attribs, plus I don't really need the question at that place,
  // so I'll leave it with nul question for now
  // if (starttime && !question) {
  //   //TODO REMOVE THIS AFTER WING FIXES IT
  //   console.error('DB INCONSISTENCY: TaskResponse with starttime but null question, response id: ', reponse);
  //   alert('DB INCONSISTENCY: TaskResponse with starttime but null question, response id: ' + reponse.id);
  //   question = {
  //     data: {
  //       id: -1,
  //       attributes: {
  //         text: 'DUMMY QUESTION TODO WING FIX',
  //         createdAt: new Date().toString(),
  //         updatedAt: new Date().toString(),
  //       },
  //     },
  //   };
  // }

  return {
    id: reponse.id,
    starttime,
    submissiontime,
    attempts,
    archivedattempts,
    analysis,
    data,
    createdAt,
    updatedAt,
    question: question ? makeQuestionBasic(question) : null,
    task: task ? makeTaskRequestBasic(task.data) : null,
    user: user ? makeUserBasic(user) : null,
  };
};

export const makeTaskRequestBasic = (request: TaskRequest) => {
  let { name, desc, type, options, starttime, endtime, createdAt, data, updatedAt, course } = request.attributes;
  return {
    createdAt,
    data,
    desc,
    endtime,
    id: request.id,
    name,
    options,
    starttime,
    type,
    updatedAt,
    course: makeCourseBasic(course),
  } as TaskRequestBasic;
};

export const makeQuestionBasic = (question: Question) => {
  if (question) {
    let { createdAt, text, updatedAt } = question.data.attributes;
    return {
      createdAt,
      text,
      updatedAt,
      id: question.data.id,
    } as QuestionBasic;
  } else return null as QuestionBasic;
};

export const makeCourseBasic = (course: Course) => {
  //console.log('makeCourseBasic called, course: ', course);
  if (course) {
    //TODO REMOVE THIS AFTER WING FIXES IT
    if (!course.data || !course.data.attributes) {
      console.error('DB INCONSISTENCY makeCourseBasic with malformed course', course);
      alert('DB INCONSISTENCY, FIX THIS: TaskCourse with no data or attributes, id : ' + (course.data ? course.data.id : undefined));
      return {
        createdAt: new Date().toISOString(),
        data: null,
        updatedAt: new Date().toISOString(),
        semester: 'TODO WING FIX SEMESTER',
        code: 'TODO WING FIX COURSE CODE',
        name: 'TODO WING FIX COURSE NAME',
        id: course.data ? course.data.id : -1,
      };
    }
    let { createdAt, data, updatedAt, semester, code, name } = course.data.attributes;
    return {
      createdAt,
      data,
      updatedAt,
      semester,
      code,
      name,
      id: course.data.id,
    } as CourseBasic;
  } else return null as CourseBasic;
};

export const makeCourse = (course: CourseBasic) => {
  if (course) {
    return {
      data: {
        id: course.id,
        attributes: {
          semester: course.semester,
          code: course.code,
          name: course.name,
          createdAt: course.createdAt,
          updatedAt: course.updatedAt,
          data: course.data,
        },
      },
    } as Course;
  } else return null as Course;
};

export interface EvaluationBasic {
  id: number;
  rubric: number[];
  comments: Comment[];
  data: any;
  createdAt: string;
  updatedAt: string;
  user?: UserBasic;
  response?: TaskResponseBasic;
  responseId?: number;
}
export interface Evaluation {
  id: number;
  attributes: {
    rubric: number[];
    comments: Comment[];
    data: any;
    createdAt: string;
    updatedAt: string;
    user?: User;
    // response?: { data: TaskResponse|id: number; };
    response?: ResponseOrResponseId; //Response in axios result, Id after extraction
  };
}
export type ResponseOrResponseId = { data: TaskResponse } | { responseId: number };

export const makeEvaluationBasic = (evaluation: Evaluation) => {
  if (evaluation) {
    let { createdAt, data, updatedAt, user, comments, rubric, response } = evaluation.attributes;
    return {
      createdAt,
      data,
      updatedAt,
      rubric,
      comments,
      id: evaluation.id,
      user: user ? makeUserBasic(user) : null,
      response: response ? makeTaskResponseBasic((response as unknown as { data: TaskResponse }).data) : null,
      responseId: response ? (response as unknown as { data: TaskResponse }).data.id : null,
    } as EvaluationBasic;
  } else return null as EvaluationBasic;
};

export interface Comment {
  text: string;
  time: number;
}

export const NOT_STARTED = 'Not Started'; //time has not come
export const INCOMPLETE = 'Incomplete'; //didn't click Start
export const CONTINUE = 'Continue';
export const SUBMITTED = 'Submitted';

export const getResponseStateAsStudent = (response: TaskResponseBasic) => {
  const crt = new Date();
  const task = response.task;

  const startTime = new Date(task.starttime);
  if (startTime.getTime() > crt.getTime()) {
    return NOT_STARTED;
  } else if (response.starttime == null) {
    return INCOMPLETE;
  } else if (response.submissiontime == null) {
    return CONTINUE;
  } else {
    return SUBMITTED;
  }
};

export const PENDING = 'Pending'; //didn't click Start
export const STARTED = 'Started';
export const GRADED = 'Graded';
// export const canBeEvaluated = (status) => !(status === PENDING || status === STARTED);
export const canBeEvaluated = (status) => {
  console.log('canBeEval ', status, !(status === PENDING || status === STARTED));
  return !(status === PENDING || status === STARTED);
};

export const getResponseStateEvalAsReviewer = (response: TaskResponseBasic, evaluations: EvaluationBasic[], userID: number) => {
  // //console.log('******getResponseStateEvalAsReviewer ', response, evaluations, userID);
  //TODO FFS inconsistency? - IF multiple evaluations exists, some of them graded but some not,when should we show GRaded ?
  //TODO FFS eval screen shows just my own evaluation
  //return GRADED if there is an evaluation graded by the given userID
  if (evaluations && evaluations.length > 0) {
    const myEval = evaluations.find((evaluation) => evaluation.user && evaluation.user.id === userID && isGraded(evaluation));
    if (myEval) {
      return GRADED;
    }
  }

  if (response.submissiontime != null) {
    return SUBMITTED;
  } else if (response.starttime != null) {
    return STARTED;
  } else {
    return PENDING;
  }
};
export const isGraded = (evaluation: EvaluationBasic) => {
  return (
    evaluation &&
    evaluation.rubric &&
    -1 === evaluation.rubric.findIndex((rubricEntry) => rubricEntry === null || rubricEntry === undefined)
  );
};

//Response.starttime != null & Response.submissiontime == null : "Started"
export const ATTEMPT_COMPLETED = 'completed';
export const ATTEMPT_STARTED = 'started';
export const ATTEMPT_STOPPED = 'stopped';
export const ATTEMPT_SUBMITTED = 'submitted';
export interface Attempt {
  state: string;
  stoptime?: number;
  starttime?: number;
  videoFile?: AWSUploadFields;
  uploadtime?: number;
}

export const isUploaded = (attempt: Attempt) => attempt.state === ATTEMPT_COMPLETED || attempt.state === ATTEMPT_SUBMITTED;
export const isSubmitted = (attempt: Attempt) => attempt.state === ATTEMPT_SUBMITTED;
export interface AWSUploadFields {
  // url: 'https://s3.amazonaws.com/figserver';
  // fields: {
  //   key: 'dev/campus/roca/responses/video237-1686836018551.mp4';
  //   Policy: 'eyJleHBpcmF0aW9uIjoiMjAyMy0wNi0xNVQxNDoxODozOFoiLCJjb25kaXRpb25zIjpbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsMCwxMDAwMDAwMDBdLHsia2V5IjoiZGV2L2NhbXB1cy9yb2NhL3Jlc3BvbnNlcy92aWRlbzIzNy0xNjg2ODM2MDE4NTUxLm1wNCJ9LHsiYnVja2V0IjoiZmlnc2VydmVyIn0seyJYLUFtei1BbGdvcml0aG0iOiJBV1M0LUhNQUMtU0hBMjU2In0seyJYLUFtei1DcmVkZW50aWFsIjoiQUtJQVJDT1VISUhLRVhJUjZOUFYvMjAyMzA2MTUvdXMtZWFzdC0xL3MzL2F3czRfcmVxdWVzdCJ9LHsiWC1BbXotRGF0ZSI6IjIwMjMwNjE1VDEzMzMzOFoifV19';
  //   bucket: 'figserver';
  //   'X-Amz-Date': '20230615T133338Z';
  //   'X-Amz-Algorithm': 'AWS4-HMAC-SHA256';
  //   'X-Amz-Signature': 'e4a54041946d5e59df6b4fde6b1597ea39f15b72b01341435fa95f2bf214048e';
  //   'X-Amz-Credential': 'AKIARCOUHIHKEXIR6NPV/20230615/us-east-1/s3/aws4_request';
  // };
  url: string;
  fields: {
    key: string;
    bucket: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    Policy: string;
    'X-Ams-Signature': string;
  };
}

// {attempt
//                     "state": "submitted",
//                     "stoptime": 1686836018551,
//                     "starttime": 1686836017840,
//                     "videoFile": {
//                         "url": "https://s3.amazonaws.com/figserver",
//                         "fields": {
//                             "key": "dev/campus/roca/responses/video237-1686836018551.mp4",
//                             "Policy": "eyJleHBpcmF0aW9uIjoiMjAyMy0wNi0xNVQxNDoxODozOFoiLCJjb25kaXRpb25zIjpbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsMCwxMDAwMDAwMDBdLHsia2V5IjoiZGV2L2NhbXB1cy9yb2NhL3Jlc3BvbnNlcy92aWRlbzIzNy0xNjg2ODM2MDE4NTUxLm1wNCJ9LHsiYnVja2V0IjoiZmlnc2VydmVyIn0seyJYLUFtei1BbGdvcml0aG0iOiJBV1M0LUhNQUMtU0hBMjU2In0seyJYLUFtei1DcmVkZW50aWFsIjoiQUtJQVJDT1VISUhLRVhJUjZOUFYvMjAyMzA2MTUvdXMtZWFzdC0xL3MzL2F3czRfcmVxdWVzdCJ9LHsiWC1BbXotRGF0ZSI6IjIwMjMwNjE1VDEzMzMzOFoifV19",
//                             "bucket": "figserver",
//                             "X-Amz-Date": "20230615T133338Z",
//                             "X-Amz-Algorithm": "AWS4-HMAC-SHA256",
//                             "X-Amz-Signature": "e4a54041946d5e59df6b4fde6b1597ea39f15b72b01341435fa95f2bf214048e",
//                             "X-Amz-Credential": "AKIARCOUHIHKEXIR6NPV/20230615/us-east-1/s3/aws4_request"
//                         }
//                     },
//                     "uploadtime": 1686836019758
//                 }

//TRANSCRIPT.json
// {
//   "jobName":"video235-1687247854067.mp4-369974",
//   "accountId":"073996386772",
//   "results":{
//       "transcripts":
//       [
//           {"transcript":"Democrats and Republicans about what to do about the acts they were passing for billions of dollars to make sure people had the capacity. But you haven't ruled out more shutdowns. No, I, I I'm not shutting down the night but there are, look, they need standards. The standard is if you have a reproduction rate in a community that's above a certain level, everybody says."
//           }
//       ],
// },"status":"COMPLETED"}
export interface TranscriptResult {
  results: {
    transcripts: TranscriptBasic[];
  };
  status: string;
}
export interface TranscriptBasic {
  transcript: string;
}

export const getTranscriptText = (transcriptResult: TranscriptResult) => {
  const res: TranscriptBasic[] = transcriptResult.results.transcripts;
  return res.reduce((accumulator, crtTranscript) => accumulator + crtTranscript.transcript, '');
};

export interface Rubric {
  id: number;
  attributes: {
    questions: RubricQuestion[];
    createdAt: string;
    updatedAt: string;
  };
}

export interface RubricQuestion {
  text: string;
  score: number;
  weight: number;
}

export interface RubricBasic {
  id: number;
  questions: RubricQuestion[];
  createdAt: string;
  updatedAt: string;
}

export const makeRubricBasic = (rubric: Rubric) => {
  let { createdAt, updatedAt, questions } = rubric.attributes;

  return {
    id: rubric.id,
    createdAt,
    updatedAt,
    questions,
  };
};

/////////////////// Rubrics Figaro style
export interface FRubric extends FRubricBase {
  id: number;
  name: string;
  type: string;
  template: Template;
  data?: any;
  createdby: SUser;
  created_at: string;
  updated_at: string;
}
export interface FRubricBase {
  name?: string;
  type?: string;
  template?: Template;
  data?: any;
}
export const getRubricBase = (rubric: FRubric) => {
  let newRubric = { ...rubric };
  delete newRubric.created_at;
  delete newRubric.createdby;
  delete newRubric.id;
  delete newRubric.updated_at;
  return newRubric as FRubricBase;
};
export const fakeRubric = (rubricBase: FRubricBase, id: number) => {
  let newRubric = { ...rubricBase, created_at: null, createdby: null, updated_at: null, id: id };
  return newRubric as FRubric;
};
export interface Template {
  categories: Category[];
}

export interface Category {
  name: string;
  questions: string[];
  averages?: number[];
}
//////////////////////////////////////////////////////////

export interface ShareBase {
  id?: number;
  user?: UserBasic; // null if shared to email not matching figaro user
  state?: string;
  email?: string; // null if shared to email matching figaro user
  type?: any;
  data?: any;
  createdby?: SUser;
  created_at?: string;
  updated_at?: string;
}

export const getEmailAddress = (share: ShareBase) => {
  if (share.user) return share.user.email;
  else return share.email;
};
