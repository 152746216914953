import React from 'react';
import { connect } from 'react-redux';
import { evalPathToken, overviewPathToken, resultsPathToken } from '../../../app';
import ReviewerTaskPanel from '../../demo/components/course/ReviewerTaskPanel';
import StudentTaskPanel from '../../demo/components/course/StudentTaskPanel';
import StudentTaskResults from '../../demo/components/course/StudentTaskResults';
import TaskEval from '../../demo/components/course/TaskEval';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';
interface IProps {
  history: any;
  match: any;
}

class TaskFrame extends React.Component<IProps> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="course" history={this.props.history} />
        <div className={className}>
          {this.props.match.url.indexOf(resultsPathToken()) !== -1 ? (
            <StudentTaskResults {...this.props} />
          ) : this.props.match.url.indexOf(evalPathToken()) !== -1 ? (
            <TaskEval {...this.props} />
          ) : this.props.match.url.indexOf(overviewPathToken()) !== -1 ? (
            <ReviewerTaskPanel {...this.props} />
          ) : (
            <StudentTaskPanel {...this.props} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(TaskFrame);
