import axios from 'axios';


const baseURL = process.env.REACT_APP_BASE_URL
export const s3URL = process.env.REACT_APP_BASE_S3
export const settingsURL = 'settings'
export const rubricsURL = 'rubrics'
export const membersURL = 'members'
export const registerURL = 'auth/local/register'
export const confirmEMailURL = 'auth/email-confirmation'
export const forgotPasswordURL = 'auth/forgot-password'
export const resetPasswordURL = 'auth/reset-password'
export const resendConfirmationEMailURL = 'auth/send-email-confirmation'

export const coursesURL = 'courses'
export const tasksURL = 'tasks'
export const responsesURL = 'responses'
export const questionsURL = 'questions'
export const evaluationsURL = 'evaluations'
export const loginURL = 'auth/local';
export const usersURL = 'users';
export default axios.create({
    baseURL
});
