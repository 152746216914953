import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  addInstructor,
  addReviewer,
  addRubric2Task,
  addStudent,
  addTaskRequest,
  courseSelected,
  deleteMembership,
  getCourse,
  getCourseInstructors,
  getCourseReviewers,
  getCourseStudents,
  getCourseTaskRequests,
  getOwnRoles,
  placeBankQuestions2Task,
  roleSelected,
} from '../../actions/campusApiActions';
import {
  ADMIN,
  INSTRUCTOR,
  Member,
  REVIEWER,
  STUDENT,
  TaskRequestBasicWithQuestionBankAndRubric,
  makeCourseBasic,
} from '../../model/ApiTypes';
import { formatCampusLongDate } from '../../utils/utils';
import CourseInstructorsReviewersTable from './CourseInstructorsReviewersTable';
import CourseStudentsTable from './CourseStudentsTable';
import TaskForm from './TaskForm';
import TaskRequestsTable from './TaskRequestsTable';

interface IProps extends PropsFromRedux {
  history: any;
  match: any;
  //instructor: boolean; it is now deducted from curent user and course
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  viewMode: ViewMode;
  lines: string; //lines with students/etc addresses
  taskRequest: TaskRequestBasicWithQuestionBankAndRubric;

  editQuestionsDialogOpened: boolean;
  editRubricDialogOpened: boolean;
}

enum ViewMode {
  Students,
  Instructors,
  Reviewers,
  AddTask,
  Tasks,
}
class CoursePanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    const crt = new Date();
    let ts = formatCampusLongDate(crt);

    return {
      viewMode: ViewMode.Tasks,
      lines: '',
      editQuestionsDialogOpened: false,
      editRubricDialogOpened: false,

      // trStartTime: toTimeLocalInput(crt),
      // trEndTime: toTimeLocalInput(crt),
      taskRequest: {
        id: null,
        name: `My Task  from ${ts}`,
        desc: `My Task from ${ts} Description TODO`,
        type: 'video',
        // starttime: new Date().toISOString(),
        // endtime: new Date().toISOString(),
        starttime: '',
        endtime: '',

        options: {
          attempts: 5,
          attempttime: 90,
          totaltime: 15,
        },
        course: null,
        questionBank: ['Sample Question 1', 'Sample Question 2'],
        rubric: [
          {
            text: 'Sample Rubric 1',
            score: 100,
            weight: 60,
          },
          {
            text: 'Sample  Rubric 2',
            score: 100,
            weight: 40,
          },
        ],
        data: null,
        createdAt: null,
        updatedAt: null,
      },
    };
  };

  static NO_MEMBERS_YET = [] as Member[];

  componentDidMount = () => {
    this.load(this.props.match.params.id);
  };
  componentWillUnmount = () => {
    console.log('CoursePanel will unmount ', this.props);
    this.cleanupOnExitTask();
  };

  cleanupOnExitTask = () => {};

  componentDidUpdate(prevProps: IProps) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      console.log('CoursePanel will load another course ', id);
      this.cleanupOnExitTask();
      this.load(id);
    }
  }

  load(id) {
    this.props.courseSelected(id).then(
      (ok) => {
        this.props.getCourse(id, this.props.authentication.token);
        this.props.getCourseTaskRequests(id, this.props.authentication.token);
        this.props.getOwnRoles(id, this.props.authentication.user.id, this.props.authentication.token);
      },
      (err) => {}
    );
  }

  render() {
    console.log('CoursePanel render ', this.props);
    if (this.props.currentCourse == null) return null;
    const course = this.props.currentCourse.data.attributes;

    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>{`${course.code} - ${course.name} (${course.semester})`}</span>
          </div>
          <div className="row">
            <div className="col-2 padding-top--30 ">{this.renderButtonsMenu()}</div>
            <div className="col-10 no-padding">
              {this.state.viewMode === ViewMode.Tasks ? this.renderTasks() : null}
              {this.state.viewMode === ViewMode.Students ? this.renderStudents() : null}
              {this.state.viewMode === ViewMode.Instructors || this.state.viewMode === ViewMode.Reviewers
                ? this.renderReviewersInstructors()
                : null}
              {this.state.viewMode === ViewMode.AddTask ? this.renderAddTask() : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderButtonsMenu() {
    let usefullHeight = window.innerHeight - 185;
    return (
      <div style={{ height: usefullHeight, overflowY: 'auto' }}>
        <div className="fig-generic-pane-right-side">
          <Button
            className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.Tasks ? ' fig-button__grey' : '')}
            variant="outlined"
            onClick={this.onTasks}
          >
            Tasks
          </Button>
        </div>
        {this.props.instructor ? (
          <>
            <div className="fig-generic-pane-right-side">
              <Button
                className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.AddTask ? ' fig-button__grey' : '')}
                variant="outlined"
                onClick={this.onAddTask}
              >
                Add A Task
              </Button>
            </div>
            <div className="fig-generic-pane-right-side">
              <Button
                className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.Students ? ' fig-button__grey' : '')}
                variant="outlined"
                onClick={this.onStudents}
              >
                Students
              </Button>
            </div>
            <div className="fig-generic-pane-right-side">
              <Button
                className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.Reviewers ? ' fig-button__grey' : '')}
                variant="outlined"
                onClick={this.onReviewers}
              >
                Reviewers
              </Button>
            </div>
            <div className={'fig-generic-pane-right-side'}>
              <Button
                className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.Instructors ? ' fig-button__grey' : '')}
                variant="outlined"
                onClick={this.onInstructors}
                // color={this.state.viewMode === ViewMode.Instructors ? 'secondary' : 'inherit'}
              >
                Instructors
              </Button>
            </div>
          </>
        ) : null}
      </div>
    );
  }
  renderTasks() {
    let usefullHeight = window.innerHeight - 215;
    console.log('CoursePanel renderTasks, usefull height, props, innerH ', usefullHeight, this.props, window.innerHeight);

    return (
      <div className="fig-generic-pane-right-side">
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
              <span>Tasks</span>
            </div>
          </div>
        </div>
        <div className="fig-rubrics-table-holder" key="tasks" style={{ height: usefullHeight, overflowY: 'auto' }}>
          <TaskRequestsTable rows={this.getTaskRequestsRows()} handleOverviewTask={this.viewTask}></TaskRequestsTable>
        </div>
      </div>
    );
  }

  getTaskRequestsRows() {
    const course = this.props.currentCourse.data.attributes;
    const reqRows = this.props.taskRequests
      ? Object.keys(this.props.taskRequests).map((taskId) => {
          const taskReq = this.props.taskRequests[taskId];
          return {
            id: taskId,
            type: taskReq.type,
            course: course.code,
            name: taskReq.name,
            attempts: taskReq.options.attempts,
            attemptTime: taskReq.options.attempttime,
            totalTime: taskReq.options.totaltime,
            ends_at: taskReq.endtime,
            starts_at: taskReq.starttime,
          };
        })
      : [];
    return reqRows;
  }
  renderStudents() {
    let usefullHeight = window.innerHeight - 125;
    console.log('CoursePanel renderStudents, usefull height ', usefullHeight, this.props);

    return (
      <div className="fig-generic-pane-right-side" style={{ height: usefullHeight, overflowY: 'auto' }}>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
              <span>Add Students</span>
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header21">
          <div className="row w-100">
            <div className="textarea-boxGray w-100 no-padding">
              <TextField
                className="w-100"
                multiline={true}
                minRows={10}
                value={this.state.lines}
                variant="outlined"
                onChange={(event) => this.setState({ lines: event.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row">
              <span>One e-mail per line</span>
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header31">
          <div className="row fig-horizontal-center">
            <Button className="fig-button margin-left--10 fig-button__dark" onClick={this.onAddStudents}>
              Add
            </Button>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header4">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%' */ }}>
              <span>Students</span>
            </div>
          </div>
        </div>

        <div className="fig-rubrics-table-holder" key="students">
          <CourseStudentsTable
            rows={
              this.props.currentCourseMemberships
                ? this.props.currentCourseMemberships.map((member) => {
                    return {
                      id: member.id,
                      studentId:
                        member.attributes && member.attributes.user && member.attributes.user.data ? member.attributes.user.data.id : null,
                      name:
                        member.attributes && member.attributes.user && member.attributes.user.data && member.attributes.user.data.attributes
                          ? member.attributes.user.data.attributes.username
                          : '',
                      email:
                        member.attributes && member.attributes.user && member.attributes.user.data && member.attributes.user.data.attributes
                          ? member.attributes.user.data.attributes.email
                          : '',
                      status: member.attributes && member.attributes.state,
                    };
                  })
                : []
            }
            onDeleteMembership={this.deleteMembership}
          ></CourseStudentsTable>
        </div>
      </div>
    );
  }

  renderReviewersInstructors() {
    let usefullHeight = window.innerHeight - 125;
    console.log('CoursePanel renderReviewersInstructors, usefull height ', usefullHeight, this.props);

    const instructors = this.state.viewMode === ViewMode.Instructors;
    return (
      <div className="fig-generic-pane-right-side" style={{ height: usefullHeight, overflowY: 'auto' }}>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row">
              <span>{!instructors ? `Add Reviewers` : `Add Instructors`}</span>
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header21">
          <div className="row w-100">
            <div className="textarea-boxGray w-100 no-padding">
              <TextField
                className="w-100"
                multiline={true}
                minRows={10}
                value={this.state.lines}
                variant="outlined"
                onChange={(event) => this.setState({ lines: event.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row">
              <span>One email per line</span>
            </div>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header31">
          <div className="row fig-horizontal-center">
            <Button
              className="fig-button margin-left--10 fig-button__dark"
              onClick={instructors ? this.onAddInstructors : this.onAddReviewers}
            >
              Add
            </Button>
          </div>
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header4">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
              <span>{!instructors ? `Reviewers` : `Instructors`}</span>
            </div>
          </div>
        </div>
        <div className="fig-rubrics-table-holder" key="reviewers">
          <CourseInstructorsReviewersTable
            rows={
              this.props.currentCourseMemberships
                ? this.props.currentCourseMemberships.map((member) => {
                    return {
                      id: member.id,
                      tId:
                        member.attributes.user && member.attributes.user.data && member.attributes.user.data.id
                          ? member.attributes.user.data.id
                          : null,
                      name:
                        member.attributes && member.attributes.user && member.attributes.user.data && member.attributes.user.data.attributes
                          ? member.attributes.user.data.attributes.username
                          : '',
                      email:
                        member.attributes && member.attributes.user && member.attributes.user.data && member.attributes.user.data.attributes
                          ? member.attributes.user.data.attributes.email
                          : '',
                      status: member.attributes ? member.attributes.state : '',
                    };
                  })
                : []
            }
            onDeleteMembership={this.deleteMembership}
          ></CourseInstructorsReviewersTable>
        </div>
      </div>
    );
  }
  renderAddTask() {
    let usefullHeight = window.innerHeight - 125;
    return (
      <div style={{ height: usefullHeight, overflowY: 'auto' }}>
        <TaskForm
          addTask={this.addTask}
          saveTask={null}
          currentTaskRequest={null}
          windowHeight={this.props.windowHeight}
          setGlobalAlert={this.props.setGlobalAlert}
        />{' '}
      </div>
    );
  }

  viewTask = (id) => {
    this.props.history.push(`/taskview/${id}`);
  };

  deleteMembership = (id) => {
    this.props.deleteMembership(id, this.props.authentication.token).then(
      () => {
        this.props.getOwnRoles(this.props.currentCourseId, this.props.authentication.user.id, this.props.authentication.token);
      },
      (err) => {}
    );
  };

  onStudents = () =>
    this.setState({ viewMode: ViewMode.Students, lines: '' }, () => {
      this.loadCourseStudents();
    });
  onReviewers = () =>
    this.setState({ viewMode: ViewMode.Reviewers, lines: '' }, () => {
      this.loadCourseReviewers();
    });
  onInstructors = () =>
    this.setState({ viewMode: ViewMode.Instructors, lines: '' }, () => {
      this.loadCourseInstructors();
    });
  onTasks = () => this.setState({ viewMode: ViewMode.Tasks });
  onAddTask = () => this.setState({ viewMode: ViewMode.AddTask });

  addRoleStatus = {
    failedLines: '',
    finishedLines: 0,
    totalLines: 0,
  };

  onAddReviewers = () => {
    const lines = this.state.lines.split('\n').filter((line) => line.trim().length);
    this.addRoleStatus.failedLines = '';
    this.addRoleStatus.finishedLines = 0;
    this.addRoleStatus.totalLines = lines.length;

    lines.forEach((line, idx) => {
      this.props.addReviewer(line, this.props.currentCourse.data.id, this.props.authentication.token).then(
        () => {
          this.processCompletedAddressLine(this.loadCourseReviewers);
        },
        (err) => {
          this.processCompletedAddressLine(this.loadCourseReviewers, line, err);
        }
      );
    });
  };

  //refresh table at the end. Currntly no user info is porvided by the backend
  processCompletedAddressLine = (refreshFn, errLine?, err?) => {
    this.addRoleStatus.finishedLines++;
    if (errLine) {
      console.log('Add failed for ', errLine, err);
      this.addRoleStatus.failedLines = this.addRoleStatus.failedLines + errLine + '\n';
    }
    if (this.addRoleStatus.finishedLines === this.addRoleStatus.totalLines) {
      if (this.addRoleStatus.failedLines.length /*this.state.failedLines.length*/) {
        this.props.setGlobalAlert(
          'The following e-mails failed to be added: \n' + /*this.state.failedLines*/ this.addRoleStatus.failedLines
        );
      }
      this.setState({ lines: '' });
      refreshFn(); //call the refresh table method
      this.props.getOwnRoles(this.props.currentCourseId, this.props.authentication.user.id, this.props.authentication.token);
    }
  };
  onAddInstructors = () => {
    const lines = this.state.lines.split('\n').filter((line) => line.trim().length);
    this.addRoleStatus.failedLines = '';
    this.addRoleStatus.finishedLines = 0;
    this.addRoleStatus.totalLines = lines.length;

    lines.forEach((line, idx) => {
      this.props.addInstructor(line, this.props.currentCourse.data.id, this.props.authentication.token).then(
        () => {
          this.processCompletedAddressLine(this.loadCourseInstructors);
        },
        (err) => {
          this.processCompletedAddressLine(this.loadCourseInstructors, line, err);
        }
      );
    });
  };
  onAddStudents = () => {
    const lines = this.state.lines.split('\n').filter((line) => line.trim().length);
    this.addRoleStatus.failedLines = '';
    this.addRoleStatus.finishedLines = 0;
    this.addRoleStatus.totalLines = lines.length;

    lines.forEach((line, idx) => {
      this.props.addStudent(line, this.props.currentCourse.data.id, this.props.authentication.token).then(
        () => {
          this.processCompletedAddressLine(this.loadCourseStudents);
        },
        (err) => {
          this.processCompletedAddressLine(this.loadCourseStudents, line, err);
        }
      );
    });
  };

  addTask = (taskRequest: TaskRequestBasicWithQuestionBankAndRubric) => {
    this.props
      .addTaskRequest(
        {
          ...taskRequest,
          course: this.props.currentCourse
            ? makeCourseBasic(this.props.currentCourse)
            : { id: this.props.currentCourseId, semester: null, code: null, createdAt: null, data: null, name: null }, //just ID needed
        },
        this.props.authentication.token
      )
      .then(
        (taskReq) => {
          console.log('task request created: ', taskReq);
          this.props.addRubric2Task(taskRequest.rubric, taskReq.id, this.props.authentication.token).then(
            () => {
              console.log('rubric added: ');
              this.props.placeBankQuestions2Task(taskRequest.questionBank, taskReq.id, this.props.authentication.token).then(
                () => {
                  console.log('questions added ');
                  this.onTasks();
                },
                (err) => {
                  this.onTasks();
                }
              );
            },
            (err) => {
              this.onTasks();
            }
          );
        },
        (err) => {
          this.onTasks();
        }
      );
  };
  loadCourseStudents = () => {
    this.props.roleSelected(STUDENT);
    this.props.getCourseStudents(this.props.currentCourse.data.id, this.props.authentication.token);
  };
  loadCourseReviewers = () => {
    this.props.roleSelected(REVIEWER);
    this.props.getCourseReviewers(this.props.currentCourse.data.id, this.props.authentication.token);
  };
  loadCourseInstructors = () => {
    this.props.roleSelected(INSTRUCTOR);
    this.props.getCourseInstructors(this.props.currentCourse.data.id, this.props.authentication.token);
  };
}
const selectorCrtCourse = (state: IRootState) =>
  state.courses.selectedCourseId && state.courses.coursesMap[state.courses.selectedCourseId]
    ? state.courses.coursesMap[state.courses.selectedCourseId]
    : null;

const selectorCrtRole = (state: IRootState) => state.courses.selectedRole;

const selectorCourseMemberships = (state: IRootState) => {
  let res = state.courses.courseMembershipMap ? Object.values(state.courses.courseMembershipMap) : null;

  if (res && res.length > 0) {
    return res;
  } else if (res) {
    return CoursePanel.NO_MEMBERS_YET;
  } else return null;
};

const selectorCrtCourseMembership = createSelector(
  [selectorCrtRole, selectorCrtCourse, selectorCourseMemberships],
  (role, course, members) => {
    console.log('selectorCrtCourseMembership called with role, course, members : ', role, course, members);
    return role != null && course != null && members != null ? members : null;
  }
);

const mapStateToProps = (state: IRootState) => {
  console.log('CoursePanel mapStateToProps ', state);
  return {
    authentication: state.authentication,
    taskRequests: state.courses.taskRequestsMap,
    currentCourse: selectorCrtCourse(state),
    currentCourseMemberships: selectorCrtCourseMembership(state),
    currentCourseId: state.courses.selectedCourseId,
    windowHeight: state.utils.windowHeight,
    //actually this replaces old functionality of instructor property (meaning is Instructor or is Admin)
    instructor:
      state.courses.ownCrtCourseRoles &&
      (state.courses.ownCrtCourseRoles.includes(INSTRUCTOR) || state.courses.ownCrtCourseRoles.includes(ADMIN)),
  };
};

const mapDispatchToProps = {
  courseSelected,
  getCourseTaskRequests,
  addReviewer,
  addInstructor,
  addStudent,
  getCourseReviewers,
  getCourseStudents,
  getCourseInstructors,
  roleSelected,
  deleteMembership,
  addTaskRequest,
  addRubric2Task,
  placeBankQuestions2Task,
  setGlobalAlert,
  getCourse,
  getOwnRoles,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CoursePanel);
