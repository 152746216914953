import {
  Course,
  EvaluationBasic,
  FRubric,
  FRubricBase,
  IRole,
  LoginResponse,
  Member,
  QuestionBasic,
  RubricBasic,
  TaskRequestBasic,
  TaskResponseBasic,
  TranscriptResult,
} from '../../model/ApiTypes';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const AUTH_ROLE = 'AUTH_ROLE';
// logged in
interface AuthUserAction {
  type: typeof AUTH_USER;
  payload: LoginResponse;
}

// log in failed
interface AuthErrorAction {
  type: typeof AUTH_ERROR;
  payload: { message: string; error: any };
}

// logged out
export interface AuthClearAction {
  type: typeof AUTH_CLEAR;
  // payload: string;
}
export interface AuthRoleAction {
  type: typeof AUTH_ROLE;
  payload: IRole;
}

export type AuthActionTypes = AuthUserAction | AuthClearAction | AuthErrorAction | AuthRoleAction;

export const SETTINGS_DATAGROUPS = 'SETTINGS_DATAGROUPS';
export interface SettingsDatagroupsAction {
  type: typeof SETTINGS_DATAGROUPS;
  payload: {
    allFaceCategories: string[]; //StreamCategories;
    allVoiceCategories: string[]; //StreamCategories;
  };
}

export const SETTINGS_GLOBAL_RUBRICS = 'GLOBAL_RUBRICS';
export interface GlobalRubricsAction {
  type: typeof SETTINGS_GLOBAL_RUBRICS;
  payload: { rubrics: FRubricBase[] };
}

export type SettingsActionTypes = SettingsDatagroupsAction | GlobalRubricsAction;

export const OWN_FRUBRICS = 'OWN_RUBRICS';
export const RUBRIC_DELETED = 'RUBRIC_DELETED';
export const RUBRIC_UPDATED = 'RUBRIC_UPDATED';
export const RUBRIC_ADDED = 'RUBRIC_ADDED';
export const TASK_RUBRICS = 'TASK_RUBRICS';

export interface RubricsAction {
  type: typeof OWN_FRUBRICS;
  payload: { rubrics: FRubric[] };
}
export interface TaskRubricsAction {
  type: typeof TASK_RUBRICS;
  payload: { rubrics: RubricBasic[] };
}

// export interface RubricDeletedAction {
//   type: typeof RUBRIC_DELETED;
//   payload: { rubricId: number };
// }
// export interface RubricUpdatedAction {
//   type: typeof RUBRIC_UPDATED;
//   payload: { rubricId: number; rubric: Rubric };
// }
// export interface RubricAddedAction {
//   type: typeof RUBRIC_ADDED;
//   payload: { rubricId: number; rubric: Rubric };
// }

export const TASK_QUESTIONS = 'TASKS_QUESTIONS';
export interface TaskQuestionsAction {
  type: typeof TASK_QUESTIONS;
  payload: { taskQuestions: QuestionBasic[] };
}

export type RubricsActionTypes = RubricsAction | TaskRubricsAction | TaskQuestionsAction; //| RubricDeletedAction | RubricUpdatedAction | RubricAddedAction;

export const OWN_ROLES = 'OWN_ROLES';
export interface OwnRolesAction {
  type: typeof OWN_ROLES;
  payload: { userId: number; courseId: number; memberships: Member[] };
}

export const OWN_COURSES = 'OWN_COURSES';
export interface OwnCoursesAction {
  type: typeof OWN_COURSES;
  payload: { memberships: Member[] };
}
export const COURSE = 'COURSE';
export interface CourseAction {
  type: typeof COURSE;
  payload: { course: Course };
}

export const COURSE_SELECTED = 'COURSE_SELECTED';
export interface CourseSelectedAction {
  type: typeof COURSE_SELECTED;
  payload: { id: number };
}

export const ROLE_SELECTED = 'ROLE_SELECTED';
export interface RoleSelectedAction {
  type: typeof ROLE_SELECTED;
  payload: { role: string };
}

export const COURSE_MEMBERSHIP = 'COURSE_MEMBERSHIP';
export interface CourseMembershipAction {
  type: typeof COURSE_MEMBERSHIP;
  payload: { member: Member }; //without course info TODO FFS
}

export const COURSE_MEMBERSHIP_DELETED = 'COURSE_MEMBERSHIP_DELETED';
export interface CourseMembershipDeletedAction {
  type: typeof COURSE_MEMBERSHIP_DELETED;
  payload: { member: Member }; //without course info TODO FFS
}

export const COURSE_MEMBERSHIPS = 'COURSE_MEMBERSHIPS';
export interface CourseMembershipsAction {
  type: typeof COURSE_MEMBERSHIPS;
  payload: { memberships: Member[] };
}

export const COURSE_TASK_REQUESTS = 'COURSE_TASK_REQUESTS';
export interface CourseTaskRequestsAction {
  type: typeof COURSE_TASK_REQUESTS;
  payload: { code: number; requests: TaskRequestBasic[] };
}

export const TASK_REQUEST_SELECTED = 'TASK_REQUEST_SELECTED';
export interface TaskRequestSelectedAction {
  type: typeof TASK_REQUEST_SELECTED;
  payload: { id: number };
}

export const TASK_REQUEST = 'TASK_REQUEST';
export interface TaskRequestAction {
  type: typeof TASK_REQUEST;
  payload: { taskRequest: TaskRequestBasic };
}

export const TASK_REQUEST_EVALUATIONS = 'TASK_REQUEST_EVALUATIONS';
export interface TaskRequestEvaluationsAction {
  type: typeof TASK_REQUEST_EVALUATIONS;
  payload: { evaluations: EvaluationBasic[] };
}

export type CoursesActionTypes =
  | OwnCoursesAction
  | CourseSelectedAction
  | CourseAction
  | OwnRolesAction
  | CourseTaskRequestsAction
  | CourseMembershipAction
  | CourseMembershipsAction
  | CourseMembershipDeletedAction
  | TaskRequestSelectedAction
  | TaskRequestAction
  | RoleSelectedAction
  | TaskRequestEvaluationsAction;

export const TASK_RESPONSES = 'TASKS_RESPONSES';
export interface TaskResponsesAction {
  type: typeof TASK_RESPONSES;
  payload: { taskResponses: TaskResponseBasic[] };
}

export const TASK_RESPONSE_SELECTED = 'TASK_RESPONSE_SELECTED';
export interface TaskResponseSelectedAction {
  type: typeof TASK_RESPONSE_SELECTED;
  payload: { id: number };
}

export const TASK_RESPONSE = 'TASK_RESPONSE';
export interface TaskResponseAction {
  type: typeof TASK_RESPONSE;
  payload: { task: TaskResponseBasic };
}
export const TASK_RESPONSE_TRANSCRIPT = 'TASK_RESPONSE_TRANSCRIPT';
export interface TaskResponseTranscriptAction {
  type: typeof TASK_RESPONSE_TRANSCRIPT;
  payload: { id: number; transcript: TranscriptResult };
}

export const TASK_RESPONSE_EVALUATIONS = 'TASK_RESPONSE_EVALUATIONS';
export interface TaskResponseEvaluationsAction {
  type: typeof TASK_RESPONSE_EVALUATIONS;
  payload: { evaluations: EvaluationBasic[] };
}

export const TASK_RESPONSE_EVALUATION = 'TASK_RESPONSE_EVALUATION';
export interface TaskResponseEvaluationAction {
  type: typeof TASK_RESPONSE_EVALUATION;
  payload: { evaluation: EvaluationBasic };
}

export const TASK_RESPONSE_EVALUATION_DELETED = 'TASK_RESPONSE_EVALUATION_DELETED';
export interface TaskResponseEvaluationDeletedAction {
  type: typeof TASK_RESPONSE_EVALUATION_DELETED;
  payload: { evaluation: EvaluationBasic };
}

export type TaskResponsesActionTypes =
  | TaskResponsesAction
  | TaskResponseSelectedAction
  | TaskResponseAction
  | TaskResponseTranscriptAction
  | TaskResponseEvaluationsAction
  | TaskResponseEvaluationAction
  | TaskResponseEvaluationDeletedAction;
