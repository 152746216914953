/* eslint-disable no-console */
import ReactEcharts from 'echarts-for-react';
import React from 'react';
import config, { getColor } from '../../config/constants';
import { GRADED, PENDING, STARTED, SUBMITTED } from '../../model/ApiTypes';
import { applyOpacity } from '../../utils/utils';
import { TaskSummary } from '../course/ReviewerTaskPanel';

export interface EChartOption {
  dataZoom?: any[];
  graphic?: any;
  toolbox?: any;
  brush?: any;
  legend?: any;
  tooltip?: any;
  dataset: any;
  xAxis?: any;
  yAxis?: any;
  grid?: any;
  series: any;
  title?: any;
  animation?: boolean;
}
export interface TaskStatusRatesInfo {
  statusRates: TaskSummary;
}

interface IProps extends TaskStatusRatesInfo {
  height: number;
}
interface IState {
  responsesCounter: number;
}

const NO_NEW_OPTIONS = {};

class TaskStatusChart extends React.PureComponent<IProps, IState> {
  echartsReact: ReactEcharts;
  graphContainer: any = null;

  baseGraphStructure: EChartOption = null;
  pieKeys: string[] = [];

  onEvents = {};

  constructor(props: IProps) {
    super(props);
    this.state = {
      responsesCounter: Object.values(this.props.statusRates).reduce((accumulator, count, idx) => accumulator + count, 0),
    };
    console.log(' ChartsForSpeaker constructor called, props ', props);
  }
  getChartComputedOptions = () => {
    if (this.baseGraphStructure && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      return this.echartsReact.getEchartsInstance().getOption();
    } else {
      return null;
    }
  };

  //chart's data should be available from the start and does not change
  getVarGraphStructure = () => {
    return NO_NEW_OPTIONS;
  };

  getValuesArray = (statuses: TaskSummary) => {
    console.log('getValuesArray ', statuses);
    let rez: number[] = [];
    Object.keys(statuses)
      .sort(this.chartSortFn)
      .forEach((key) => {
        const val = (statuses[key] / (this.state.responsesCounter ? this.state.responsesCounter : Number.MAX_VALUE)) * 100;
        rez.push(parseFloat(val.toFixed(2)));
      });
    return rez;
  };

  getCrossColor = () => (params) => this.getStatColor(params);
  COLOR_ROOT = 2;

  getStatColor = (params) => {
    // console.log(`crossColor for  params: percent datakey , pieKeys:   `, params.percent, params.dataIndex, this.pieKeys);
    const rez = params.percent !== undefined ? getColor(params.dataIndex + this.COLOR_ROOT) : 'white';
    // console.log(`crossColor rez   `, rez);

    return applyOpacity(rez, 1);
  };

  chartSortOrder = [PENDING, STARTED, SUBMITTED, GRADED];
  chartSortFn = (a, b) => this.chartSortOrder.indexOf(a) - this.chartSortOrder.indexOf(b);

  public getColorForStatus = (status: string) => getColor(this.chartSortOrder.indexOf(status) + this.COLOR_ROOT);
  //real data should be available from the start
  initBaseGraphStructure() {
    console.log('$$$$$$$$$$$initBaseChratsStructure TaskStatusChart called');
    const source = [];
    const series = [];
    const titles: any[] = []; //No title needed since we use tabs for speakers
    let legendSelected = {};
    let array1 = Object.keys(this.props.statusRates).sort(this.chartSortFn);
    source.push([...array1]);
    array1.forEach((item) => (legendSelected[item] = true));

    let analysesData: TaskSummary[] = [this.props.statusRates];
    let analysesInfo: string[] = ['status_pie'];

    analysesData.forEach((streamInfo, index) => {
      let valuesArray = this.getValuesArray(streamInfo);

      source.push(valuesArray);
      series.push({
        name: analysesInfo[index],
        id: analysesInfo[index],
        type: 'pie',
        animation: false,
        seriesLayoutBy: 'row',
        data: valuesArray,

        radius: '100%',
        center: [`${50}%`, '50%'],
        labelLine: {
          show: false,
          length: 5,
          length2: 5,
        },
        label: {
          position: 'center',
          show: false,
          alignTo: 'labelLine', //'edge',
          margin: 20,
          fontSize: 10, //undefined,
          formatter: (params) => {
            //console.log('format label for  params: ', params);
            return `${this.pieKeys[params.dataIndex]}: ${params.data}%`;
            // return [`${this.pieKeys[params.dataIndex]}`, ` ${params.data}%`].join('\n');
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 12,
            fontWeight: 'bold',
            color: 'black',
          },
        },
        itemStyle: {
          color: this.getCrossColor(),
        },
        tooltip: {},
      });
      legendSelected[analysesInfo[index]] = true;
    });

    //   formatter: [
    //     '{a|Style "a" is applied to this snippet}'
    //     '{b|Style "b" is applied to this snippet}This snippet use default style{x|use style "x"}'
    // ].join('\n'),

    // rich: {
    //     a: {
    //         color: 'red',
    //         lineHeight: 10
    //     },

    let rez: EChartOption = {
      legend: {},
      title: titles,
      dataset: {
        source,
        seriesLayoutBy: 'row',
      },
      series,
    };
    // if () { TODO FFS
    //   rez = {
    //     ...rez,
    //     tooltip: {
    //       trigger: 'axis',
    //       showContent: true,
    //       extraCssText: null,
    //     },
    //   };
    // }

    // console.log('set legend');

    // rez.legend = this.initLegend(legendSelected); TODO FFS - for some reason, legend is not displayed for pie-s

    // rez.toolbox = {
    //   top: 0,
    //   right: '15',
    //   z: 40,
    //   orient: 'horizontal',
    //   show: true,
    //   emphasis: {
    //     iconStyle: {
    //       // borderColor: this.state.highlightEditing ? config.highlights.highlightDraftBorder : config.graph.toolBoxSelectedColor,
    //       textPosition: 'bottom',
    //     },
    //   },
    // };

    console.log('$$$$$$$$$$$initBaseGraphStructure returned ', rez);
    this.baseGraphStructure = rez;
    this.pieKeys = rez.dataset.source[0];
    return this.baseGraphStructure;
  }

  componentDidMount() {}
  componentWillUnmount() {
    console.log('WILL UNmount', this.echartsReact);
  }

  componentDidUpdate(prevProps: TaskStatusRatesInfo, prevState: IState) {}

  render() {
    console.log('*****TaskStatusChart RENDER, rates*********** ', this.props.statusRates);
    if (!this.props.statusRates) {
      console.log('*****RENDER*********** TaskStatusChart intoarce div kior');
      return <div />;
    }
    const start = new Date().getTime();
    console.log('*****RENDER*********** Charts options: ', this.getChartComputedOptions());

    const rez = (
      <div className="row style={width: '100%', height: '100%'}">
        <div
          className={'col no-padding NUfig-video-details-left-side__chartContainer '}
          ref={(internalComp) => {
            this.graphContainer = internalComp;
          }}
        >
          <ReactEcharts
            ref={(e) => {
              this.echartsReact = e;
            }}
            option={
              this.baseGraphStructure
                ? /*
              this.getVarGraphStructure()
               FFS this optimization no longer applies, since statusRates property can chenge (responses can be reset)
               */
                  this.initBaseGraphStructure()
                : this.initBaseGraphStructure()
            }
            // style={{ height: this.props.height, width: '100%' }}
            style={{ height: '100%', width: '100%' }}
            onEvents={this.onEvents}
            theme={config.theme.name}
          />
        </div>
      </div>
    );
    console.log('*****RENDER*********** Charts took ', new Date().getTime() - start);
    return rez;
  }

  initLegend(legendSelected) {
    //todo FFS failed to diplay legend at all
    return {
      selected: legendSelected,
      show: true,

      // right: 30,
      // top: '50%',
      // orient: 'vertical',

      orient: 'vertical',
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
      width: '100%',
      height: '100%',

      z: 30,
      textStyle: {
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black',
      },
      // formatter: (name) => {
      //   //   // var series = myChart.getOption().series[0];
      //   //   // var value = series.data.filter(row => row.name === name)[0].value;
      //   //   return name + '    ' + 'XXX';
      //   console.log('legend formatter called ', name);
      //   return name;
      // },
      data: Object.keys(this.props.statusRates).sort(this.chartSortFn),
    };
  }
}
export default TaskStatusChart;
