import { combineReducers } from 'redux';
import authentication, { CampusAuthenticationState } from '../../demo/reducers/authReducer';
import courses, { CoursesState } from '../../demo/reducers/coursesReducer';
import rubrics, { RubricsState } from '../../demo/reducers/rubricsReducer';
import settings, { SettingsState } from '../../demo/reducers/settingsReducer';
import taskResponses, { TaskResponsesState } from '../../demo/reducers/tasksReducer';
import userProfiles, { CampusUsersProfileState } from '../../reducers/userProfileReducer';
import utils, { CampusUtilsState } from '../../reducers/utilsReducer';

export interface IRootState {
  readonly authentication: CampusAuthenticationState;
  readonly utils: CampusUtilsState;
  readonly userProfiles: CampusUsersProfileState;
  readonly settings: SettingsState;
  readonly rubrics: RubricsState;
  readonly taskResponses: TaskResponsesState;
  readonly courses: CoursesState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  utils,
  userProfiles,
  settings,
  taskResponses,
  courses,
  rubrics,
});

export default rootReducer;
