export const DURATION_UNKNOWN = 0;
export const TIME_INITIAL = 0;

export const generateFakeId = () => new Date().getTime().toString();

export const hexToRgb = (h: string) => {
  // eslint-disable-next-line no-bitwise
  return [parseInt('0x' + h[1] + h[2], 16) | 0, parseInt('0x' + h[3] + h[4], 16) | 0, parseInt('0x' + h[5] + h[6], 16) | 0];
};
export const rgbToHex = (r: number, g: number, b: number) => {
  // eslint-disable-next-line no-bitwise
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
export const applyOpacity = (rgbcolor: string, opacity: number) => {
  const [r, g, b] = hexToRgb(rgbcolor);
  return `rgba(${r},${g},${b}, ${opacity})`;
};

export const seconds2ss = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime - minutes * 60);
  let secondValue;
  if (seconds < 10) {
    secondValue = '0' + seconds;
  } else {
    secondValue = seconds;
  }
  return secondValue;
};

export const seconds2mss = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime - minutes * 60);
  let minuteValue;
  let secondValue;
  if (minutes < 10) {
    // minuteValue = '0' + minutes;
    minuteValue = minutes;
  } else {
    minuteValue = minutes;
  }
  if (seconds < 10) {
    secondValue = '0' + seconds;
  } else {
    secondValue = seconds;
  }
  return minuteValue + ':' + secondValue;
};

export const seconds2mssmm = (currentTime: number) => {
  const minutes = Math.floor(currentTime / 60);
  const seconds = Math.floor(currentTime - minutes * 60);
  let minuteValue;
  let secondValue;
  if (minutes < 10) {
    // minuteValue = '0' + minutes;
    minuteValue = minutes;
  } else {
    minuteValue = minutes;
  }
  if (seconds < 10) {
    secondValue = '0' + seconds;
  } else {
    secondValue = seconds;
  }
  const millis = currentTime - minutes * 60 - seconds;
  // return minuteValue + ':' + secondValue + `${millis > 0 ? '.' + Math.floor(millis * 100) : ''}`;
  return minuteValue + ':' + secondValue + `${'.' + Math.floor(millis * 100)}`;
};

/**
 * convert to format required by <input  type="datetime-local"/>*/
export const toDateTimeLocalInput = (value: Date) => {
  return `${value.getFullYear()}-${(1 + value.getMonth()).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}-${value.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}T${value
    .getHours()
    .toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}:${value
    .getMinutes()
    .toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`;
  // //:${value
  // .getSeconds()
  // .toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}
};
export const toDateLocalInput = (value: Date) => {
  return `${value.getFullYear()}-${(1 + value.getMonth()).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}-${value.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`;
};
export const toTimeLocalInput = (value: Date) => {
  return `${value.getHours().toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}:${value
    .getMinutes()
    .toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`;
};

const optionsDate = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as Intl.DateTimeFormatOptions;
const optionsTime = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
} as Intl.DateTimeFormatOptions;

export const formatCampusLongDate = (date) =>
  `${new Date(date).toLocaleDateString(undefined, optionsDate)} @ ${new Date(date).toLocaleTimeString(undefined, optionsTime)}`;

const optionsLocale = { minimumIntegerDigits: 2, useGrouping: false };
export const toDateTimeSSLocalInput = (value: Date) => {
  return `${value.getFullYear()}_${(1 + value.getMonth()).toLocaleString(undefined, optionsLocale)}_${value
    .getDate()
    .toLocaleString(undefined, optionsLocale)}_${value.toLocaleTimeString(undefined, optionsTime)}`;
};
