import { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { ConnectedProps, connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-default.css';
import ModalAlert from './ModalAlert';

import { windowHeightUpdated } from './app/actions/utils';
import AddCourseFrame from './app/components/common/AddCourseFrame';
import Clients from './app/components/common/Clients';
import CourseFrame from './app/components/common/CourseFrame';
import DashboardFrame from './app/components/common/DashboardFrame';
import Profile from './app/components/common/Profile';
import Settings from './app/components/common/Settings';
import TaskFrame from './app/components/common/TaskFrame';
import LoginContainer from './app/components/common/loginContainer/LoginContainer';
import PrivateRoute from './app/components/common/privateRoute/PrivateRoute';
import ForgotPasswordPanel from './app/demo/components/user/ForgotPasswordPanel';
import RegisterEmailConfirmationPanel from './app/demo/components/user/RegisterEmailConfirmationPanel';
import RegisterUserPanel from './app/demo/components/user/RegisterUserPanel';
import RequestRegistrationConfirmationPanel from './app/demo/components/user/RequestRegistrationConfirmationPanel';
import ResetPasswordPanel from './app/demo/components/user/ResetPasswordPanel';
import { API_ROLE_COACH, API_ROLE_USER } from './app/demo/model/ApiTypes';

const cond = document && document.querySelector('base') ? document.querySelector('base') : null;

let abaseHref = cond && cond.getAttribute('href') ? cond.getAttribute('href') : '';

let baseHref = abaseHref ? abaseHref.replace(/\/$/, '') : '';

// export interface IAppProps extends StateProps, DispatchProps {}
export interface IAppProps extends PropsFromRedux {}
export const getUnauthedHomePath = () => '/login';
export const getAuthedHomePath = () => '/dashboard';
export const resultsPathToken = () => 'result';
export const instructorPathToken = () => 'instructor';
export const evalPathToken = () => 'eval';
export const overviewPathToken = () => 'taskview';
export const App = (props: IAppProps) => {
  useEffect(() => {}, []);
  const allAccessRole = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_COACH', API_ROLE_USER, API_ROLE_COACH];

  const handleGlobalWindowResize = () => {
    props.windowHeightUpdated(window.innerHeight);
  };

  console.log('App() invoked, props: ', props);
  window.addEventListener('resize', handleGlobalWindowResize);
  handleGlobalWindowResize();
  console.log('resizeHandler installed ');

  return (
    <div>
      <HashRouter basename={baseHref} hashType={'slash'}>
        <div>
          <Switch>
            <Route path="/register" exact component={RegisterUserPanel} />
            <Route path="/emailconfirmation" exact component={RegisterEmailConfirmationPanel} />
            <Route path="/passwordreset" exact component={ResetPasswordPanel} />
            <Route path="/changepassword" exact component={ResetPasswordPanel} />
            <Route path="/forgotpassword" exact component={ForgotPasswordPanel} />
            <Route path="/requestconfirmation" exact component={RequestRegistrationConfirmationPanel} />
            <PrivateRoute path="/profile" component={Profile} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <PrivateRoute
              path="/dashboard"
              exact
              component={DashboardFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/courses/:id"
              exact
              component={CourseFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            {/* not good enough, if a student pastes this url he will see course information as if reviewer ???*/}
            {/* <PrivateRoute
              path="/instructorcourses/:id"
              exact
              component={CourseFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            /> */}
            <PrivateRoute
              path="/addcourse"
              exact
              component={AddCourseFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/tasks/:id"
              exact
              component={TaskFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/taskresults/:id"
              exact
              component={TaskFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/taskeval/:id"
              exact
              component={TaskFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute
              path="/taskview/:id"
              exact
              component={TaskFrame}
              authed={true}
              userRole={props.userRole}
              accessRole={allAccessRole}
            />
            <PrivateRoute path="/settings" exact component={Settings} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <PrivateRoute path="/clients" exact component={Clients} authed={true} userRole={props.userRole} accessRole={allAccessRole} />

            <PrivateRoute exact path="/" component={DashboardFrame} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
            <Route exact path={getUnauthedHomePath()} component={LoginContainer} />
            <PrivateRoute path="/" component={DashboardFrame} authed={true} userRole={props.userRole} accessRole={allAccessRole} />
          </Switch>
        </div>
      </HashRouter>
      <Alert stack={{ limit: 10 }} html={true} />
      <ModalAlert />
    </div>
  );
};

//

const mapStateToProps = ({ /*locale,*/ authentication }: any) => ({
  // currentLocale: locale.currentLocale,

  // isAuthenticated: authentication.isAuthenticated,
  // isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  // isInProduction: applicationProfile.inProduction,
  userRole: authentication.user && authentication.user.role ? authentication.user.role.name : null,
});

const mapDispatchToProps = {
  //setLocale
  // clearGlobalAlert,
  windowHeightUpdated,
};

// type StateProps = ReturnType<typeof mapStateToProps>;
// type DispatchProps = typeof mapDispatchToProps;

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

//export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
export default connector(hot(module)(App));
