import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import ErrorIcon from '@material-ui/icons/Error';
import { clearGlobalAlert } from './app/actions/utils';
import { IRootState } from './app/shared/reducers';

export const ModalAlert = () => {
  const globalAlert = useSelector((state: IRootState) => state.utils.alertTitle);
  const dispatch = useDispatch();
  const clearAlert = () => dispatch(clearGlobalAlert());

  return (
    <Dialog
      className="modal-confirmation-dialog"
      open={globalAlert !== null}
      onClose={clearAlert}
      fullWidth
      maxWidth="xs"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // BackdropProps={{ invisible: true }}
      //BackdropProps={{ style: { /*backgroundColor: "transparent",*/ left: '5%', top: '5%', width: '90%', height: '90%' } }}
    >
      <div className="row fig-horizontal-center">
        <DialogActions>
          <ErrorIcon fontSize="large" className="attention-icon" />
        </DialogActions>
      </div>
      <DialogTitle className="modal-confirmation-dialog__title">Can't perform this action</DialogTitle>
      <DialogContent className="modal-confirmation-dialog__content">
        <DialogContentText style={{ whiteSpace: 'pre' }} id="alert-dialog-description">
          {globalAlert}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="fig-horizontal-center modal-confirmation-dialog__buttons">
        <Button className="fig-button in-modal-dialog" onClick={clearAlert} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAlert;
