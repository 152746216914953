import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { formatCampusLongDate } from '../../utils/utils';

export default function TaskRequestsTable(props) {
  const { rows } = props;
  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={false}
                    tabIndex={-1}
                    key={row.id}
                    selected={false}
                    // onClick={() => props.handleOverviewTask(row.id)}
                    // style={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.course}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">
                      <b>Attempts</b>: {row.attempts}
                    </TableCell>
                    <TableCell align="left">
                      <b>Video</b>: {row.attemptTime} seconds
                    </TableCell>
                    <TableCell align="left">
                      <b>Time</b>: {row.totalTime} minutes
                    </TableCell>

                    <TableCell align="center" className="no-padding">
                      <div>
                        <b>starts:</b> {formatCampusLongDate(new Date(row.starts_at))}
                      </div>
                      <div>
                        <b>ends:</b> {formatCampusLongDate(new Date(row.ends_at))}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title={`View Task Details`}>
                      <button
                            className="fig-button-link "
                          // variant="text"
                          onClick={() => props.handleOverviewTask(row.id)}
                        >
                          View Task
                        </button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
TaskRequestsTable.propTypes = {
  rows: PropTypes.array.isRequired,
  handleOverviewTask: PropTypes.func.isRequired,
};
