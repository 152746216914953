import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { addCourse } from '../../demo/actions/campusApiActions';
import { Course } from '../../demo/model/ApiTypes';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';
interface IProps extends PropsFromRedux {
  history: any;
}

interface IState {
  courseYear: string;
  courseCode: string;
  courseName: string;
}
class AddCourseFrame extends React.PureComponent<IProps, IState> {
  onYearChange = (event) => {
    this.setState({ courseYear: event.target.value });
  };
  onCodeChange = (event) => {
    this.setState({ courseCode: event.target.value });
  };
  onNameChange = (event) => {
    this.setState({ courseName: event.target.value });
  };

  addCourse = () => {
    this.props
      .addCourse(
        { code: this.state.courseCode, name: this.state.courseName, semester: this.state.courseYear },
        this.props.authentication.token
      )
      .then(
        // (course: Course) => this.props.history.push(`instructorcourses/${course.data.id}`),
        (course: Course) => this.props.history.push(`courses/${course.data.id}`),
        (error) => console.log('Add course error', error)
      );
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    return {
      courseYear: '',
      courseCode: '',
      courseName: '',
    };
  };

  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="course" history={this.props.history} />
        <div className={className}>{this.renderForm()}</div>
      </div>
    );
  }

  renderForm() {
    let usefullHeight = window.innerHeight - 250;
    console.log('TaskEval render, usefull height ', usefullHeight /*this.props*/);
    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Add Course</span>
          </div>
          <div className="row w-100">
            <div className="col-12  no-padding">
              <div className="fig-generic-pane-right-side" style={{ height: usefullHeight + 110, overflowY: 'auto' }}>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400, maxHeight: usefullHeight + 110 }}>
                  <div
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      fontWeight: 700,
                      width: '100%',
                    }}
                  >
                    <div className="row w-100">
                      <div className="col-2 no-padding">
                        <span className="w-100">Course Year / Semester</span>
                        <TextField
                          className="fig-modal-dialog-inputG  w-100"
                          style={{ backgroundColor: 'rgba(245, 246, 248, 0.6)' }}
                          InputLabelProps={{
                            shrink: true,
                            required: false,
                          }}
                          value={this.state.courseYear}
                          // onChange={(event) => {
                          //   const newValue = Number.parseFloat(event.target.value);
                          //   if(newValue >=0 && newValue<=rubricScore){
                          //     const newRubric = [...this.state.evaluation.rubric];
                          //     newRubric[idxQuestion] = newValue
                          //     this.setState({ evaluation: { ...this.state.evaluation, rubric: newRubric } });
                          //   }
                          // }}
                          onChange={this.onYearChange}
                          required
                        />
                      </div>
                      <div className="col-2 ">
                        <span className="w-100">Course Code</span>
                        <TextField
                          className="fig-modal-dialog-inputG w-100"
                          style={{ backgroundColor: 'rgba(245, 246, 248, 0.6)' }}
                          InputLabelProps={{
                            shrink: true,
                            required: false,
                          }}
                          value={this.state.courseCode}
                          onChange={this.onCodeChange}
                          required
                        />
                      </div>
                      <div className="col-8 ">
                        <span className="w-100">Course Name</span>
                        <TextField
                          className="fig-modal-dialog-inputG  w-100"
                          style={{ backgroundColor: 'rgba(245, 246, 248, 0.6)' }}
                          InputLabelProps={{
                            shrink: true,
                            required: false,
                          }}
                          value={this.state.courseName}
                          required
                          onChange={this.onNameChange}
                        />
                      </div>
                    </div>
                    <div className="row " style={{ display: 'flex', justifyContent: 'center', paddingTop: 100 }}>
                      <Button
                        className="fig-button fig-button__grey margin-left--10"
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button className="fig-button margin-left--10 fig-button__dark" onClick={this.addCourse}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  console.log('AddCourseFrame mapStateToProps ', state);
  return {
    authentication: state.authentication,
  };
};
const mapDispatchToProps = {
  addCourse,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AddCourseFrame);
