import { Button, IconButton, TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import {
  Member,
  QuestionBasic,
  RubricBasic,
  RubricQuestion,
  TaskRequestBasic,
  TaskRequestBasicWithQuestionBankAndRubric,
} from '../../model/ApiTypes';
import { formatCampusLongDate, toDateLocalInput, toTimeLocalInput } from '../../utils/utils';
import QuestionBankDialog from './QuestionsBankDialog';
import RubricDialog from './RubricDialog';

interface IProps {
  currentTaskRequest?: TaskRequestBasic; //not null for editing task
  addTask?: (taskRequest: TaskRequestBasicWithQuestionBankAndRubric) => void; //not null for adding new Tasks
  saveTask?: (taskRequest: TaskRequestBasicWithQuestionBankAndRubric) => void; //not null for editing task
  questions?: QuestionBasic[]; //not null for editing task
  rubric?: RubricBasic; //not null for editing task

  windowHeight: number; //to refresh height
  setGlobalAlert: (string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  taskRequest: TaskRequestBasicWithQuestionBankAndRubric;
  trStartTime: string; //FFS use this at save time
  trEndTime: string; //FFS use this at save time

  editQuestionsDialogOpened: boolean;
  editRubricDialogOpened: boolean;
}

class TaskForm extends React.PureComponent<IProps, IState> {
  initialStartTime: Date = null;
  constructor(props: IProps) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    const now = new Date();
    const tsTaskCreated = formatCampusLongDate(now);

    const whenToStart = new Date(now.getTime() + 24 * 60 * 60 * 1000); //tomorrow at same time
    whenToStart.setHours(9, 0, 0, 0);

    const whenToStop = new Date(whenToStart.getTime() + 24 * 60 * 60 * 1000); //day after tomorrow
    whenToStop.setHours(23, 59, 59, 0);

    this.initialStartTime = this.props.currentTaskRequest ? new Date(this.props.currentTaskRequest.starttime) : null;
    const taskRequest = this.props.currentTaskRequest
      ? {
          ...this.props.currentTaskRequest,
          questionBank: this.props.questions ? this.props.questions.map((q) => q.text) : [],
          rubric: this.props.rubric.questions,
          rubricID: this.props.rubric.id,
        }
      : {
          id: null,
          name: `My Task  created at ${tsTaskCreated}`,
          desc: `My Task created at ${tsTaskCreated} NO DESCRIPTION`,
          type: 'video',

          starttime: whenToStart.toISOString(),
          endtime: whenToStop.toISOString(),

          options: {
            attempts: 5,
            attempttime: 90,
            totaltime: 15,
          },
          course: null,
          questionBank: ['Sample Question 1', 'Sample Question 2'],
          rubric: [
            {
              text: 'Sample Rubric 1',
              score: 100,
              weight: 60,
            },
            {
              text: 'Sample  Rubric 2',
              score: 100,
              weight: 40,
            },
          ],
          rubricID: null,
          data: null,
          createdAt: null,
          updatedAt: null,
        };

    return {
      editQuestionsDialogOpened: false,
      editRubricDialogOpened: false,

      trStartTime: toTimeLocalInput(this.props.currentTaskRequest ? new Date(this.props.currentTaskRequest.starttime) : whenToStart),
      trEndTime: toTimeLocalInput(this.props.currentTaskRequest ? new Date(this.props.currentTaskRequest.endtime) : whenToStop),

      taskRequest,
    };
  };

  static NO_MEMBERS_YET = [] as Member[];

  render() {
    let usefullHeight = window.innerHeight - 125;
    console.log('TaskForm render, usefull height ', usefullHeight, this.props);

    return (
      <div className="fig-generic-pane-right-side">
        <div className="row w-100" key="rowheader1.1">
          <div className="col-9 no-padding" key="colheader1.1">
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header1.1">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>Task Name</span>
                </div>
              </div>
            </div>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header1.2">
              <div className="row w-100">
                <div className="textarea-boxGray w-100 no-padding">
                  <TextField
                    className="w-100"
                    value={this.state.taskRequest.name}
                    variant="outlined"
                    error={!this.state.taskRequest.name || !this.state.taskRequest.name.length}
                    onChange={(event) => this.setState({ taskRequest: { ...this.state.taskRequest, name: event.target.value } })}
                    placeholder="Enter Task Name"
                    disabled={this.taskStarted()}
                  />
                </div>
              </div>
            </div>
            <div className="row w-100" key="header1.3">
              <div className="col-4 no-padding">
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.1">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>Start Date</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.2">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        value={toDateLocalInput(new Date(this.state.taskRequest.starttime))}
                        variant="outlined"
                        type="date"
                        error={
                          !this.state.taskRequest.starttime ||
                          !this.state.taskRequest.starttime.length ||
                          this.state.taskRequest.starttime === 'NaN-NaN-NaN'
                        }
                        onChange={(event) => {
                          let nDate = new Date(event.target.value + 'T00:00:00'); //meaning local timezone
                          this.setState({ taskRequest: { ...this.state.taskRequest, starttime: nDate.toISOString() } });
                        }}
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.3">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>Attempts</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.4">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        type="number"
                        value={this.state.taskRequest.options.attempts}
                        variant="outlined"
                        placeholder="Enter Attempts"
                        onChange={(event) =>
                          this.setState({
                            taskRequest: {
                              ...this.state.taskRequest,
                              options: { ...this.state.taskRequest.options, attempts: Number.parseInt(event.target.value) },
                            },
                          })
                        }
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.1">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>Start Time</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.2">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        value={this.state.trStartTime}
                        variant="outlined"
                        type="time"
                        onChange={(event) => {
                          this.setState({ trStartTime: event.target.value });
                        }}
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.3">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>Recording Time (seconds)</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.4">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        value={this.state.taskRequest.options.attempttime}
                        variant="outlined"
                        type="number"
                        placeholder="Enter Recording Time"
                        onChange={(event) =>
                          this.setState({
                            taskRequest: {
                              ...this.state.taskRequest,
                              options: { ...this.state.taskRequest.options, attempttime: Number.parseInt(event.target.value) },
                            },
                          })
                        }
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 no-padding">
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.1">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>End Date</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.2">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        value={toDateLocalInput(new Date(this.state.taskRequest.endtime))}
                        variant="outlined"
                        type="date"
                        error={
                          !this.state.taskRequest.endtime ||
                          !this.state.taskRequest.endtime.length ||
                          this.state.taskRequest.endtime === 'NaN-NaN-NaN'
                        }
                        onChange={(event) => {
                          let nDate = new Date(event.target.value + 'T00:00:00'); //meaning local timezone);
                          this.setState({ taskRequest: { ...this.state.taskRequest, endtime: nDate.toISOString() } });
                        }}
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.3">
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div
                      className="row"
                      style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
                    >
                      <span>Total Time (minutes)</span>
                    </div>
                  </div>
                </div>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.4">
                  <div className="row w-100">
                    <div className="textarea-boxGray w-100 no-padding">
                      <TextField
                        className="w-100"
                        value={this.state.taskRequest.options.totaltime}
                        variant="outlined"
                        type="number"
                        placeholder="Enter Total Time"
                        onChange={(event) =>
                          this.setState({
                            taskRequest: {
                              ...this.state.taskRequest,
                              options: { ...this.state.taskRequest.options, totaltime: Number.parseInt(event.target.value) },
                            },
                          })
                        }
                        disabled={this.taskStarted()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3" key="colheader1.2">
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2.1">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>Task Type</span>
                </div>
              </div>
            </div>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2.2">
              <div className="row w-100">
                <div className="textarea-boxGray w-100 no-padding">
                  <TextField
                    className="w-100"
                    value={this.state.taskRequest.type}
                    variant="outlined"
                    disabled
                    onChange={(event) =>
                      this.setState({
                        taskRequest: {
                          ...this.state.taskRequest,
                          type: event.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2.3">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>End Time</span>
                </div>
              </div>
            </div>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2.4">
              <div className="row w-100">
                <div className="textarea-boxGray w-100 no-padding">
                  <TextField
                    className="w-100"
                    value={this.state.trEndTime}
                    variant="outlined"
                    type="time"
                    onChange={(event) => {
                      this.setState({ trEndTime: event.target.value });
                    }}
                    disabled={this.taskStarted()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row w-100" key="rowheader1.2">
          <div className="col-12 fig-generic-pane-right-side__title no-padding" style={{ fontWeight: 400 }} key="header5.1">
            <div style={{ display: 'inline-block', justifyContent: 'space-between', marginTop: 10, marginBottom: 8, fontWeight: 700 }}>
              <div
                className="row"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}
              >
                <span className="padding-right--30">
                  Question Bank &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Tooltip title="Edit Questions">
                    <IconButton
                      onClick={this.openEditQuestionsDialog}
                      className="fig-button fig-delete-rubric "
                      style={{ borderWidth: 0 }}
                      disabled={this.taskStarted()}
                    >
                      <svg className="fig-edit-icon">
                        <use href={sprites + '#edit-icon'} />
                      </svg>
                    </IconButton>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          {this.state.taskRequest.questionBank.map((question, idx) => this.renderQuestion(question, idx))}
        </div>
        <div className="row w-100" key="rowheader1.3">
          <div className="col-7 no-padding">
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.1">
              <div style={{ display: 'block', justifyContent: 'space-between', marginTop: 10, marginBottom: 8, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>
                    Rubric &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Tooltip title="Edit Rubric">
                      <IconButton
                        onClick={this.openEditRubricsDialog}
                        className="fig-button fig-delete-rubric padding-left--20"
                        disabled={this.taskStarted()}
                      >
                        <svg className="fig-edit-icon">
                          <use href={sprites + '#edit-icon'} />
                        </svg>
                      </IconButton>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.1">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>Grade</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header6.1">
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between' /*, minWidth: '30%', maxWidth: '50%'*/ }}>
                  <span>Weight</span>
                </div>
              </div>
            </div>
          </div>
          {this.state.taskRequest.rubric.map((question, idx) => this.renderRubric(question, idx))}
        </div>
        <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="headerbtn">
          <div className="row fig-horizontal-center  padding-top--40 padding-bottom--40">
            <Button
              className="fig-button margin-left--10  fig-button__dark"
              // disabled={this.shouldDenyChangingTask()}
              // disabled={this.taskStarted()}
              onClick={() => {
                if (!this.shouldDenyChangingTask()) {
                  const start = this.makeCombinedDate(toDateLocalInput(new Date(this.state.taskRequest.starttime)), this.state.trStartTime);
                  const end = this.makeCombinedDate(toDateLocalInput(new Date(this.state.taskRequest.endtime)), this.state.trEndTime);
                  const taskR = { ...this.state.taskRequest, starttime: start.toISOString(), endtime: end.toISOString() };
                  this.props.currentTaskRequest ? this.props.saveTask(taskR) : this.props.addTask(taskR);
                } else if (this.taskStarted()) {
                  this.props.setGlobalAlert('Cannot alter already started task.');
                } else if (this.taskDatesInThePast()) {
                  this.props.setGlobalAlert('Task start/end moment cannot be in the past.');
                }
              }}
            >
              Save Task
            </Button>
          </div>
        </div>

        {this.renderEditQuestionsDialog()}
        {this.renderEditRubricsDialog()}
      </div>
    );
  }

  //do not allow changing a started task, or setting start/end time in the past
  shouldDenyChangingTask = () => this.taskStarted() || this.taskDatesInThePast();

  taskStarted = () => this.initialStartTime && this.initialStartTime <= new Date();

  taskDatesInThePast = () =>
    this.makeCombinedDate(toDateLocalInput(new Date(this.state.taskRequest.endtime)), this.state.trEndTime) <= new Date() ||
    this.makeCombinedDate(toDateLocalInput(new Date(this.state.taskRequest.starttime)), this.state.trStartTime) <= new Date();

  makeCombinedDate(inputDate: string, inputTime: string) {
    //toDateLocalInput(),

    // Combine the date and time strings
    const combinedString = inputDate + 'T' + inputTime + ':00';
    // Create a new Date object from the combined string
    const res = new Date(combinedString);
    console.log(res);
    return res;
  }

  renderEditQuestionsDialog = () => {
    return this.state.editQuestionsDialogOpened ? (
      <QuestionBankDialog
        questions={[...this.state.taskRequest.questionBank]}
        onClose={this.closeEditQuestionsDialog}
        onSave={this.onSaveQuestions}
      ></QuestionBankDialog>
    ) : null;
  };
  openEditQuestionsDialog = (event) => {
    if (!this.taskStarted()) {
      this.setState({ editQuestionsDialogOpened: true });
    } else {
      this.props.setGlobalAlert('Cannot alter already started task.');
    }
  };
  closeEditQuestionsDialog = () => {
    this.setState({ editQuestionsDialogOpened: false });
  };
  onSaveQuestions = (questions: string[]) => {
    console.log('should save questions: ', questions);
    this.setState({ taskRequest: { ...this.state.taskRequest, questionBank: questions } });
    this.closeEditQuestionsDialog();
  };

  renderEditRubricsDialog = () => {
    return this.state.editRubricDialogOpened ? (
      <RubricDialog
        questions={[...this.state.taskRequest.rubric]}
        onClose={this.closeEditRubricsDialog}
        onSave={this.onSaveRubric}
      ></RubricDialog>
    ) : null;
  };
  openEditRubricsDialog = (event) => {
    if (!this.taskStarted()) {
      this.setState({ editRubricDialogOpened: true });
    } else {
      this.props.setGlobalAlert('Cannot alter already started task.');
    }
  };
  closeEditRubricsDialog = () => {
    this.setState({ editRubricDialogOpened: false });
  };
  onSaveRubric = (questions: RubricQuestion[]) => {
    console.log('should save rubric: ', questions);
    this.setState({ taskRequest: { ...this.state.taskRequest, rubric: questions } });
    this.closeEditRubricsDialog();
  };
  renderRubric = (question: RubricQuestion, idxQuestion: number) => {
    return (
      <>
        <div className="col-7 no-padding fig-video-details-matrix-main-container" key={'q.' + idxQuestion}>
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.2">
            <div className="row w-100">
              <div className="textarea-boxGray w-100 no-padding  fig-question-row">
                <TextField
                  className="w-100"
                  value={question.text}
                  variant="outlined"
                  placeholder="Enter Rubric"
                  onChange={(event) => {
                    const newr = [...this.state.taskRequest.rubric];
                    newr[idxQuestion] = { ...this.state.taskRequest.rubric[idxQuestion], text: event.target.value };
                    this.setState({ taskRequest: { ...this.state.taskRequest, rubric: newr } });
                  }}
                  disabled={this.taskStarted()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 fig-video-details-matrix-main-container" key={'s.' + idxQuestion}>
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header3.2">
            <div className="row w-100">
              <div className="textarea-boxGray w-100 no-padding  fig-question-row">
                <TextField
                  className="w-100"
                  value={question.score}
                  variant="outlined"
                  type="number"
                  placeholder="Enter Grade"
                  onChange={(event) => {
                    const newr = [...this.state.taskRequest.rubric];
                    newr[idxQuestion] = { ...this.state.taskRequest.rubric[idxQuestion], score: Number.parseInt(event.target.value) };
                    this.setState({ taskRequest: { ...this.state.taskRequest, rubric: newr } });
                  }}
                  disabled={this.taskStarted()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-2 fig-video-details-matrix-main-container" key={'w.' + idxQuestion}>
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header6.2">
            <div className="row w-100">
              <div className="textarea-boxGray w-100 no-padding  fig-question-row">
                <TextField
                  className="w-100"
                  value={question.weight}
                  variant="outlined"
                  type="number"
                  placeholder="Enter Weight"
                  onChange={(event) => {
                    const newr = [...this.state.taskRequest.rubric];
                    newr[idxQuestion] = { ...this.state.taskRequest.rubric[idxQuestion], weight: Number.parseInt(event.target.value) };
                    this.setState({ taskRequest: { ...this.state.taskRequest, rubric: newr } });
                  }}
                  disabled={this.taskStarted()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 fig-video-details-matrix-main-container" key={`q_${idxQuestion}_del`}>
          <IconButton
            disabled={this.taskStarted()}
            aria-label="delete"
            className="fig-button fig-delete-rubric"
            size="small"
            onClick={this.locallyDeleteRubricQuestion(idxQuestion)}
          >
            <svg className="fig-icon-delete-rubric-row">
              <use href={sprites + '#delete-icon'} />
            </svg>
          </IconButton>
        </div>
      </>
    );
  };

  renderQuestion = (question: string, idxQuestion: number) => {
    return (
      <>
        <div className="col-11 no-padding fig-video-details-matrix-main-container" key={'q.' + idxQuestion}>
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header5.2">
            <div className="row w-100">
              <div className="textarea-boxGray w-100 no-padding fig-question-row">
                <TextField
                  className="w-100"
                  value={question}
                  variant="outlined"
                  placeholder="Enter Question"
                  onChange={(event) => {
                    const newr = [...this.state.taskRequest.questionBank];
                    newr[idxQuestion] = event.target.value;
                    this.setState({ taskRequest: { ...this.state.taskRequest, questionBank: newr } });
                  }}
                  disabled={this.taskStarted()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-1 fig-video-details-matrix-main-container" key={`q_${idxQuestion}_del`}>
          <IconButton
            disabled={this.taskStarted()}
            aria-label="delete"
            className="fig-button fig-delete-rubric "
            size="small"
            onClick={this.locallyDeleteBankQuestion(idxQuestion)}
          >
            <svg className="fig-icon-delete-rubric-row">
              <use href={sprites + '#delete-icon'} />
            </svg>
          </IconButton>
        </div>
      </>
    );
  };
  locallyDeleteRubricQuestion = (idxQ: number) => (event) => {
    if (!this.taskStarted()) {
      const newr = [...this.state.taskRequest.rubric];
      newr.splice(idxQ, 1);
      this.setState({ taskRequest: { ...this.state.taskRequest, rubric: newr } });
    } else {
      this.props.setGlobalAlert('Cannot alter already started task.');
    }
  };
  locallyDeleteBankQuestion = (idxQ: number) => (event) => {
    if (!this.taskStarted()) {
      const newr = [...this.state.taskRequest.questionBank];
      newr.splice(idxQ, 1);
      this.setState({ taskRequest: { ...this.state.taskRequest, questionBank: newr } });
    } else {
      this.props.setGlobalAlert('Cannot alter already started task.');
    }
  };
}
export default TaskForm;
