import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@material-ui/core';
import React, { FormEvent } from 'react';
import BoldCloseButton from '../shared/BoldCloseButton';

import WarningIcon from '@material-ui/icons/WarningRounded';
import { RubricQuestion } from '../../model/ApiTypes';

interface IProps {
  onClose: () => void;
  questions: RubricQuestion[];
  onSave: (questions: RubricQuestion[]) => void;
}

interface IState {
  lines: string;
}

class RubricDialog extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { lines: '' };
  }

  componentDidMount = () => {
    const lines = this.props.questions.reduce(
      (accumulator, rubricQuestion, idx) =>
        accumulator + (idx > 0 ? '\n#\n' : '') + `Grade ${rubricQuestion.score}\nWeight ${rubricQuestion.weight}\n${rubricQuestion.text}`,
      ''
    );
    this.setState({ lines: lines });
  };

  toQuestions = (lines: string) => {
    // const rubrics = lines.split('\n#\n').map((line) => ((line.endsWith('\n#\n') ? line.substring(0, line.length - '\n#\n'.length) : line)));
    const rubrics = lines.split('\n#\n');
    return rubrics.map((rubric) => {
      const entry = rubric.split('\n');
      const grade = entry[0].substring('Grade '.length);
      const weight = entry[1].substring('Weight '.length);
      const text = entry[2];
      return { score: Number.parseInt(grade), weight: Number.parseInt(weight), text } as RubricQuestion;
    });
  };
  render() {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.onClose} scroll="paper" className="fig-modal-dialog-main-container-v2">
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.onClose}></BoldCloseButton>

        <DialogTitle id="scroll-dialog-title" disableTypography>
          <div className="row padding-top--35">
            <div className="col-12 no-padding fig-vertical-middle">
              <span className="modal-title">Rubric</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div className="col-12 no-padding fig-generic-pane-right-side">{this.renderQuestionsList()}</div>
        </DialogContent>

        <div className="row modal-dialog-footer-two-buttons " style={{ width: '100%', paddingBottom: 20, marginLeft: 0 }}>
          <div className="col-12 no-padding fig-space-between ">
            <div
              className="padding-left--20 padding-top--20"
              style={{
                fontSize: '0.75rem',
                textAlign: 'start',
                display: 'flex',
                // alignContent: 'center',
              }}
            >
              <span className="col-2 ">
                <IconButton /*className="delete-search"*/ aria-label="clear">
                  <WarningIcon />
                </IconButton>
              </span>
              <span className="col-10">
                <i> Each Rubric Question must be separated by a single line with only the “#” symbol.</i>
              </span>
            </div>

            <div className="modal-button-box padding-left--40">
              <Button className="fig-button in-modal-dialog fig-button__grey" onClick={this.props.onClose}>
                Cancel
              </Button>
            </div>

            <div className="modal-button-box padding-right--40 padding-left--10">
              <Button
                className={`fig-button in-modal-dialog ${true /*!this.hasErrors()*/ ? 'fig-button__dark' : 'fig-button__white'}`}
                onClick={() => {
                  this.props.onSave(this.toQuestions(this.state.lines));
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  renderQuestionsList() {
    return (
      <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header21">
        <div className="row w-100">
          <div className="textarea-boxGray w-100 no-padding margin-top--10-negative">
            <TextField
              className="w-100"
              multiline={true}
              minRows={10}
              value={this.state.lines}
              variant="outlined"
              onChange={(event) => this.setState({ lines: event.target.value })}
            />
          </div>
        </div>
      </div>
    );
  }

  private resetState(): void {
    this.setState({
      lines: '',
    });
  }

  handleInputChange = (event) => {
    this.setState({ lines: event.target.value });
  };

  onEnterKey = (e: any) => {
    if (e.which === 13) {
      this.onFormSubmit(e);
    }
  };
  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({});
  };
}
export default RubricDialog;
