import { Button, Grid } from '@material-ui/core';
import React, { VideoHTMLAttributes, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector } from 'reselect';
import 'video-react/dist/video-react.css';
import { setGlobalAlert, windowHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { errorNotification, successNotification } from '../../../utils/utils';
import {
  getTaskResponse,
  startAttempt,
  startTaskResponse,
  stopAttempt,
  submitAttempt,
  taskResponseSelected,
  uploadFile,
} from '../../actions/campusApiActions';
import { primaryColor } from '../../config/constants';
import {
  AWSUploadFields,
  Attempt,
  IActivityLog,
  INCOMPLETE,
  NOT_STARTED,
  getAttemptVideoUrl,
  getResponseStateAsStudent,
  isSubmitted,
  isUploaded,
} from '../../model/ApiTypes';
import { formatCampusLongDate } from '../../utils/utils';

interface IProps extends PropsFromRedux {
  match: any;
  history: any;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {
  isRecording: boolean;
  isTesting: boolean;
  mainVideoURL: string;
  //todo show/hide Start Task button
  mediaRecorder: MediaRecorder;
  timerRecording: NodeJS.Timeout;
  recordingEndTime: number;
  timerCountDown: NodeJS.Timeout;
  remainingTime: any;
  stream: any;
  globalTaskTimer: any; //FFS global task timeout no longer enforced
  taskStopped: boolean;

  uploading: boolean;
  file: File;

  showCountdown: boolean;
}

//err handling
//When the page refreshes react doesn't have the chance to unmount the components as normal.
//Use the window.onbeforeunload event to set a handler for refresh (read the comments in the code): CLEANUP - TIMERS MEDIA ETC
//https://stackoverflow.com/questions/39084924/componentwillunmount-not-being-called-when-refreshing-the-current-page

class StudentTaskPanel extends React.PureComponent<IProps, IState> {
  videoComponent: React.RefObject<any> = null;
  TIMEOUT_TEST = 10000;
  qMsg1 = () => {
    const reqAttrib = this.props.currentResponse.task;
    return `You will have ${reqAttrib.options.totaltime} minutes to make up to ${reqAttrib.options.attempts} attempts at record a ${reqAttrib.options.attempttime} seconds video.  Timer Started @`;
  };
  qMsg2 = () =>
    `At the beginning of every recording attempt, please show your Student Card or Government Issued ID for 10 seconds.  Ensure that it is visible to the Camera. `;

  qMsg3 = () => `Question`;

  prepMsg1 = () => {
    const reqAttrib = this.props.currentResponse.task;
    return `Your Task has not started yet. Once you start your Task, you will have ${reqAttrib.options.totaltime} minutes to make up to ${reqAttrib.options.attempts} attempts at record a ${reqAttrib.options.attempttime}
  seconds video. Once you have completed and reviewed your attempts, you must submit 1 of the recorded videos for grading
  within the ${reqAttrib.options.totaltime} minutes from the Task starts.`;
  };
  prepMsg2 = () => `Before you start your task, please do a short 10 second recording to ensure that your Audio & Video works correctly. Do
  not continue until you have reviewed and ensured that the test recording is working.`;

  prepEndMsg1 = () => {
    const reqAttrib = this.props.currentResponse.task;
    return `After reviewing an ensure both the Audio and Video works in the recording, you can Start your Task and the ${reqAttrib.options.totaltime} minutes timer by
    clicking the button below.`;
  };

  prepEndMsg2 = () => `If you are experiencing any problems, please see the HELP/FAQ page.`;

  qEndMsg = () => (this.props.uploadedAttempts.length ? `Review and Select 1 Video for Grading` : null);

  constructor(props: IProps) {
    super(props);
    console.log('StudentTaskPanel ctor,  props ', props);
    this.videoComponent = React.createRef();

    this.state = this.getInitialState();
  }
  getInitialState = () => {
    return {
      isRecording: false,
      isTesting: false,
      mediaRecorder: null,
      timer: null,
      mainVideoURL: null,
      stream: null,
      globalTaskTimer: null,
      timerRecording: null,
      recordingEndTime: null,
      timerCountDown: null,
      remainingTime: null,
      taskStopped: false,
      submitted: null,
      uploading: false,
      file: null,
      showCountdown: true,
    };
  };

  componentDidMount = () => {
    // this.props.getTasks(this.props.authentication.token);
    //this.props.taskSelected(this.props.match.params.id);
    this.load(this.props.match.params.id);
  };
  componentWillUnmount = () => {
    console.log('StudentTaskPanel will unmount ', this.props);
    this.cleanupOnExitTask();
  };

  cleanupOnExitTask = () => {
    if (this.state.globalTaskTimer) {
      clearTimeout(this.state.globalTaskTimer);
    }
    if (this.state.isTesting) {
      console.log('##############@@unmount stopping test recording ');
      this.onStopTestRecording();
      console.log('##############@@unmount sttoped  testrecording');
    }
    if (this.state.isRecording) {
      console.log('##############@@unmount stopping recording attempt');
      this.onStopRecordingAttempt();
      console.log('##############@@unmount sttoped  recording attempt');
    }
  };

  componentDidUpdate(prevProps: IProps) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      //TODO reste state ?
      console.log('TaskPanel will load another task ID ', id);
      this.cleanupOnExitTask();
      this.load(id);
    }
  }

  load(id) {
    this.props.taskSelected(id);
    this.props.getTaskResponse(id, this.props.authentication.token);
  }

  render() {
    let usefullHeight = window.innerHeight - 250;
    console.log('TaskPanel render, usefull height ', usefullHeight, this.props);
    const taskReq = this.props.currentResponse ? this.props.currentResponse.task : null;
    return this.props.currentResponse ? (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Your Task</span>
          </div>
          <div className="row w-100">
            <div className="col-12 no-padding">
              <div className="fig-generic-pane-right-side" style={{ height: usefullHeight + 110, overflowY: 'auto' }}>
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400, maxHeight: usefullHeight + 110 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '50%', maxWidth: '70%' }}>
                      <span>{taskReq.type}</span>
                      <span>{this.props.currentResponse.task.course.code}</span>
                      <span>{taskReq.name}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <span style={{ marginRight: 10 }}>Deadline: {formatCampusLongDate(taskReq.endtime)}</span>
                    </div>
                  </div>
                  {this.respondingStarted() ? (
                    <>
                      {this.qMsg1()}
                      <b>{formatCampusLongDate(this.props.currentResponse.starttime)}</b>
                      <br />
                      <br />
                      {this.qMsg2()}
                      <br />
                      <br />
                      <b>{this.qMsg3()}</b>
                      <br />
                      <br />
                      {this.props.currentResponse.question.text}
                      <br />
                      <br />
                    </>
                  ) : (
                    <>
                      {this.prepMsg1()}
                      <br />
                      <br />
                      <br />
                      <br />
                      {this.prepMsg2()}
                    </>
                  )}
                  {this.renderVideosArea()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
  testRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((mediaStream) => {
          const mediaRecorder = new MediaRecorder(mediaStream, {
            /*mimeType: 'video/webm' */
          });

          let recordedChunks = [];
          mediaRecorder.addEventListener('start', () => {
            console.log('##############@@Recording started');
            recordedChunks = [];
          });

          mediaRecorder.addEventListener('dataavailable', (event) => {
            console.log('##############@@Recording test data available ', event);
            if (event.data.size > 0) {
              recordedChunks.push(event.data);
            }
          });
          mediaRecorder.addEventListener('stop', () => {
            console.log('##############@@Recording stopped');
            mediaRecorder.stream.getTracks().forEach((track) => {
              if (track.readyState === 'live') {
                track.stop();
              }
            });

            this.state.timerRecording && clearTimeout(this.state.timerRecording);
            this.state.timerCountDown && clearInterval(this.state.timerCountDown);
            const recordedBlob = new Blob(recordedChunks /*{ type: 'video/webm' }*/);

            let url = URL.createObjectURL(recordedBlob);
            this.setState((state) => {
              return {
                isTesting: false,
                mediaRecorder: null,
                timerRecording: null,
                timerCountDown: null,
                recordingEndTime: null,
                remainingTime: null,
                mainVideoURL: url,
                stream: null,
              };
            });
          });

          // Listen for the error event
          mediaRecorder.addEventListener('error', (event) => {
            console.error('##############Recording error:', event);
            mediaRecorder.stop();
            let ev = event as unknown as { error: any };
            console.error(`##############@@The following mediaRecorder error occurred: ${ev.error}`);
            this.props.setGlobalAlert(`The following mediaRecorder error occurred: ${ev.error}`);
          });

          const timer = setTimeout(() => {
            if (this.state.isTesting && this.state.mediaRecorder === mediaRecorder) {
              console.log('##############@@timer sttopoing test recording');
              this.onStopTestRecording();
              console.log('##############@@timer sttoped  testrecording');
            }
          }, this.TIMEOUT_TEST + 1000);
          const timerEndTime = new Date().getTime() + this.TIMEOUT_TEST + 1000;
          const countDown = setInterval(() => {
            this.setState({ remainingTime: this.getRemainingTimeInfo() });
          }, 1000);
          this.setState(
            {
              mediaRecorder,
              timerRecording: timer,
              timerCountDown: countDown,
              remainingTime: this.getRemainingTimeInfo(timerEndTime),
              stream: mediaStream,
              recordingEndTime: timerEndTime,
            },
            () => {
              mediaRecorder.start(/*2000*/); //1 second slices
            }
          );
        })
        .catch((error) => {
          console.error(`##############@@The following getUserMedia error occurred: ${error}`);
          this.setState({ isTesting: false });
          this.props.setGlobalAlert(`The following getUserMedia error occurred: ${error}`);
        });
    } else {
      this.props.setGlobalAlert('getUserMedia not supported on your browser!');
    }
  };

  recordAttempt = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const taskReq = this.props.currentResponse.task;

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((mediaStream) => {
          const mediaRecorder = new MediaRecorder(mediaStream, {
            /*mimeType: 'video/webm' */
          });

          let recordedChunks = [];
          mediaRecorder.addEventListener('start', () => {
            console.log('##############Recording started');
            recordedChunks = [];
          });

          mediaRecorder.addEventListener('dataavailable', (event) => {
            console.log('##############@@Recording data available ', event);
            if (event.data.size > 0) {
              console.log('##############event data', event.data);
              recordedChunks.push(event.data);
            }
          });
          mediaRecorder.addEventListener('stop', () => {
            console.log('##############Recording stopped');
            if (this.state.timerRecording) clearTimeout(this.state.timerRecording);
            if (this.state.timerCountDown) clearInterval(this.state.timerCountDown);
            mediaRecorder.stream.getTracks().forEach((track) => {
              if (track.readyState === 'live') {
                track.stop();
              }
            });
            const recordedBlob = new Blob(recordedChunks, {
              /*type: 'video/webm' */
            });
            //TODO what if any api call fails?! retry? discard the recording ?
            this.props.stopAttempt(this.props.currentResponse.id, this.props.authentication.token).then(
              (taskResponse) => {
                //todo do we need task or it it already as prop currentRespomnse
                console.warn('after stopAttempt, success');
                this.uploadVideo(recordedBlob);
              },
              (err) => {
                console.log('after stopAttempt,  error ', err);
              }
            );

            //GINA FFS  dwnld  let url = URL.createObjectURL(recordedBlob);
            this.setState((state) => {
              return {
                //uploading: true, FFS, maybe we failed before uploading

                isRecording: false,
                mediaRecorder: null,
                mainVideoURL: null,
                timerRecording: null,
                timerCountDown: null,
                remainingTime: null,
                recordingEndTime: null,
                stream: null,
              };
            });
          });

          // Listen for the error event
          mediaRecorder.addEventListener('error', (event) => {
            //TODO HOW TO TEST
            console.error('##############Recording error:', event);
            mediaRecorder.stop();
            // this.setState({ isRecording: false });
            let ev = event as unknown as { error: any };
            console.error(`##############@@The following mediaRecorder error occurred: ${ev.error}`);
            this.props.setGlobalAlert(`The following mediaRecorder error occurred: ${ev.error}`);
          });

          const timer = setTimeout(() => {
            if (this.state.isRecording && this.state.mediaRecorder === mediaRecorder) {
              console.log('##############timer sttoping attempt rec');
              this.onStopRecordingAttempt();
              console.log('##############timer sttoped attempt rec');
            }
          }, taskReq.options.attempttime * 1000 + 1000);
          const timerEndTime = new Date().getTime() + taskReq.options.attempttime * 1000 + 1000;
          const countDown = setInterval(() => {
            this.setState({ remainingTime: this.getRemainingTimeInfo() });
          }, 1000);

          this.props.startAttempt(this.props.currentResponse.id, this.props.authentication.token).then(
            (taskResponse) => {
              //todo do we need task or it it already as prop currentRespomnse
              console.warn('after startAttempt, success');
              // Start recording
              this.setState(
                {
                  mediaRecorder,
                  timerRecording: timer,
                  remainingTime: this.getRemainingTimeInfo(timerEndTime),
                  timerCountDown: countDown,
                  stream: mediaStream,
                  recordingEndTime: timerEndTime,
                },
                () => {
                  mediaRecorder.start(/*2000*/);
                }
              );
            },
            (err) => {
              console.log('after startAttempt,  error ', err);
              this.cancelAttempting(timer, countDown, mediaRecorder);
            }
          );
        })
        .catch((error) => {
          console.error(`##############@@The following getUserMedia error occurred: ${error}`);
          this.setState({ isRecording: false });
          this.props.setGlobalAlert(`The following getUserMedia error occurred: ${error}`);
        });
    } else {
      this.props.setGlobalAlert('getUserMedia not supported on your browser!');
    }
  };

  //called if startAttempt failed
  cancelAttempting = (timerRecording, timerCountDown, mediaRecorder) => {
    console.log('##############Canceling recording attempt ');
    if (timerRecording) clearTimeout(timerRecording);
    if (timerCountDown) clearInterval(timerCountDown);
    mediaRecorder.stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.stop();
      }
    });
    this.setState((state) => {
      return {
        uploading: false,
        isRecording: false,
        mediaRecorder: null,
        mainVideoURL: null,
        timerRecording: null,
        timerCountDown: null,
        remainingTime: null,
        recordingEndTime: null,
        stream: null,
      };
    });
  };

  onStartTask = () => {
    // CHANGED: global task timer should not be enforced, it complicates things
    // const timer = setTimeout(() => {
    //   if (this.state.isRecording) {
    //     console.log('##############@@timerTask stopping recording attempt');
    //     this.onStopRecordingAttempt();
    //     console.log('##############@@timer sttoped  recording attempt');
    //   }
    //   if (this.state.isTesting) {
    //     console.log('##############@@timerTask stopping test recording ');
    //     this.onStopTestRecording();
    //     console.log('##############@@timer sttoped  testrecording');
    //   }
    //   this.setState({ taskStopped: true });
    //   this.props.setGlobalAlert('The time run out for your task');
    // }, this.props.currentResponse.task.options.totaltime * 60 * 1000);
    this.props.startTask(this.props.currentResponse.id, this.props.authentication.token);
    this.setState({
      mediaRecorder: null,
      stream: null,
      mainVideoURL: null,
      // globalTaskTimer: timer,
    });
  };

  onStartRecordingAttempt = () => {
    this.setState({ isRecording: true }, () => {
      this.recordAttempt();
    });
  };
  onStopRecordingAttempt = () => {
    if (this.state.mediaRecorder) this.state.mediaRecorder.stop();
  };
  onStartTestRecording = () => {
    this.setState({ isTesting: true }, () => {
      this.testRecording();
    });
  };
  onStopTestRecording = () => {
    if (this.state.mediaRecorder) this.state.mediaRecorder.stop();
  };

  onSubmit = (attempt: Attempt, index: number) => (event) => {
    this.props.submitAttempt(this.props.currentResponse.id, index, this.props.authentication.token).then(
      (task) => {
        if (this.state.globalTaskTimer) {
          clearTimeout(this.state.globalTaskTimer);
        }
        successNotification('Response succesfully submitted!');

        this.props.history.push(`/dashboard`);
      },
      (err) => {
        //TODO TODO
        errorNotification('Failed to submit response!');
      }
    );
  };

  static MAX_UPLOAD_SIZE = 1048576 * 500;
  uploadVideo = (recordedBlob: Blob) => {
    let info: AWSUploadFields = this.props.currentResponse.attempts[this.props.currentResponse.attempts.length - 1].videoFile;
    this.setState({ uploading: true }, () => {
      this.props.uploadFile(recordedBlob, info, this.props.currentResponse.id, this.props.authentication.token).then(
        (okId) => {
          console.warn('after upload, success');
          this.setState((state) => {
            return {
              uploading: false,
            };
          });
        },
        (err) => {
          console.log('after upload,  err ', err);
          errorNotification('Failed to upload response attempt!');
          this.setState({ uploading: false });
        }
      );
    });
  };

  getMainButtonInfo = () => {
    //currentTask
    const reqAttrib = this.props.currentResponse.task;
    if (this.respondingStarted()) {
      if (this.state.isRecording) {
        return {
          label: `Stop Recording Attempt ${1 + this.props.uploadedAttempts.length} of ${reqAttrib.options.attempts}`,
          action: this.onStopRecordingAttempt,
        };
      } else {
        if (reqAttrib.options.attempts > this.props.uploadedAttempts.length) {
          return {
            label: `Start Recording Attempt ${1 + this.props.uploadedAttempts.length} of ${reqAttrib.options.attempts}`,
            action: this.onStartRecordingAttempt,
          };
        } else {
          return {
            label: `TODO  NOT EXPECTED`,
            action: null,
          };
        }
      }
    } else if (this.state.isTesting) {
      return { label: 'Stop Test Recording', action: this.onStopTestRecording };
    } else {
      return { label: 'Start Test Recording', action: this.onStartTestRecording };
    }
  };

  private respondingStarted() {
    return (
      this.props.responseState !== NOT_STARTED && this.props.responseState !== INCOMPLETE && this.props.currentResponse.question != null
    );
    //TODO ASK WING FIX ON GET RESPONSES I don;t get the  question for Started responses; only when I get it individually
  }

  renderVideosArea() {
    let mainBtnInfo = this.getMainButtonInfo();
    let attemptsConsumed = this.props.currentResponse.task.options.attempts <= this.props.uploadedAttempts.length;
    let showCountdown = this.state.showCountdown;
    return (
      <div>
        <div className={'fig-generic-pane-right-side__contentbox ' + (attemptsConsumed ? 'hide' : '')}>
          <div className="textarea-box">
            <div className="">
              <div className="row no-gutters">
                <div className={this.respondingStarted() ? 'col-4' : 'col-4'}>
                  <div
                    className="no-padding"
                    style={{
                      flex: `0 0 ${100}%`,
                      visibility: 'visible',
                    }}
                  >
                    {!(this.state.isTesting || this.state.isRecording) ? (
                      <div>
                        <VideoWithStreamSrc
                          key="review"
                          srcObject={null}
                          src={this.respondingStarted() ? null : this.state.mainVideoURL}
                          // style={{ border: 1, borderStyle: 'solid', borderColor: 'red' }}
                          width="64%"
                          autoPlay={false}
                          controls={this.state.mainVideoURL != null /*TODO FFS GINA && this.props.responseState === NOT_STARTED*/}
                          muted={false}
                          hidden={this.respondingStarted()}
                        ></VideoWithStreamSrc>
                      </div>
                    ) : (
                      <div>
                        <VideoWithStreamSrc
                          srcObject={this.state.stream}
                          src={null}
                          width="64%"
                          autoPlay
                          muted
                          key="preview"
                        ></VideoWithStreamSrc>
                      </div>
                    )}
                  </div>
                </div>
                <div className={this.respondingStarted() ? 'col-4' : 'col-4'}>
                  <div className="button-holder h-100">
                    <Button
                      className="fig-button in-modal-dialog "
                      variant="outlined"
                      onClick={mainBtnInfo.action}
                      disabled={this.state.taskStopped || this.props.submittedAttempt != null || this.state.uploading}
                      hidden={attemptsConsumed}
                    >
                      {mainBtnInfo.label}
                    </Button>
                  </div>
                </div>

                <div className={this.respondingStarted() ? 'col-4' : 'col-4'}>
                  <div className="button-holder h-100">
                    {(this.state.isTesting || this.state.isRecording) && (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: '10%',
                            alignItems: 'center',
                            width: '183px',
                            textAlign: 'center',
                            color: primaryColor,
                            margin: 'auto',
                          }}
                        >
                          {showCountdown && this.state.remainingTime ? (
                            <>
                              <div className="row" style={{ width: '100%' }}>
                                <div className="col-5">
                                  <b>{this.state.remainingTime.minutes}</b>
                                </div>
                                <div className="col-2">:</div>
                                <div className="col-5">
                                  <b>{this.state.remainingTime.seconds}</b>
                                </div>
                              </div>
                              <div className="row" style={{ width: '100%' }}>
                                <div className="col-5">MINUTES</div>
                                <div className="col-2"></div>
                                <div className="col-5">SECONDS</div>
                              </div>
                            </>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>

                        <Button
                          className="fig-button in-modal-dialog "
                          variant="outlined"
                          onClick={() => {
                            this.setState({ showCountdown: !showCountdown });
                          }}
                          disabled={this.state.taskStopped || this.props.submittedAttempt != null || this.state.uploading}
                          hidden={attemptsConsumed}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{(showCountdown ? `Hide` : `Show`) + ` Remaining Time`}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                {/* </div>*/}
              </div>
            </div>
          </div>
          <br />
        </div>
        {!this.respondingStarted() ? (
          <>
            <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }}>
              <br />
              <b>{this.prepEndMsg1()}</b>
              <br />
              <b>{this.prepEndMsg2()}</b>
            </div>
            <div className="button-holder" hidden={this.respondingStarted()}>
              <Button
                className="fig-button in-modal-dialog "
                variant="outlined"
                onClick={this.onStartTask}
                disabled={this.state.taskStopped || this.state.isTesting || this.state.uploading}
              >
                Start Task
              </Button>
            </div>
          </>
        ) : (
          <div className="w-100 h-100" style={{ overflowX: 'hidden' }}>
            <br />
            {this.respondingStarted() ? <b>{this.qEndMsg()}</b> : null}
            <Grid container spacing={5} style={{ /*overflowY: 'auto', */ overflowX: 'hidden', alignItems: 'center', margin: 'auto' }}>
              {this.props.uploadedAttempts.map((attempt, idx) => this.renderAttempt(attempt, idx))}
            </Grid>
          </div>
        )}
      </div>
    );
  }

  renderAttempt = (attempt: Attempt, index: number) => {
    const url = getAttemptVideoUrl(attempt);
    return (
      <Grid item key={url} xs={12} sm={4} md={3}>
        <div className="textarea-box">
          <div className="w-100 h-100">
            <div className="row no-gutters h-100">
              <div className="col-12">
                <div
                  className="no-padding"
                  style={{
                    flex: `0 0 ${100}%`,
                    visibility: 'visible',
                  }}
                >
                  <div>
                    <VideoWithStreamSrc
                      srcObject={null}
                      src={url}
                      // style={{ border: 1, borderStyle: 'solid', borderColor: 'red', margin: 15, padding: 15 }}
                      width={'90%'}
                      autoPlay={false}
                      controls
                      muted={false}
                    ></VideoWithStreamSrc>
                  </div>

                  <div className="button-holder ">
                    <Button
                      className="fig-button in-modal-dialog "
                      variant="outlined"
                      onClick={this.onSubmit(attempt, index)}
                      disabled={this.state.taskStopped || this.props.submittedAttempt != null}
                    >
                      {`Submit Attempt #${index + 1}`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };
  static EMPTY_ATTEMPTS: Attempt[] = [];
  static EMPTY_LOGS: IActivityLog[] = [];

  getRemainingTimeInfo = (toMoment?) => {
    const timeleft = !isNaN(toMoment)
      ? toMoment - new Date().getTime()
      : !isNaN(this.state.recordingEndTime)
      ? this.state.recordingEndTime - new Date().getTime()
      : null;
    if (timeleft == null) return null;
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
    return { minutes, seconds };
  };
}

const selectorCrtResponse = (state: IRootState) =>
  state.taskResponses.selectedResponseId && state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    ? state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    : null;

const retrieveUploadedAttempts = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return StudentTaskPanel.EMPTY_ATTEMPTS;
  const res = crtResponse.attempts.filter((attempt) => isUploaded(attempt));
  return res.length > 0 ? res : StudentTaskPanel.EMPTY_ATTEMPTS;
});

const retrieveSubmittedAttempt = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return null;
  const res = crtResponse.attempts.find((attempt) => isSubmitted(attempt));
  return res ? res : null;
});

const retrieveResponseState = createSelector([selectorCrtResponse], (crtResponse) =>
  crtResponse ? getResponseStateAsStudent(crtResponse) : null
);

const mapStateToProps = (state: IRootState) => {
  console.log('StudentTaskPanel mapStateToProps ', state, state.utils.windowHeight);
  return {
    authentication: state.authentication,

    currentResponse: selectorCrtResponse(state),

    responseState: retrieveResponseState(state),

    uploadedAttempts: retrieveUploadedAttempts(state),
    submittedAttempt: retrieveSubmittedAttempt(state),

    windowHeight: state.utils.windowHeight,
  };
};

const mapDispatchToProps = {
  windowHeightUpdated,
  setGlobalAlert,
  taskSelected: taskResponseSelected,
  getTaskResponse,
  startTask: startTaskResponse,
  startAttempt,
  stopAttempt,
  submitAttempt,
  uploadFile,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(StudentTaskPanel);

export const getDefaultDeadline = () => {
  return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
};

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject: MediaStream;
};

function VideoWithStreamSrc({ srcObject, ...props }: PropsType) {
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!refVideo.current) return;
    refVideo.current.srcObject = srcObject;
  }, [srcObject]);

  return <video ref={refVideo} {...props} key={'' + srcObject} />;
}
