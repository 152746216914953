import { applyMiddleware, compose, createStore } from 'redux';
// import DevTools from './devtools';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import reducer, { IRootState } from '../shared/reducers';
import loggerMiddleware from './logger-middleware';

// import { loadingBarMiddleware } from 'react-redux-loading-bar';

const defaultMiddlewares = [
  thunkMiddleware,
  // errorMiddleware,
  // notificationMiddleware,
  // promiseMiddleware,
  // loadingBarMiddleware(),
  loggerMiddleware,
];
const composedMiddlewares = (middlewares) =>
  process.env.NODE_ENV === 'development'
    ? //  ? compose(applyMiddleware(...defaultMiddlewares, ...middlewares), DevTools.instrument())
      composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares))
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState?: IRootState, middlewares = []) => {
  console.log('store initialize called , initial state =, middlewares = ', initialState, middlewares);
  return createStore(reducer, initialState, composedMiddlewares(middlewares));
};

export default initialize;
