/* eslint-disable import/no-anonymous-default-export */
import { AUTH_CLEAR, AUTH_ERROR, AUTH_ROLE, AUTH_USER } from '../actions/campusApiActions/types';
import { IRole, LoginResponse, UserBasic } from '../model/ApiTypes';

const STORAGE_KEY_TOKEN = 'token';
const STORAGE_KEY_USER = 'user';
const STORAGE_KEY_ROLE = 'role';

const userValue = (): UserBasic => {
  try {
    return JSON.parse(window.sessionStorage.getItem(STORAGE_KEY_USER));
  } catch (e) {
    return null;
  }
};

const tokenValue = (): string => {
  try {
    return window.sessionStorage.getItem(STORAGE_KEY_TOKEN);
  } catch (e) {
    return null;
  }
};

const roleValue = (): IRole => {
  try {
    return JSON.parse(window.sessionStorage.getItem(STORAGE_KEY_ROLE));
  } catch (e) {
    return null;
  }
};

const clearAuthStorage = () => {
  window.sessionStorage.removeItem(STORAGE_KEY_USER);
  window.sessionStorage.removeItem(STORAGE_KEY_TOKEN);
  window.sessionStorage.removeItem(STORAGE_KEY_ROLE);
};

const INITIAL_STATE = {
  isSignedIn: false,
  loginError: false,
  isAdmin: false,
  user: userValue(),
  token: tokenValue(),
  role: roleValue(),
};

export type CampusAuthenticationState = Readonly<typeof INITIAL_STATE>;

function toValid(state: CampusAuthenticationState) {
  if (state && state.token && state.user && state.user.role && state.role) {
    // TODO FFS check all fields
    return state;
  } else {
    //console.log('auth state reset');
    return {
      isSignedIn: false,
      loginError: false,
      isAdmin: false,
      user: null,
      token: null,
      role: null,
    };
  }
}

// Reducers
export default (state: CampusAuthenticationState = toValid(INITIAL_STATE), action: any): CampusAuthenticationState => {
  let isAdmin = false;

  switch (action.type) {
    case AUTH_USER: {
      const loginResponse: LoginResponse = action.payload;
      if (loginResponse.user.role && loginResponse.user.role.name === 'Admin') {
        // TODO  ASK about role names
        isAdmin = true;
      }
      window.sessionStorage.setItem(STORAGE_KEY_USER, JSON.stringify(loginResponse.user));
      window.sessionStorage.setItem(STORAGE_KEY_TOKEN, loginResponse.jwt); // todo FFS THIS SHOULD BE REMOVED
      window.sessionStorage.setItem(STORAGE_KEY_ROLE, null);

      return {
        ...state,
        isSignedIn: true,
        isAdmin,
        user: loginResponse.user,
        token: loginResponse.jwt,
        role: null,
      };
    }
    case AUTH_ROLE: {
      const role: any = action.payload;
      window.sessionStorage.setItem(STORAGE_KEY_ROLE, JSON.stringify(role));

      return {
        ...state,
        role: role,
      };
    }
    case AUTH_ERROR: {
      return { ...state, isSignedIn: false, loginError: true, role: null };
    }
    case AUTH_CLEAR: {
      clearAuthStorage();
      return {
        ...state,
        isSignedIn: false,
        loginError: false,
        user: null,
        token: null,
        role: null,
      };
    }
    default:
      return state;
  }
};
