import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector } from 'reselect';
import 'video-react/dist/video-react.css';
import sprites from '../../../../icons/fig-icons.svg';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  createEvaluation,
  deleteEvaluation,
  getRubricForTask,
  getTaskResponse,
  getTaskResponseEvaluations,
  getTaskResponseEvaluationsByReviewer,
  getTranscript,
  taskResponseSelected,
  updateEvaluationComments,
  updateEvaluationRubricGrades,
} from '../../actions/campusApiActions';
import { primaryColor } from '../../config/constants';
import {
  Attempt,
  Comment,
  EvaluationBasic,
  IActivityLog,
  RubricBasic,
  TaskResponseBasic,
  getAttemptVideoUrl,
  getTranscriptText,
  isSubmitted,
  isUploaded,
} from '../../model/ApiTypes';
import { DURATION_UNKNOWN, TIME_INITIAL, formatCampusLongDate, seconds2mssmm } from '../../utils/utils';
import VideoRenderer from '../video/VideoRenderer';
import StudentTaskPanel from './StudentTaskPanel';

interface IProps extends PropsFromRedux {
  match: any;
  history: any;
}
interface IState {
  mainAttemptIndex: number;
  mainVideoURL: string;
  comment: string;
  commentRequired: boolean;
  evaluation: EvaluationBasic;
  evalExpanded: boolean;

  videoDuration: number;
  currentTime: number;
}

class TaskEval extends React.PureComponent<IProps, IState> {
  videoComponent: React.RefObject<any> = null;
  static NO_EVALUATION_YET: EvaluationBasic = { id: null, comments: null, rubric: null, data: null, createdAt: null, updatedAt: null };
  //used when getOwnEvaluations api call returns empty array
  //to diffrenriate between cases when own evaluatuins were not queried

  qMsg1 = () =>
    `${this.props.currentResponse.user.id} - ${this.props.currentResponse.user.username} - ${this.props.currentResponse.user.email}    `;

  qMsg3 = () => `Question`;
  qTranscript = () => `Transcript of the Submitted Video`;

  qEndMsg = () => (this.props.uploadedAttempts.length ? `Attempts` : null);

  onViewSubmission = (url, attemptIndex) => (event) => {
    if (this.state.mainAttemptIndex !== attemptIndex) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ mainAttemptIndex: attemptIndex });
    }
  };

  onVideoTimeReached = (time: number) => {
    // this.setState({ currentTime: Math.floor(time) }); //TODO FFS IF RENDERING NEEDS TO BE REDUCCED , WE COULD  HAVE SEPARATE COMPONENT JUST FOR COMMENTS, w curentTime = time, while main comonent uses just Math.floor
    this.setState({ currentTime: time });
  };
  onVideoDurationComputed = (duration: number) => {
    this.setState({ videoDuration: duration });
  };

  constructor(props: IProps) {
    super(props);
    console.log('TaskEval ctor,  props ', props);
    this.state = this.getInitialState();
    this.videoComponent = React.createRef();
  }
  getInitialState = () => {
    return {
      mainAttemptIndex: -1,
      mainVideoURL: null,
      comment: '',
      commentRequired: false,
      evaluation: null,
      evalExpanded: true,

      videoDuration: DURATION_UNKNOWN,
      currentTime: TIME_INITIAL,
    };
  };

  componentDidMount = () => {
    this.load(this.props.match.params.id);
  };
  componentWillUnmount = () => {
    console.log('TaskEval will unmount ', this.props);
    this.cleanupOnExitTask();
  };

  cleanupOnExitTask = () => {};
  crtGridRef = null;

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      console.log('TaskEval will load another task ID ', id);
      this.cleanupOnExitTask();
      this.load(id);
    }
    if (this.props.myEvaluation !== prevProps.myEvaluation || this.props.taskRubric !== prevProps.taskRubric) {
      if (this.props.myEvaluation && this.props.taskRubric) {
        this.setState({ evaluation: this.cloneOrMakeEmptyEvaluation(this.props.taskRubric, this.props.myEvaluation) });
      } else {
        this.setState({ evaluation: null });
      }
    }

    if (prevState.currentTime !== this.state.currentTime) {
      if (!this.crtGridRef) return;
      let nodeToScrollTo: Element = null;
      let node: any = null;
      let scrollIdx = -1;

      if (this.infoForScrolling.mostRecentCommentTime >= 0) {
        scrollIdx = this.infoForScrolling.mostRecentCommentIdx;
      } else if (this.infoForScrolling.nextCommentIdx >= 0) {
        scrollIdx = this.infoForScrolling.nextCommentIdx;
      }
      if (scrollIdx >= 0) {
        const selector = this.buildCommentItemElementId(scrollIdx);
        node = this.crtGridRef.querySelector(`#${selector}`);
        if (node) {
          if (node.length > 1) {
            nodeToScrollTo = node[0];
          } else {
            nodeToScrollTo = node;
          }
          nodeToScrollTo.scrollIntoView({
            block: 'nearest',
          });
        }
      }
    }
  }

  load(id) {
    this.props.taskResponseSelected(id);
    this.props.getTaskResponse(id, this.props.authentication.token).then((taskResponse: TaskResponseBasic) => {
      if (taskResponse != null && taskResponse.task != null) {
        this.props.getRubricForTask(taskResponse.task.id, this.props.authentication.token);
        const submitted = getSubmittedAttemptINfo(taskResponse);
        if (submitted.attempt) {
          this.props.getTranscript(id, submitted.attempt, this.props.authentication.token);
          this.setState({ mainVideoURL: getAttemptVideoUrl(submitted.attempt) });
        }
      }
    });
    //this.props.getTaskResponseEvaluations(id, this.props.authentication.token);
    this.props.getTaskResponseEvaluationsByReviewer(id, this.props.authentication.user.id, this.props.authentication.token);
  }

  cloneOrMakeEmptyEvaluation = (rubricInfo: RubricBasic, evaluation: EvaluationBasic) => {
    if (this.isRealEvaluation(evaluation)) {
      return this.cloneEvaluation(evaluation);
    } else {
      return this.makeEmptyEvaluation(rubricInfo);
    }
  };

  cloneEvaluation = (evaluation: EvaluationBasic) => {
    let rez = { ...evaluation, comments: [], rubric: [] } as EvaluationBasic;
    evaluation.comments.forEach((comment, idx) => {
      rez.comments.push({ ...comment });
    });
    rez.rubric = [...evaluation.rubric];
    rez.responseId = this.props.currentResponseId; //Response/ResponseId is not retrieved by api call
    return rez;
  };
  makeEmptyEvaluation = (rubricInfo: RubricBasic) => {
    let rez = {
      comments: [],
      rubric: [],
      user: { ...this.props.authentication.user },
      responseId: this.props.currentResponseId,
    } as EvaluationBasic;
    rubricInfo.questions.forEach((question) => {
      rez.rubric.push(null);
    });
    return rez;
  };

  isRealEvaluation = (evaluation: EvaluationBasic) => evaluation && evaluation !== TaskEval.NO_EVALUATION_YET;

  saveGrades = () => {
    if (this.isRealEvaluation(this.props.myEvaluation)) {
      this.props.updateEvaluationRubricGrades(
        this.props.myEvaluation.id,
        this.cloneOrMakeEmptyEvaluation(this.props.taskRubric, this.state.evaluation),
        this.props.authentication.token
      );
    } else {
      this.props.createEvaluation(
        this.cloneOrMakeEmptyEvaluation(this.props.taskRubric, this.state.evaluation),
        this.props.authentication.token
      );
    }
  };
  onDeleteEvaluation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.isRealEvaluation(this.props.myEvaluation)) {
      this.props.deleteEvaluation(this.props.myEvaluation.id, this.props.authentication.token);
    }
  };
  gotoCommentMomment = (comment: Comment) => (event) => {
    const isShowingTheSubmittedAttempt = this.props.submittedAttempt
      ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
      : false;

    if (isShowingTheSubmittedAttempt) this.videoComponent.current.seek(comment.time);
  };
  onDeleteComment = (comment: Comment) => (event) => {
    this.props.updateEvaluationComments(
      this.props.myEvaluation.id,
      [...this.state.evaluation.comments.filter((crt) => !(crt.text === comment.text && crt.time === comment.time))],
      this.props.authentication.token
    );
  };
  onEnterComment(e: any) {
    if (e.which === 13) {
      this.addComment();
    }
  }

  addCommentAndSort = (comments, text, time) =>
    [...comments, { text, time }].sort((a: Comment, b: Comment) => (a.time < b.time ? -1 : a.time > b.time ? +1 : 0));

  addComment = () => {
    const text = this.state.comment;
    const time = this.videoComponent.current.getCurrentTime();

    if (this.isRealEvaluation(this.props.myEvaluation)) {
      this.props
        .updateEvaluationComments(
          this.props.myEvaluation.id,
          this.addCommentAndSort(this.state.evaluation.comments, text, time),
          this.props.authentication.token
        )
        .then(() => this.setState({ comment: '', commentRequired: false }));
    } else {
      this.props
        .createEvaluation(
          {
            ...this.makeEmptyEvaluation(this.props.taskRubric),
            comments: this.addCommentAndSort([], text, time),
          },
          this.props.authentication.token
        )
        .then(() => this.setState({ comment: '', commentRequired: false }));
    }
  };
  handleCommentChange = (event) => {
    this.setState({ comment: event.target.value, commentRequired: true });
  };

  // onDeleteEvaluation = () => {
  //   if (this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation) {
  //     this.setState({ deleteEvaluationDialogOpened: true });
  //   }
  // };
  // onDeleteEvaluationConfirmed = () => {
  //   this.closeDeleteEvaluationConfirmationDialog();
  //   if (this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation) {
  //     this.props.deleteSubmissionEvaluation(
  //       this.props.ownEvaluations[this.state.selectedRubricIdx].evaluation.id,
  //       this.props.ownEvaluations[this.state.selectedRubricIdx].rubricInfo.id,
  //       this.props.authentication.token
  //     );
  //   }
  // };
  // closeDeleteEvaluationConfirmationDialog = () => {
  //   this.setState({ deleteEvaluationDialogOpened: false });
  // };

  render() {
    let usefullHeight = window.innerHeight - 250;
    console.log('TaskEval render, usefull height ', usefullHeight, this.props);
    return this.props.currentResponse ? (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>Task</span>
          </div>
          <div className="row w-100">
            <div className="col-12 no-padding" style={{ height: usefullHeight + 110, overflowY: 'auto' }}>
              <div className="fig-generic-pane-right-side  ">
                <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400, maxHeight: usefullHeight + 110 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '30%', maxWidth: '50%' }}>
                      <span>{this.props.currentResponse.task.type}</span>
                      <span>{this.props.currentResponse.task.course.code}</span>
                      <span>{this.props.currentResponse.task.name}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <span style={{ marginRight: 10 }}>Timer Started @ {formatCampusLongDate(this.props.currentResponse.starttime)}</span>
                    </div>
                  </div>
                  <>
                    <b>
                      <br />
                      {this.qMsg1()}
                    </b>
                    <br />
                    <br />
                    <b>{this.qMsg3()}</b>
                    <br />
                    <br />
                    {this.props.currentResponse.question ? this.props.currentResponse.question.text : ''}
                    <br />
                    <br />
                    <b>{this.qTranscript()}</b>
                    <br />
                    <br />
                    {this.props.transcript ? this.props.transcript : 'Transcription in Progress'}
                    <br />
                    <br />
                  </>
                  {this.renderRubricEvaluation()}
                  {this.renderAttempts()}
                  {this.renderActivityLogs()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }

  getMainButtonInfo = () => {};

  renderAttempts() {
    return (
      <Accordion key={'summary_attempts'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Attempts</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div>
              <div className="w-100 h-100">
                {/* <br />
                {<b>{this.qEndMsg()}</b>} */}
                <Grid container spacing={1} style={{ /*overflowY: 'auto',*/ alignItems: 'center' }}>
                  {this.props.uploadedAttempts.map((attempt, idx) => this.renderAttempt(attempt, idx))}
                </Grid>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderAttempt = (attempt: Attempt, index: number) => {
    const submitted = isSubmitted(attempt);
    const url = getAttemptVideoUrl(attempt);

    const isShowing = url === this.state.mainVideoURL;

    const isSelected = this.state.mainAttemptIndex === index;
    return (
      <Grid item key={url} xs={12} sm={3} md={2}>
        <div className="textarea-box">
          <div className="w-100 h-100">
            <div className="row no-gutters h-100">
              <div className="col-12">
                <div
                  className="no-padding"
                  style={{
                    flex: `0 0 ${100}%`,
                    visibility: 'visible',
                  }}
                >
                  <div onClick={this.onViewSubmission(url, index)}>
                    <video
                      src={url}
                      width={'100%'}
                      autoPlay={false}
                      controls
                      muted={false}
                      style={
                        isSelected
                          ? {
                              borderWidth: 3,
                              borderStyle: 'solid',
                              borderColor: `rgba(155, 167, 188, 0.5) `, //TODO GINA ffs button border color scss
                              borderRadius: 4,
                            }
                          : { cursor: 'pointer' }
                      }
                    ></video>
                  </div>

                  <div className="button-holder w-100">
                    <Button
                      className={`fig-button in-modal-dialog padding--10 w-100 ${isShowing ? 'NUghost-to-dark-blue' : ''}`}
                      onClick={this.onViewSubmission(url, index)}
                      disabled={isSelected}
                    >
                      {/* {`${!isSelected ? 'View' : ''} ${submitted ? 'Submitted' : `Attempt #${index + 1}`}`} */}
                      {`${submitted ? 'Submitted' : `Attempt #${index + 1}`}`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  private static noInfoForScrolling = {
    mostRecentCommentTime: -1,
    mostRecentCommentIdx: -1,
    nextCommentTime: 1000000,
    nextCommentIdx: -1,
  };
  infoForScrolling = { ...TaskEval.noInfoForScrolling };

  buildCommentItemElementId = (nth: number) => {
    return `Comments_${nth}`;
  };

  renderCommentsInEval() {
    console.log('render comments, current time=', this.state.currentTime);
    this.infoForScrolling = { ...TaskEval.noInfoForScrolling };
    const isShowingTheSubmittedAttempt = this.props.submittedAttempt
      ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
      : false;
    if (!isShowingTheSubmittedAttempt) this.crtGridRef = null;
    return isShowingTheSubmittedAttempt ? (
      <div className="row">
        <div className="col-12 fig-video-details-matrix-main-container">
          <div className=" no-padding fig-evaluation-header ">
            <div className="col-6 no-padding fig-evaluation-header ">
              {/* <div>Shown : {isShowingTheSubmittedAttempt ? 'Submitted' : `Attempt #${this.state.mainAttemptIndex + 1}`} video</div> */}
              {/* <div>Comments for: Submitted video</div> */}
              {this.renderCommentForm()}
              <div
                ref={(e) => {
                  this.crtGridRef = e;
                }}
                style={{ height: 140, overflowY: 'auto' }}
              >
                {this.state.evaluation && this.state.evaluation.comments
                  ? this.state.evaluation.comments.map((comment, idx) => {
                      const ctime = comment.time;
                      if (ctime > this.infoForScrolling.mostRecentCommentTime && ctime <= this.state.currentTime) {
                        this.infoForScrolling.mostRecentCommentTime = ctime;
                        this.infoForScrolling.mostRecentCommentIdx = idx;
                      }
                      if (ctime < this.infoForScrolling.nextCommentTime && ctime >= this.state.currentTime) {
                        this.infoForScrolling.nextCommentTime = ctime;
                        this.infoForScrolling.nextCommentIdx = idx;
                      }
                      const isShowingTheSubmission = this.props.submittedAttempt
                        ? getAttemptVideoUrl(this.props.submittedAttempt) === this.state.mainVideoURL
                        : false;
                      return (
                        <div className="row " key={comment.time + '_' + idx} id={this.buildCommentItemElementId(idx)}>
                          {this.renderDeleteCommentBtn(comment, isShowingTheSubmission)}
                          {this.renderComment(comment, isShowingTheSubmission)}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
  renderDeleteCommentBtn = (item: Comment, isShowingTheSubmission: boolean) => {
    if (isShowingTheSubmission) {
      return (
        <div>
          <Tooltip title="Delete this comment">
            <IconButton
              aria-label="delete"
              className="fig-button fig-delete-rubric margin-top0 margin-right1"
              size="small"
              onClick={this.onDeleteComment(item)}
            >
              <svg className="fig-icon-delete">
                <use href={sprites + '#delete-icon'} />
              </svg>
            </IconButton>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <IconButton
          aria-label="delete"
          className="fig-button fig-delete-rubric margin-top0 margin-right1"
          size="small"
          onClick={this.onDeleteComment(item)}
        >
          <svg className="fig-icon-delete">
            <use href={sprites + '#delete-icon'} />
          </svg>
        </IconButton>
      );
    }
  };

  renderComment = (item: Comment, isShowingTheSubmission: boolean) => {
    const isPlaying = item.time >= Math.floor(this.state.currentTime) && item.time < Math.floor(this.state.currentTime) + 1;
    if (isShowingTheSubmission) {
      const style = isPlaying
        ? {
            color: primaryColor,
            cursor: 'pointer',
          }
        : {
            cursor: 'pointer', //'#050505'
          };
      return (
        <div>
          <Tooltip title={`Go To ${seconds2mssmm(item.time)}`}>
            <span onClick={this.gotoCommentMomment(item)} style={style}>
              {seconds2mssmm(item.time)}
            </span>
          </Tooltip>
          &nbsp;&nbsp; - &nbsp;&nbsp;
          <Tooltip title={`Go To ${seconds2mssmm(item.time)}`}>
            <span onClick={this.gotoCommentMomment(item)} style={style}>
              {item.text}
            </span>
          </Tooltip>
        </div>
      );
    } else {
      const style = isPlaying
        ? {
            color: primaryColor,
          }
        : {};

      return (
        <div>
          <span style={style}>{seconds2mssmm(item.time)}</span>
          &nbsp;&nbsp; - &nbsp;&nbsp;
          <span style={style}>{item.text}</span>
        </div>
      );
    }
  };
  renderCommentForm() {
    const isNotShowingTheSubmittedAttempt = this.props.submittedAttempt
      ? getAttemptVideoUrl(this.props.submittedAttempt) !== this.state.mainVideoURL
      : true;
    //used for allowing to comment just when the Submitted attempt is the one shown in the main video
    return (
      <form className="row fig-vertical-middle" onSubmit={() => {}}>
        <TextField
          className="col-9 fig-modal-dialog-input-underlined"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          placeholder={'Enter Comment'}
          value={this.state.comment}
          onChange={this.handleCommentChange}
          error={this.state.comment.length === 0 && this.state.commentRequired}
          onKeyPress={(e) => this.onEnterComment(e)}
          required
          disabled={isNotShowingTheSubmittedAttempt}
        />
        <Button
          className={`fig-button in-modal-dialog  col-2 ${isNotShowingTheSubmittedAttempt ? 'hide' : ''}`}
          variant="outlined"
          onClick={this.addComment}
          disabled={isNotShowingTheSubmittedAttempt}
        >
          {`Add Comment`}
        </Button>
      </form>
    );
  }

  getAverageGrade = () => {
    if (this.state.evaluation && this.props.taskRubric) {
      const res =
        this.props.taskRubric.questions.reduce(
          (accumulator, question, idx) => accumulator + question.weight * this.state.evaluation.rubric[idx],
          0
        ) / this.getScore();
      const hasNull = this.state.evaluation.rubric.indexOf(null) != -1;
      return hasNull || isNaN(res) || res === 0 /*FFS 0 should be allowed but cases*/ ? null : res;
    } else {
      return null;
    }
  };

  getScore = () => {
    if (this.props.taskRubric) {
      return this.props.taskRubric.questions[0].score;
    } else {
      return null;
    }
  };

  renderRubricEvaluation() {
    const rubric = this.props.taskRubric;
    const globalScore = this.getScore();
    const averageGrade = Math.round(this.getAverageGrade() * 100) / 100;
    return (
      <Accordion
        key={'evaluation'}
        className="fig-accordion"
        expanded={this.state.evalExpanded}
        onChange={() => {
          this.setState({ evalExpanded: !this.state.evalExpanded });
        }}
      >
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Evaluation by {this.props.authentication.user.username}</b>
          </span>
          <Tooltip title="Delete this evaluation">
            <IconButton
              aria-label="delete"
              className="fig-button fig-delete-rubric margin-top0"
              size="small"
              hidden={!this.isRealEvaluation(this.props.myEvaluation)}
              onClick={this.onDeleteEvaluation}
            >
              <svg className="fig-icon-delete">
                <use href={sprites + '#delete-icon'} />
              </svg>
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </AccordionSummary>

        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            <div className="row">
              <div className="col-4 fig-video-details-matrix-main-container">
                <div className="row no-gutters h-100">
                  <div className="col-12 ">
                    <div
                      className="no-padding"
                      style={{
                        flex: `0 0 ${100}%`,
                        visibility: 'visible',
                      }}
                    >
                      <div>
                        <VideoRenderer
                          ref={this.videoComponent}
                          url={
                            this.state.mainVideoURL
                              ? this.state.mainVideoURL
                              : this.props.submittedAttempt
                              ? getAttemptVideoUrl(this.props.submittedAttempt)
                              : null
                          }
                          // width={'80%'}
                          timeReached={this.onVideoTimeReached}
                          durationComputed={this.onVideoDurationComputed}
                        />

                        {/* <video
                          src={
                            this.state.mainVideoURL
                              ? this.state.mainVideoURL
                              : this.props.submittedAttempt
                              ? getAttemptVideoUrl(this.props.submittedAttempt)
                              : null
                          }
                          // style={{ border: 1, borderStyle: 'solid', borderColor: 'red', margin: 15, padding: 15 }}
                          width={'80%'}
                          autoPlay={false}
                          controls
                          muted={false}
                        ></video> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-8 fig-video-details-matrix-main-container">
                <div className="row no-gutters h-100">
                  <div className="col-3 no-padding">
                    <div className=" no-padding fig-evaluation-header ">
                      <div>Grade</div>
                    </div>
                    <div className="average-grade-container" style={{fontSize: '2.000rem'}}>
                      {averageGrade}
                      {averageGrade != null ? (globalScore === 100 ? '%' : `/ ${globalScore}`) : ''}
                    </div>
                  </div>
                  <div
                    className="col-9 no-padding fig-evaluation-header"
                    // style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                  >
                    <div className="row ">Rubric</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 0 }}>
                      <div className="row">
                        {rubric && rubric.questions ? (
                          <div className="row">
                            {rubric.questions.map((question, idxQuestion) =>
                              this.renderQuestionDetails(idxQuestion, question.text, question.weight, question.score)
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="row button-holder ">
                        <Button
                          className="fig-button in-modal-dialog "
                          variant="outlined"
                          onClick={() => {
                            this.saveGrades();
                          }}
                        >
                          Save grades
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.renderCommentsInEval()}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  renderQuestionDetails = (idxQuestion: number, question: string, weight: number, rubricScore: number) => {
    return this.renderQuestionWScore(
      idxQuestion,
      question,
      weight,
      rubricScore,
      this.state.evaluation && this.state.evaluation.rubric ? this.state.evaluation.rubric[idxQuestion] : null
    );
  };

  renderQuestionWScore = (idxQuestion: number, question: string, weight: number, rubricScore: number, grade: number) => {
    let itemToRender = (
      <div className="row w-100 fig-question-row" key={`_${idxQuestion}`}>
        <div className="col-7 no-padding fig-question-text ">
          <div>
            <b>{idxQuestion + 1}.</b> {question} &nbsp;(weight {weight}%)
          </div>
        </div>
        <div className="col-5 no-padding fig-matrix-scores">{this.renderGradePerScore(grade, rubricScore, idxQuestion)}</div>
      </div>
    );
    return itemToRender;
  };

  renderGradePerScore(grade, rubricScore: number, idxQuestion) {
    return (
      // <div className="fig-matrix-scoresbox ">
      //   {/* {grade ? grade.toFixed(2) : '-'}/{score.toFixed(2)} */}
      //   {grade ? grade : '-'}/{score}
      // </div>

      <>
        <TextField
          className="col-6  fig-modal-dialog-input-underlined no-padding"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          placeholder={'Enter Grade '}
          value={grade == null || isNaN(grade) ? '' : grade}
          onChange={this.onGradeChange(idxQuestion, rubricScore)}
          required
          error={false /*!this.state.description || !this.state.description.length*/}
          inputProps={{ /*min: 0,*/ style: { textAlign: 'right' } }}
        />
        <TextField
          className="col-6  fig-modal-dialog-input  no-padding"
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          disabled
          value={'/ ' + rubricScore}
          inputProps={{ readOnly: true }}
        />
      </>
    );
  }
  onGradeChange = (idxQuestion: number, rubricScore: number) => (event) => {
    const newValue = Number.parseFloat(event.target.value);
    if (isNaN(newValue) || (newValue >= 0 && newValue <= rubricScore)) {
      const newRubric = [...this.state.evaluation.rubric];
      newRubric[idxQuestion] = newValue;
      this.setState({ evaluation: { ...this.state.evaluation, rubric: newRubric } });
    }
  };

  renderActivityLogs() {
    return (
      <Accordion key={'summary_logs'} className="fig-accordion">
        <AccordionSummary
          className="row fig-vertical-middle"
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
          <span className="row fig-vertical-middle" aria-label="Enable Summary">
            <b>Activity Logs</b>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-12 fig-accordion-details">
            {this.props.activity.map((item, idx) => (
              <div className="row" key={item.time + '_' + idx}>
                <span>{item.time}</span>&nbsp;&nbsp; - &nbsp;&nbsp;<span>{item.message}</span>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const selectorCrtResponse = (state: IRootState) =>
  state.taskResponses.selectedResponseId && state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    ? state.taskResponses.taskResponseMap[state.taskResponses.selectedResponseId]
    : null;

const retrieveUploadedAttempts = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return StudentTaskPanel.EMPTY_ATTEMPTS;
  const res = crtResponse.attempts.filter((attempt) => isUploaded(attempt));
  return res.length > 0 ? res : StudentTaskPanel.EMPTY_ATTEMPTS;
});

const retrieveSubmittedAttempt = createSelector([selectorCrtResponse], (crtResponse) => getSubmittedAttemptINfo(crtResponse));

const getSubmittedAttemptINfo = (crtResponse) => {
  if (crtResponse == null || !crtResponse.attempts) return { attempt: null, position: -1 };
  let position = -1;
  const res = crtResponse.attempts.find((attempt, index) => {
    if (isSubmitted(attempt)) {
      position = index;
      return true;
    } else return false;
  });
  return { attempt: res ? res : null, position };
};

const selectorCrtTranscript = (state: IRootState) =>
  state.taskResponses.selectedResponseId && state.taskResponses.responseTranscriptMap[state.taskResponses.selectedResponseId]
    ? state.taskResponses.responseTranscriptMap[state.taskResponses.selectedResponseId]
    : null;

const retrieveTranscript = createSelector([selectorCrtTranscript], (transcriptResult) => {
  if (transcriptResult == null) return null;
  else return getTranscriptText(transcriptResult);
});

const getActivityLogs = createSelector([selectorCrtResponse], (crtResponse) => {
  if (crtResponse == null) return StudentTaskPanel.EMPTY_LOGS;
  const res = [] as IActivityLog[];
  if (crtResponse.starttime) {
    res.push({ message: 'Started Task', time: new Date(crtResponse.starttime).toLocaleString() });
  }
  if (!crtResponse.attempts || crtResponse.attempts.length === 0) return res;

  let idxSubmitted = null;
  crtResponse.attempts.forEach((attempt, index) => {
    if (attempt.starttime) res.push({ message: `Started Attempt #${index + 1}`, time: new Date(attempt.starttime).toLocaleString() });
    if (attempt.stoptime) res.push({ message: `Completed Attempt #${index + 1}`, time: new Date(attempt.stoptime).toLocaleString() });
    if (attempt.uploadtime) res.push({ message: `Uploaded Attempt #${index + 1}`, time: new Date(attempt.uploadtime).toLocaleString() });
    if (idxSubmitted == null && isSubmitted(attempt)) {
      idxSubmitted = index;
    }
  });
  if (crtResponse.submissiontime != null && idxSubmitted != null) {
    res.push({ message: `Submitted Attempt #${idxSubmitted + 1}`, time: new Date(crtResponse.submissiontime).toLocaleString() });
  }

  return res;
});

const getLatestOwnEvaluation = (state: IRootState) => {
  let res = state.taskResponses.responseEvaluationsMap
    ? Object.values(state.taskResponses.responseEvaluationsMap).sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA < dateB ? -1 : dateB === dateA ? 0 : 1;
      })
    : null;
  if (res && res.length > 0) {
    return res[res.length - 1];
  } else if (res) {
    return TaskEval.NO_EVALUATION_YET;
  } else return null;
};

const mapStateToProps = (state: IRootState) => {
  console.log('TaskEval mapStateToProps ', state, state.utils.windowHeight);
  return {
    authentication: state.authentication,
    windowHeight: state.utils.windowHeight,
    currentResponse: selectorCrtResponse(state),
    uploadedAttempts: retrieveUploadedAttempts(state),
    submittedAttempt: retrieveSubmittedAttempt(state).attempt,
    submittedIndex: retrieveSubmittedAttempt(state).position,
    transcript: retrieveTranscript(state),
    activity: getActivityLogs(state),
    myEvaluation: getLatestOwnEvaluation(state),
    taskRubric: state.courses.taskRubric,
    currentResponseId: state.taskResponses.selectedResponseId,
  };
};

const mapDispatchToProps = {
  getTaskResponse,
  setGlobalAlert,
  taskResponseSelected,
  getTranscript,
  getTaskResponseEvaluations,
  getTaskResponseEvaluationsByReviewer,
  getRubricForTask,
  updateEvaluationRubricGrades,
  createEvaluation,
  deleteEvaluation,
  updateEvaluationComments,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TaskEval);

export const getDefaultDeadline = () => {
  return new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
};
