import {
  AUTH_CLEAR,
  AuthClearAction,
  COURSE_SELECTED,
  CourseSelectedAction,
  TASK_REQUEST_SELECTED,
  TASK_RESPONSE,
  TASK_RESPONSES,
  TASK_RESPONSE_EVALUATION,
  TASK_RESPONSE_EVALUATIONS,
  TASK_RESPONSE_EVALUATION_DELETED,
  TASK_RESPONSE_SELECTED,
  TASK_RESPONSE_TRANSCRIPT,
  TaskRequestSelectedAction,
  TaskResponsesActionTypes,
} from '../actions/campusApiActions/types';
import { TaskResponseBasic, TranscriptResult } from '../model/ApiTypes';
import { TaskRequestEvaluationsMap } from './coursesReducer';

const initialTaskResponseMap: TaskResponseMap = {};
const initialResponseTranscriptMap: TaskTranscriptMap = {};
const initialResponseEvaluationsMap = null as TaskRequestEvaluationsMap;
const initialState = {
  taskResponseMap: initialTaskResponseMap,
  selectedResponseId: null as number,
  responseTranscriptMap: initialResponseTranscriptMap,
  responseEvaluationsMap: initialResponseEvaluationsMap,
};

export type TaskResponsesState = Readonly<typeof initialState>;
export type TaskResponseMap = { [id: string]: TaskResponseBasic };
export type TaskTranscriptMap = { [id: string]: TranscriptResult };
// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: TaskResponsesState = initialState,
  action: TaskResponsesActionTypes | AuthClearAction | TaskRequestSelectedAction | CourseSelectedAction
): TaskResponsesState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case TASK_RESPONSES: {
      const map = {};
      action.payload.taskResponses.forEach((task) => {
        map[task.id] = task;
      });
      return {
        ...state,
        taskResponseMap: map,
      };
    }
    case TASK_RESPONSE_SELECTED: {
      return {
        ...state,
        selectedResponseId: action.payload.id,
        responseTranscriptMap: initialResponseTranscriptMap, //FFS ust one entry not really a map
        responseEvaluationsMap: initialResponseEvaluationsMap,
      };
    }

    case COURSE_SELECTED:
    case TASK_REQUEST_SELECTED: {
      return {
        ...state,
        taskResponseMap: initialTaskResponseMap, 
        selectedResponseId: null,
        responseEvaluationsMap: initialResponseEvaluationsMap,
      };
    }

    case TASK_RESPONSE: {
      return {
        ...state,
        taskResponseMap: { ...state.taskResponseMap, [action.payload.task.id]: action.payload.task },
      };
    }
    case TASK_RESPONSE_TRANSCRIPT: {
      return {
        ...state,
        responseTranscriptMap: { [action.payload.id]: action.payload.transcript },
      };
    }
    case TASK_RESPONSE_EVALUATIONS: {
      const map: TaskRequestEvaluationsMap = {};
      action.payload.evaluations.forEach((evaluation) => {
        map[evaluation.id] = evaluation;
      });

      return {
        ...state,
        //TODO FFS MAKE SURE THEY ARE FOR CRT SELECTED response
        responseEvaluationsMap: map,
      };
    }
    case TASK_RESPONSE_EVALUATION: {
      return {
        ...state,
        responseEvaluationsMap: state.responseEvaluationsMap
          ? { ...state.responseEvaluationsMap, [action.payload.evaluation.id]: action.payload.evaluation }
          : { [action.payload.evaluation.id]: action.payload.evaluation },
      };
    }
    case TASK_RESPONSE_EVALUATION_DELETED: {
      if (state.responseEvaluationsMap) {
        const newEvalMapState = { ...state.responseEvaluationsMap };
        delete newEvalMapState[action.payload.evaluation.id];
        return {
          ...state,
          responseEvaluationsMap: newEvalMapState,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
};
