import { createTheme } from '@material-ui/core/styles';
import registerTheme from '../components/echarts/macarons';
import { ISelectableItem } from '../components/shared/BorderedSelector';

export const primaryColor = '#1b335e';
export const secondaryColor = '#7fa8ef';

const theme = registerTheme();
export const getColor = (idx: number) => theme.theme.color[idx % theme.theme.color.length];

/* exactly one selected */
const DEFAULT_PRIVACY_MENU_OPTIONS = [
  { key: 'public', displayedValue: 'Public', selected: true },
  { key: 'private', displayedValue: 'Private', selected: false },
  { key: 'shared', displayedValue: 'Shared', selected: false },
  { key: 'removed', displayedValue: 'Removed', selected: false },
];

const getDefaultPrivacy = () => {
  let sel = DEFAULT_PRIVACY_MENU_OPTIONS.find((item) => item.selected);
  return sel;
};

export const Date_Modified_Earliest = 'Update Earliest';
export const Date_Modified_Latest = 'Update Latest';
export const Timeline_Earliest = 'Timeline Earliest';
export const Timeline_Latest = 'Timeline Latest';

const filtersConfig = {
  DEFAULT_API_SORT_CRITERIA: Timeline_Earliest, //this matches the sort order used with the API TODO check

  defaultFilterByItems: [
    { key: 'public', displayedValue: 'Public', selected: true },
    { key: 'private', displayedValue: 'Private', selected: true },
    { key: 'shared', displayedValue: 'Shared', selected: true },
    { key: 'removed', displayedValue: 'Removed', selected: true },
  ],
  defaultSortByItems: [
    { key: Date_Modified_Earliest, displayedValue: Date_Modified_Earliest, selected: false },
    { key: Date_Modified_Latest, displayedValue: Date_Modified_Latest, selected: false },
    { key: Timeline_Earliest, displayedValue: Timeline_Earliest, selected: true },
    { key: Timeline_Latest, displayedValue: Timeline_Latest, selected: false },
  ],
};

const commentsConfig = {
  getDefaultPrivacy: getDefaultPrivacy,
  autoscroll: false, // actually this controls autoscrolling in comments views in sync with video time
  defaultPrivacyItems: DEFAULT_PRIVACY_MENU_OPTIONS,
  commentsFilterByItems: filtersConfig.defaultFilterByItems,
  commentsSortByItems: filtersConfig.defaultSortByItems,
};

const DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS = [
  { key: 'draft', displayedValue: 'Draft', selected: true },
  { key: 'public', displayedValue: 'Public', selected: false },
  { key: 'private', displayedValue: 'Private', selected: false },
];

const rubricsConfig = {
  getDefaultPrivacy: () => {
    let sel = DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS.find((item) => item.selected);
    return sel;
  },
  defaultPrivacyItems: DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS,
};

const themeMui = createTheme({
  palette: {
    primary: {
      // main: getColor(0),
      main: primaryColor,
    },
    secondary: {
      // main: getColor(1),
      main: secondaryColor,
    },
  },
});
function getMuiTheme() {
  return themeMui;
}
function getPopupMenuColor() {
  // return getColor(0);
  return primaryColor;
}

const config = {
  filters: filtersConfig,

  comments: commentsConfig,

  rubrics: rubricsConfig,

  dev: {},
  theme: { ...theme, getMuiTheme, getPopupMenuColor },
  report: {
    application: 'Campus Analytics',
    reportTitle: 'Campus Analytics Report',
  },
};
export default config;
export type CONFIG = typeof config;
export const ENV_VALUE_ENABLED = '1';

///////////////
export interface IFilteringCategory {
  allName: string;
  multiItemGroupName: string;
  allMultiItems: string[];
  selectedMultiItems: string[];
}

type FilteringCategories = {
  [category: string]: IFilteringCategory;
};
export interface IFiltersState {
  allCategories: FilteringCategories;
  sortByItems: ISelectableItem[];
}

export const ARRAY_NONE = [];
export const ME_COACH = "Me (I'm the Coach)";
export const ME_PRESENTER = "Me (I'm the Presenter)";
export const STATE_ALL_VIDEOS_NAME = 'All Videos (Excluding Deleted)';
export const STATE_ALL_COACHES_NAME = 'All';
export const STATE_ALL_PRESENTERS_NAME = 'All';

export const CATEGORY_STATE = 'State';
export const CATEGORY_COACHES = 'Coaches';
export const CATEGORY_PRESENTER = 'Presenter';
export const CATEGORY_SORT = 'Sort By';

export const SubmissionId_Latest = 'Date Created';
export const Title_Latest = 'Title';
export const Coach_Latest = 'Coach';
export const Presenter_Latest = 'Presenter';

export const defaultSortByItems = [
  { key: SubmissionId_Latest, displayedValue: SubmissionId_Latest, selected: true },
  { key: Title_Latest, displayedValue: Title_Latest, selected: false },
  { key: Coach_Latest, displayedValue: Coach_Latest, selected: false },
  { key: Presenter_Latest, displayedValue: Presenter_Latest, selected: false },
];
