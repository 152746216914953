import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { canBeEvaluated } from '../../model/ApiTypes';

export default function StudentTaskResponsesTable(props) {
  const { rows } = props;
  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={false}
                    tabIndex={-1}
                    key={row.id}
                    selected={false}
                    // onClick={() => props.handleViewResults(row.fullResponse, row.status)}
                    // style={{ cursor: canBeEvaluated(row.status) ? 'pointer' : 'auto' }}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.student}</TableCell>
                    <TableCell align="left">
                      <b>Q.&nbsp;</b>
                      {row.question}
                    </TableCell>
                    <TableCell align="left">{row.late != null ? `${Math.floor(row.late / 1000 / 60)} minutes late` : ''}</TableCell>
                    <TableCell align="left">
                      {row.exceeded != null ? `${Math.floor(row.exceeded / 1000 / 60)} minutes time exceeded` : ''}
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>

                    {/* <TableCell align="justify">
                      <IconButton
                        aria-label="delete"
                        className="fig-button fig-delete-rubric margin-top0"
                        size="small"
                        onClick={() => props.handleDelete(row.fullResponse, row.id)}
                      >
                        <svg className="fig-icon-delete-rubric-row">
                          <use href={sprites + '#delete-icon'} />
                        </svg>
                      </IconButton>
                    </TableCell> */}

                    <TableCell align="justify">
                      {canBeEvaluated(row.status) && (
                        <Tooltip title="Response Evaluation">
                          <button
                            className="fig-button-link "
                            aria-label="evaluation"
                            onClick={() => props.handleViewResults(row.fullResponse, row.status)}
                          >
                            Evaluation
                          </button>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="justify" key={'reset' + index}>
                      <div>
                        <Tooltip title={`Reset Response`}>
                          <button className="fig-button-link " onClick={() => props.resetResponse(row.fullResponse, row.status)}>
                            Reset
                          </button>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
StudentTaskResponsesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  handleViewResults: PropTypes.func.isRequired,
  // handleDelete: PropTypes.func.isRequired,
  resetResponse: PropTypes.func.isRequired,
};
