import { ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppComponent from './app';
import initStore from './app/config/store';
import config from './app/demo/config/constants';
import './main.css';

//SWITCH comment/uncomment these two lines to use directly the css / use scss
//mainScss.scss should be generated/updated by running npm run sass
import './styles/sass/mainScss.scss';
//import './styles/css/mainScss.css';

const store = initStore();
// registerLocale(store);

// loadIcons();

const rootEl = document.getElementById('root');

const render = (Component) =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <Provider store={store}>
      <div>
        <ThemeProvider theme={config.theme.getMuiTheme()}>
          <Component />
        </ThemeProvider>
      </div>
    </Provider>,
    rootEl
  );

render(AppComponent);
