import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { GRADED, PENDING, STARTED, SUBMITTED } from '../../model/ApiTypes';
import { QuestionSummaryMap } from './ReviewerTaskPanel';

export default function QuestionsSummariesTable(props) {
  const { questionsMap } = props;
  if (questionsMap === null) return null;
  const summaries = Object.values(questionsMap as QuestionSummaryMap);
  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {summaries.map((questionSummary, index) => {
                const qTotal = questionSummary[PENDING] + questionSummary[STARTED] + questionSummary[SUBMITTED] + questionSummary[GRADED];
                return (
                  <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={questionSummary.question.id} selected={false}>
                    <TableCell align="left">
                      <b>{index + 1}.&nbsp;</b>
                      {questionSummary.question.text}
                    </TableCell>
                    <TableCell align="left">
                      <b>{`${PENDING} : ${questionSummary[PENDING]}/${qTotal}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`${STARTED} : ${questionSummary[STARTED]}/${qTotal}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`${SUBMITTED} : ${questionSummary[SUBMITTED]}/${qTotal}`}</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>{`${GRADED} : ${questionSummary[GRADED]}/${qTotal}`}</b>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
