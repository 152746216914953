import Alert from 'react-s-alert';

// Alerts reference
export const errorNotification = (message) => {
	Alert.error(message, {
		position: 'bottom',
		effect: 'jelly',
		timeout: 5000
	});
}

export const successNotification = (message) => {
	Alert.success(message, {
		//position: 'top-right',
		position: 'top',
		effect: 'jelly',
		timeout: 3000
	});
}

export const warningNotification = (message) => {
	Alert.warning(message, {
		position: 'top',
		effect: 'jelly',
		timeout: 5000
	});
}

export const currentUrl = window.location.hostname;
