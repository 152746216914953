import React from 'react';
import { connect } from 'react-redux';
import Dashboard from '../../demo/components/course/Dashboard';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';
interface IProps {
  history: any;
}

class DashboardFrame extends React.Component<IProps, any> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="course" history={this.props.history} />
        <div className={className}>
          <Dashboard history={this.props.history} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {};
};

export default connect(mapStateToProps, {})(DashboardFrame);
