import {
  AUTH_CLEAR,
  AuthClearAction,
  COURSE,
  COURSE_MEMBERSHIP,
  COURSE_MEMBERSHIPS,
  COURSE_MEMBERSHIP_DELETED,
  COURSE_SELECTED,
  COURSE_TASK_REQUESTS,
  CoursesActionTypes,
  OWN_COURSES,
  OWN_ROLES,
  ROLE_SELECTED,
  RubricsActionTypes,
  TASK_QUESTIONS,
  TASK_REQUEST,
  TASK_REQUEST_EVALUATIONS,
  TASK_REQUEST_SELECTED,
  TASK_RESPONSE_SELECTED,
  TASK_RUBRICS,
  TaskRequestAction,
  TaskRequestEvaluationsAction,
  TaskResponseSelectedAction,
} from '../actions/campusApiActions/types';
import { Course, EvaluationBasic, Member, QuestionBasic, RubricBasic, TaskRequestBasic, makeCourse } from '../model/ApiTypes';

const initialMembershipMap = {} as MembershipMap;
const initialTaskRequestsMap = {} as TaskRequestsMap;
const initialCoursesMap = {} as CoursesMap;
const initialTaskRequestEvaluationsMap = {} as TaskRequestEvaluationsMap;

const initialState = {
  membershipMap: initialMembershipMap,
  coursesMap: initialCoursesMap,
  selectedCourseId: null as number,
  selectedRole: null as string,
  taskRequestsMap: initialTaskRequestsMap,
  selectedTaskRequestId: null as number,
  taskRequestEvaluationsMap: initialTaskRequestEvaluationsMap,
  taskRubric: null as RubricBasic,
  courseMembershipMap: initialMembershipMap, //for a specific course, but membership info has no course info in it
  taskQuestions: null as QuestionBasic[], //todo FFS don;t need Map crtly as we don't update/delete it
  ownCrtCourseRoles: null as string[],
};

export type CoursesState = Readonly<typeof initialState>;
export type MembershipMap = { [id: string]: Member };
export type TaskRequestsMap = { [id: string]: TaskRequestBasic };
export type CoursesMap = { [id: string]: Course };
export type TaskRequestEvaluationsMap = { [id: string]: EvaluationBasic }; //evaluation ID to Evaluation
export type TaskResponseToEvaluationMap = { [id: string]: EvaluationBasic[] }; //response ID to Evaluations
// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state: CoursesState = initialState,
  action:
    | CoursesActionTypes
    | AuthClearAction
    | TaskRequestAction
    | TaskRequestEvaluationsAction
    | TaskResponseSelectedAction
    | RubricsActionTypes
): CoursesState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return { ...initialState };
    }
    case OWN_COURSES: {
      const mapMb = {};
      const mapCourses = {};
      action.payload.memberships.forEach((member) => {
        if (member.attributes.course && member.attributes.course.data && member.attributes.course.data.id) {
          mapMb[member.id] = member;
          mapCourses[member.attributes.course.data.id] = member.attributes.course;
        } else {
          alert(`No course ID in the membership ${member.id}, ignoring this membership ?!`);
        }
      });
      return {
        ...state,
        membershipMap: mapMb,
        coursesMap: mapCourses,
        courseMembershipMap: initialMembershipMap,
      };
    }
    case OWN_ROLES: {
      return {
        ...state,
        ownCrtCourseRoles: action.payload.memberships.map((member) => member.attributes.role),
      };
    }
    case COURSE_MEMBERSHIP: {
      return {
        ...state,
        courseMembershipMap: { ...state.courseMembershipMap, [action.payload.member.id]: action.payload.member },
      };
    }
    case COURSE_MEMBERSHIP_DELETED: {
      let courseMembershipMapChanged = false;
      let membershipMapChanged = false;
      const newmembershipMap = { ...state.membershipMap };
      const newMap = { ...state.courseMembershipMap };

      if (state.membershipMap && state.membershipMap[action.payload.member.id]) {
        delete newmembershipMap[action.payload.member.id];
        membershipMapChanged = true;
      }
      if (state.courseMembershipMap && state.courseMembershipMap[action.payload.member.id]) {
        delete newMap[action.payload.member.id];
        courseMembershipMapChanged = true;
      }

      if (!membershipMapChanged && !courseMembershipMapChanged) {
        return state;
      } else {
        return {
          ...state,
          membershipMap: membershipMapChanged ? newmembershipMap : state.membershipMap,
          courseMembershipMap: courseMembershipMapChanged ? newMap : state.courseMembershipMap,
        };
      }
    }
    case COURSE_MEMBERSHIPS: {
      const mapMb = {};
      action.payload.memberships.forEach((member) => {
        mapMb[member.id] = member;
      });
      return {
        ...state,
        courseMembershipMap: mapMb,
      };
    }
    case COURSE_SELECTED: {
      return {
        ...state,
        selectedCourseId: action.payload.id,
        taskRequestsMap: initialTaskRequestsMap,
        selectedTaskRequestId: null,
        taskRequestEvaluationsMap: initialTaskRequestEvaluationsMap,
        taskRubric: null,
        selectedRole: null,
        courseMembershipMap: initialMembershipMap,
        taskQuestions: null,
        ownCrtCourseRoles: null,
      };
    }
    case ROLE_SELECTED: {
      return {
        ...state,
        selectedRole: action.payload.role, //TODO FFS might better be stored in local CoursePanel  state then in redux store
        courseMembershipMap: initialMembershipMap,
      };
    }

    case COURSE: //FFS should membership be read again ?!
      return {
        ...state,
        coursesMap: { ...state.coursesMap, [action.payload.course.data.id]: action.payload.course },
      };
    case COURSE_TASK_REQUESTS: {
      const map: TaskRequestsMap = {};
      action.payload.requests.forEach((request) => {
        map[request.id] = request;
      });
      return {
        ...state,
        taskRequestsMap: map,
      };
    }
    case TASK_REQUEST: {
      return {
        ...state,
        taskRequestsMap: { ...state.taskRequestsMap, [action.payload.taskRequest.id]: action.payload.taskRequest },
        coursesMap: action.payload.taskRequest.course
          ? { ...state.coursesMap, [action.payload.taskRequest.course.id]: makeCourse(action.payload.taskRequest.course) }
          : state.coursesMap,
      };
    }
    case TASK_REQUEST_EVALUATIONS: {
      const map: TaskRequestEvaluationsMap = {};
      action.payload.evaluations.forEach((evaluation) => {
        map[evaluation.id] = evaluation;
      });

      return {
        ...state,
        //TODO FFS MAKE SURE THEY ARE FOR CRT SELECTED TASKREQUESTID
        taskRequestEvaluationsMap: map,
      };
    }
    case TASK_REQUEST_SELECTED: {
      return {
        ...state,
        selectedTaskRequestId: action.payload.id,
        taskRequestEvaluationsMap: initialTaskRequestEvaluationsMap,
        taskRubric: null,
        taskQuestions: null,
      };
    }
    case TASK_RESPONSE_SELECTED: {
      return {
        ...state,
        taskRequestEvaluationsMap: initialTaskRequestEvaluationsMap,
        //TODO FFS DISCARD/INIT MEMBERSHIP COURSE TASKREQUESTS too WHEN WE WILL RETRIEVE ALL INFO FROM PAGE< WITHOUT RELYING ON CONTEXT/PREVIOUS PAGES
        // membershipMap: initialMembershipMap,
        // coursesMap: initialCoursesMap,
        // taskRequestsMap: initialTaskRequestsMap,
        taskRubric: null,
        taskQuestions: null,
      };
    }
    case TASK_RUBRICS: {
      return {
        ...state,
        taskRubric: action.payload.rubrics.length > 0 ? action.payload.rubrics[0] : null,
      };
    }
    case TASK_QUESTIONS: {
      return {
        ...state,
        taskQuestions: action.payload.taskQuestions.length > 0 ? action.payload.taskQuestions : null,
      };
    }
    default:
      return state;
  }
};
