import { CHANGE_SETTINGS } from '../actions/types';

const INITIAL_STATE = {
  users: [],
  coaches: [],
  admins: [],
};

export type CampusUsersProfileState = Readonly<typeof INITIAL_STATE>;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: CampusUsersProfileState = INITIAL_STATE, action: any): CampusUsersProfileState => {
  switch (action.type) {
    case CHANGE_SETTINGS: {
      return state;
    }
    default:
      return state;
  }
};
