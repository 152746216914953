import { CLEAR_ALERT, SET_ALERT, UPDATE_WINDOW_HEIGHT as WINDOW_HEIGHT_UPDATED, UPDATE_PLAYER_HEIGHT as PLAYER_HEIGHT_UPDATED, } from '../types';




export const playerHeightUpdated = (playerHeight) => async (dispatch) => {
  console.log('playerHeightUpdated: ', playerHeight);
  dispatch({
    type: PLAYER_HEIGHT_UPDATED,
    payload: {
      playerHeight,
    },
  });
  return Promise.resolve();
};

export const windowHeightUpdated = (windowHeight) => async (dispatch) => {
  console.log('windowHeightUpdated: ', windowHeight);
  dispatch({
    type: WINDOW_HEIGHT_UPDATED,
    payload: {
      windowHeight: windowHeight,
    },
  });
  return Promise.resolve();
};

export const setGlobalAlert = (title: string) => async (dispatch) => {
  console.log('setGlobalAlert: ', title);
  dispatch({
    type: SET_ALERT,
    payload: {
      title,
    },
  });
  return Promise.resolve();
};
export const clearGlobalAlert = () => async (dispatch) => {
  console.log('clearGlobalAlert: ');
  dispatch({
    type: CLEAR_ALERT,
    payload: {},
  });
  return Promise.resolve();
};
