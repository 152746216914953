import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import { canViewCourse } from '../../model/ApiTypes';

export default function CoursesTable(props) {
  const { rows } = props;

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={row.courseId + row.role} selected={false}>
                    <TableCell align="left" key="semester">
                      {row.semester}
                    </TableCell>
                    <TableCell align="left" key="code">
                      {row.code}
                    </TableCell>
                    <TableCell align="left" key="name">
                      {row.name}
                    </TableCell>
                    <TableCell align="left" key="roles">
                      {row.role.join(', ')}
                    </TableCell>
                    <TableCell align="justify" key="toCourse">
                      {canViewCourse(row.role) ? (
                        <Tooltip title="View Course Information">
                          <button
                            className="fig-button-link "
                            aria-label="view course"
                            onClick={() => props.handleViewCourse(row.courseId, row.role)}
                          >
                            View Course
                          </button>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
CoursesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  handleViewCourse: PropTypes.func.isRequired,
  onDeleteMembership: PropTypes.func.isRequired,
};
