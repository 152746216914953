import { CLEAR_ALERT, SET_ALERT, UPDATE_WINDOW_HEIGHT } from '../actions/types';

const INITIAL_STATE = {
  //TODO FFS what size initially ? match it to default layout
  windowHeight: window.innerHeight,

  //percentage
  alertTitle: null,
};

export type CampusUtilsState = Readonly<typeof INITIAL_STATE>;

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default (state: CampusUtilsState = INITIAL_STATE, action: any): CampusUtilsState => {
  switch (action.type) {
    case UPDATE_WINDOW_HEIGHT: {
      //return { ...state, windowHeight: action.payload.windowHeight };
      return { ...state, windowHeight: window.innerHeight };
    }
    case SET_ALERT: {
      return { ...state, alertTitle: action.payload.title };
    }
    case CLEAR_ALERT: {
      return { ...state, alertTitle: null };
    }
    default:
      return state;
  }
};
