import { Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import PersonOutline from '@material-ui/icons/PersonOutline';
import * as jose from 'jose';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getAuthedHomePath, getUnauthedHomePath } from '../../../../app';
import { setGlobalAlert } from '../../../actions/utils';
import { getWhoAmI, login } from '../../../demo/actions/campusApiActions';
import { API_ROLE_COACH, API_ROLE_USER } from '../../../demo/model/ApiTypes';
import { IRootState } from '../../../shared/reducers';

interface IState {
  username: string;
  password: string;
  userNameRequired: boolean;
  passwordRequired: boolean;
}

class LoginContainer extends Component<PropsFromRedux & { history: any }, IState> {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      userNameRequired: false,
      passwordRequired: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('login container componentDidUpdate : prev/crt props ', prevProps, this.props);
  }
  private checkUserName(): boolean {
    this.setState({ userNameRequired: this.state.username === '' });
    return !(this.state.username === '');
  }

  private checkPassword(): boolean {
    this.setState({ passwordRequired: this.state.password === '' });
    return !(this.state.password === '');
  }

  getHomeFor = (userRole) => {
    switch (userRole) {
      case 'ROLE_ADMIN':
      case 'ROLE_USER':
      case 'ROLE_COACH':
      case API_ROLE_USER:
      case API_ROLE_COACH: {
        return getAuthedHomePath();
      }
      default:
        return getUnauthedHomePath();
    }
  };

  onSuccesfullLogin = () => {
    this.props.getWhoAmI(this.props.token);

    this.props.history.push(this.getHomeFor(this.props.userRole));

    const claims = jose.decodeJwt(this.props.token);

    const millis = claims.exp * 1000 - new Date().getTime() + 1;
    // MDN:  Maximum delay value
    // Browsers store the delay as a 32-bit signed integer internally. This causes an integer overflow when using delays larger than 2,147,483,647 ms (about 24.8 days), resulting in the timeout being executed immediately.
    //crtly token expires after 1 month and that exceeds maximum delay correctly handled by browsers
    const wakeupAfter = millis > MAX_TIMER_DELAY ? MAX_TIMER_DELAY : millis;
    //console.log('1claims:', claims, 'exp: ', new Date(claims.exp * 1000), 'millis to then ', millis, ', setting timer after ', wakeupAfter);

    setTimeout(() => {
      const claims = jose.decodeJwt(this.props.token);
      const millis2 = claims.exp * 1000 - new Date().getTime() + 1;
      //console.log('T1 claims:', claims, 'exp: ', new Date(claims.exp * 1000), 'millis to then ', millis2);

      if (millis2 < 0) {
        this.redirectToLogin();
      } else {
        setTimeout(
          () => {
            //console.log('T2 ');
            this.redirectToLogin();
          },
          millis2 > MAX_TIMER_DELAY ? MAX_TIMER_DELAY : millis2
        );
      }
    }, wakeupAfter);
  };

  redirectToLogin = () => {
    this.props.setGlobalAlert(EXPIRED_MESSAGE);
    this.props.history.push(getUnauthedHomePath());
  };
  private onFormSubmit(): void {
    if (this.checkUserName() === false || this.checkPassword() === false) {
      return;
    }
    this.props
      .login({
        identifier: this.state.username,
        password: this.state.password,
      })
      .then(
        () => this.onSuccesfullLogin(),
        (err) => {
          // console.log('login container login err ', err);
          // window.location.reload();
        }
      );
  }

  onEnterKey(e: any) {
    if (e.which === 13) {
      this.onFormSubmit();
    }
  }

  private renderLoginFailed() {
    if (this.props.loginFailed) {
      return (
        <>
          <div className="fig-login-failed">
            <span>Login failed!</span>
            <span>Can't access your account?</span>
          </div>
          <div className="fig-login-reset-resend">
            <Link onClick={this.onForgotPassword}>Reset your password</Link>
            <Link onClick={this.onAskRegistrationConfirmation}>Re-send registration confirmation code</Link>
          </div>
        </>
      );
    }
    return <div />;
  }

  onAskRegistrationConfirmation = () => {
    this.props.history.push(`/requestconfirmation?email=${this.state.username}`);
  };
  onForgotPassword = () => {
    this.props.history.push(`/forgotpassword?email=${this.state.username}`);
  };
  onCreateAccount = () => {
    this.props.history.push(`/register?email=${this.state.username}`);
  };

  render() {
    return (
      <div className="login-wrapper">
        <Paper>
          <div className="login-wrapper__header">
            <Avatar className="header-avatar" color="secondary">
              <PersonOutline color="secondary" />
            </Avatar>
            <div className="header-container">
              <p className="no-margin">Welcome to</p>
              <h1 className="no-margin">Campus </h1>
            </div>
            <div className="header-container" style={{ color: 'inherit', width: 50 }} />
          </div>
          <form className="login-wrapper__form">
            <TextField
              className="w-100"
              id="email"
              value={this.state.username}
              onChange={(e) =>
                this.setState({
                  username: e.target.value,
                  userNameRequired: !e.target.value,
                })
              }
              onKeyPress={(e) => this.onEnterKey(e)}
              type="text"
              //label="Username"
              variant="outlined"
              margin="normal"
              // className={styles.textField}
              required
              error={this.state.userNameRequired}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'USERNAME'}
            />
            <TextField
              className="w-100"
              id="password"
              value={this.state.password}
              onChange={(e) =>
                this.setState({
                  password: e.target.value,
                  passwordRequired: !e.target.value,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={'PASSWORD'}
              onKeyPress={(e) => this.onEnterKey(e)}
              type="password"
              //label="Password"
              margin="normal"
              variant="outlined"
              required
              error={this.state.passwordRequired}
            />
            <div className="login-wrapper__buttons">
              <Button className="fig-button ghost-to-dark-blue" onClick={() => this.onFormSubmit()}>
                Log In
              </Button>
              {/* <Link onClick={this.onCreateAccount}>Create account</Link> */}
              <Link onClick={this.onForgotPassword}>Reset Password</Link>
            </div>
          </form>
          {this.renderLoginFailed()}
        </Paper>
      </div>
    );
  }
}

const MAX_TIMER_DELAY = 2147483647;
//const MAX_TIMER_DELAY = 21474; USE THIS FOR TESTING
const EXPIRED_MESSAGE = 'Authorization expired. Please log in again.';

const mapStateToProps = (state: IRootState) => {
  // console.log('login container map state, ', state);
  return {
    loginFailed: state.authentication.loginError,
    userRole: state.authentication.user && state.authentication.user.role ? state.authentication.user.role.name : null, //FFS OLD FIGARO STYLE
    token: state.authentication.token,
  };
};

const mapDispatchToProps = { login, setGlobalAlert, getWhoAmI };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(LoginContainer);
