import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { CONTINUE, INCOMPLETE, NOT_STARTED, SUBMITTED } from '../../model/ApiTypes';
import { formatCampusLongDate } from '../../utils/utils';

const formatState = (row) => {
  switch (row.state) {
    case NOT_STARTED: //time has not come
      return row.taskOptions.taskavaiability === false ? 'Task Closed' : 'Starting Soon';
    case INCOMPLETE: //didn't click Start
      return row.taskOptions.taskavaiability === false ? 'Task Closed - Not Started' : 'Start Task';
    case CONTINUE:
      return row.taskOptions.taskavaiability === false ? 'Task Closed - Incomplete' : 'Continue Task';
    case SUBMITTED:
      return !row.taskOptions.studentresultsaccess ? 'Submitted' : 'Graded';
  }
  return 'UNDEFINED';
};

export default function TasksResponsesTable(props) {
  const { rows } = props;

  const makeStateCell = (row) => {
    if (NOT_STARTED === row.state || (SUBMITTED === row.state && !row.taskOptions.studentresultsaccess)) {
      return <span className="fig-button-link-disabled ">{formatState(row)}</span>;
    } else {
      return (
        <Tooltip title={`Go To Response ${SUBMITTED === row.state ? 'Evaluation' : ''}`}>
          <button className="fig-button-link " onClick={() => props.handleRespond(row.id)}>
            {formatState(row)}
          </button>
        </Tooltip>
      );
    }
  };

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table" stickyHeader>
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `link-${index}`;

                return (
                  <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={row.id} selected={false}>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.course}</TableCell>
                    <TableCell align="left">
                      <div>{row.name}</div>
                    </TableCell>

                    <TableCell align="left">starts: {formatCampusLongDate(new Date(row.starts_at))}</TableCell>
                    <TableCell align="left">ends: {formatCampusLongDate(new Date(row.ends_at))}</TableCell>
                    <TableCell align="justify" component="th" key={labelId + 'eval'} scope="row">
                      <div>{makeStateCell(row)} </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
TasksResponsesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  handleRespond: PropTypes.func.isRequired,
};
