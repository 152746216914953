import Button from '@material-ui/core/Button';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setGlobalAlert } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  assignTaskQuestions2Students,
  courseSelected,
  getCourseTaskRequests,
  getResponsesForTaskRequest,
  getRubricForTask,
  getTaskQuestions,
  getTaskRequestEvaluations,
  placeBankQuestions2Task,
  resetTask,
  resetTaskResponse,
  setStudentAccessToTaskGrades,
  setTaskAvailability,
  taskRequestSelected,
  updateRubric,
  updateTaskRequest,
} from '../../actions/campusApiActions';
import {
  CourseBasic,
  EvaluationBasic,
  GRADED,
  PENDING,
  QuestionBasic,
  STARTED,
  SUBMITTED,
  TaskRequestBasic,
  TaskRequestBasicWithQuestionBankAndRubric,
  TaskResponseBasic,
  getResponseStateEvalAsReviewer,
  makeCourseBasic,
} from '../../model/ApiTypes';
import { TaskResponseToEvaluationMap } from '../../reducers/coursesReducer';
import { formatCampusLongDate, seconds2mss, toDateTimeSSLocalInput } from '../../utils/utils';
import TaskStatusChart from '../echarts/TaskStatusChart';
import QuestionsSummariesTable from './QuestionsSummariesTable';
import StudentTaskResponsesTable from './StudentTaskResponsesTable';
import TaskForm from './TaskForm';

interface IProps extends PropsFromRedux {
  history: any;
  match: any;
}
interface IState {
  viewMode: ViewMode;
  crtPie: any;
}
enum ViewMode {
  EditTask,
  ViewTaskStatistics,
}

interface EvalInfo {
  email: string;
  rubric: number[];
  question: string;
}

class ReviewerTaskPanel extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.getInitialState();
  }
  getInitialState = () => {
    return {
      viewMode: ViewMode.ViewTaskStatistics,
      crtPie: null,
    };
  };

  componentDidMount = () => {
    this.load(this.props.match.params.id);
  };
  componentWillUnmount = () => {
    console.log('ReviewerTaskPanel will unmount ', this.props);
    this.cleanupOnExitTask();
  };

  cleanupOnExitTask = () => {};

  static EMPTY_RESPONSES: TaskResponseBasic[] = [];
  static EMPTY_EVALUATIONS: EvaluationBasic[] = [];
  componentDidUpdate(prevProps: IProps, prevState) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      console.log('ReviewerTaskPanel will load another task ', id);
      this.cleanupOnExitTask();
      this.load(id);
    }
    if (this.pieChart !== this.state.crtPie) {
      this.setState({ crtPie: this.pieChart }); //to trigger re-render "legend div" with correct pie colors
    }
  }

  load(id) {
    //UPDATED: this taskRequestSelected also get task info with course info
    this.props.taskRequestSelected(id, this.props.authentication.token).then(
      (ok) => {
        this.props.getResponsesForTaskRequest(id, this.props.authentication.token);
        this.props.getTaskRequestEvaluations(id, this.props.authentication.token);
        this.props.getRubricForTask(id, this.props.authentication.token);
        this.props.getTaskQuestions(id, this.props.authentication.token);
      },
      (err) => {}
    );
  }

  refreshResponses(id) {
    this.props.getResponsesForTaskRequest(id, this.props.authentication.token);
    this.props.getTaskRequestEvaluations(id, this.props.authentication.token);
  }

  static EMPTY_STUDENT_ROWS = [];
  render() {
    console.log('ReviewerTaskPanel render ', this.props);
    const course = this.props.currentCourseFromMemory ? this.props.currentCourseFromMemory : this.props.currentCourseFromResponses;
    // if (course == null || (this.props.currentTaskRequest == null && this.props.currentTaskRequestFromResponses == null)) return null;
    if (this.props.currentTaskRequestFromMemory == null && this.props.currentTaskRequestFromResponses == null) return null;
    const reqRows = this.props.questionsData ? this.props.questionsData.studentResponses : ReviewerTaskPanel.EMPTY_STUDENT_ROWS;
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;

    let usefullHeight = window.innerHeight - 125;

    return (
      <div className="row no-gutters fig-rubrics">
        <div className="col-12">
          <div className="fig-rubrics-header">
            <span>{course ? `${course.code} - ${course.name} (${course.semester})` : ''}</span>
          </div>
          <div className="row">
            <div className="col-2" style={{ height: usefullHeight, overflowY: 'auto' }}>
              {this.renderButtonsMenu(currentTaskRequest, course)}
            </div>
            <div className="col-10 " style={{ height: usefullHeight, overflowY: 'auto' }}>
              {this.state.viewMode === ViewMode.ViewTaskStatistics ? this.renderTaskStatistics(course, reqRows, currentTaskRequest) : null}
              {this.state.viewMode === ViewMode.EditTask ? this.renderTaskForm(currentTaskRequest) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderTaskForm(currentTaskRequest: TaskRequestBasic) {
    return (
      <TaskForm
        addTask={null}
        saveTask={this.updateTask}
        currentTaskRequest={currentTaskRequest}
        questions={this.props.taskQuestions}
        rubric={this.props.taskRubric}
        windowHeight={this.props.windowHeight}
        setGlobalAlert={this.props.setGlobalAlert}
      />
    );
  }
  pieChart: TaskStatusChart = null;
  renderTaskStatistics(course: CourseBasic, reqRows: any[], currentTaskRequest: TaskRequestBasic) {
    return (
      <div>
        <div className="row fig-generic-pane-right-side" key="TaskHeader">
          <div
            className="col-5 fig-generic-pane-right-side__title"
            style={{
              paddingLeft: 0,
              marginTop: 5,
              marginBottom: 5,
              fontWeight: 700,
            }}
          >
            <div className="row w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{currentTaskRequest.type}</span>
              <span>{course ? course.code : ''}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div className="row w-100">{currentTaskRequest.name}</div>
          </div>
          <div className="col-3  fig-generic-pane-right-side__title no-padding" style={{ marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            <div className="row w-100" style={{ display: 'flex', justifyContent: 'flex-end' /*, minWidth: '30%', maxWidth: '50%' */ }}>
              Start: {formatCampusLongDate(currentTaskRequest.starttime)}
            </div>
            <div className="row w-100" style={{ display: 'flex', justifyContent: 'flex-end' /*, minWidth: '30%', maxWidth: '50%' */ }}>
              Deadline: {formatCampusLongDate(currentTaskRequest.endtime)}
            </div>
            <div className="row w-100" style={{ display: 'flex', justifyContent: 'flex-end' /*, minWidth: '30%', maxWidth: '50%' */ }}>
              <div className="row w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Attempts: {currentTaskRequest.options.attempts}</span>
                <span>Video time: {seconds2mss(currentTaskRequest.options.attempttime)}</span>
                <span>Total time allowed: {currentTaskRequest.options.totaltime} minutes</span>
              </div>
            </div>
          </div>
          <div className="col-2 fig-generic-pane-right-side__title no-padding  " style={{ marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
            {this.props.questionsData && this.props.questionsData.studentResponses.length && this.props.questionsData.taskSummary ? (
              <TaskStatusChart
                ref={(e) => {
                  this.pieChart = e;
                }}
                statusRates={this.props.questionsData.taskSummary}
                key="statusChart"
                height={this.props.windowHeight}
              />
            ) : null}
          </div>

          <div
            className="col-2  fig-generic-pane-right-side__title no-padding  "
            key="legend"
            // style={{ marginTop: 'auto', marginBottom: 'auto', fontWeight: 700 }}
            style={{ marginTop: '5px', marginBottom: '5px', fontWeight: 700 }}
          >
            {this.props.questionsData ? (
              <>
                <div
                  className="row w-100"
                  style={{
                    display: 'flex',
                    minWidth: '30%',
                    maxWidth: '100%',
                  }}
                >
                  <span>{PENDING}: &nbsp;</span>
                  <span
                    style={{
                      color: this.pieChart ? this.pieChart.getColorForStatus(PENDING) : 'black',
                    }}
                  >
                    {this.props.questionsData.taskSummary[PENDING]} / {this.props.questionsData.studentResponses.length}
                  </span>
                </div>
                <div
                  className="row w-100"
                  style={{
                    display: 'flex',
                    minWidth: '30%',
                    maxWidth: '100%',
                  }}
                >
                  <span>{STARTED}: &nbsp;</span>
                  <span
                    style={{
                      color: this.pieChart ? this.pieChart.getColorForStatus(STARTED) : 'black',
                    }}
                  >
                    {this.props.questionsData.taskSummary[STARTED]} / {this.props.questionsData.studentResponses.length}
                  </span>
                </div>
                <div
                  className="row w-100"
                  style={{
                    display: 'flex',
                    minWidth: '30%',
                    maxWidth: '100%',
                  }}
                >
                  <span>{SUBMITTED}: &nbsp;</span>
                  <span
                    style={{
                      color: this.pieChart ? this.pieChart.getColorForStatus(SUBMITTED) : 'black',
                    }}
                  >
                    {this.props.questionsData.taskSummary[SUBMITTED]} / {this.props.questionsData.studentResponses.length}
                  </span>
                </div>
                <div
                  className="row w-100"
                  style={{
                    display: 'flex',
                    minWidth: '30%',
                    maxWidth: '100%',
                  }}
                >
                  <span>{GRADED}: &nbsp;</span>
                  <span
                    style={{
                      color: this.pieChart ? this.pieChart.getColorForStatus(GRADED) : 'black',
                    }}
                  >
                    {/* {GRADED}: {this.props.questionsData.taskSummary[GRADED]} / {this.props.questionsData.studentResponses.length} */}
                    {/* {GRADED}: {this.props.evaluationsCnt} / {this.props.questionsData.studentResponses.length} TODO FFS */}
                    {getTotalQuestionsGradedCount(this.props.questionsData.questionsSummaries)} /{' '}
                    {this.props.questionsData.studentResponses.length}
                  </span>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <div className="fig-generic-pane-right-side" key="Questions">
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '30%', maxWidth: '50%' }}>
                <span>Summary</span>
              </div>
            </div>
          </div>
          <div className="fig-rubrics-table-holder" key="tasks">
            <QuestionsSummariesTable questionsMap={this.props.questionsData.questionsSummaries} />
          </div>
        </div>
        <div className="fig-generic-pane-right-side" key="Students">
          <div className="fig-generic-pane-right-side__title" style={{ fontWeight: 400 }} key="header2">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5, marginBottom: 5, fontWeight: 700 }}>
              <div className="row" style={{ display: 'flex', justifyContent: 'space-between', minWidth: '30%', maxWidth: '50%' }}>
                <span>Students</span>
              </div>
            </div>
          </div>
          <div className="fig-rubrics-table-holder" key="tasks">
            <StudentTaskResponsesTable
              rows={reqRows}
              handleViewResults={this.gotoResponse} /*handleDelete={this.deleteResponse}*/
              resetResponse={this.resetResponse}
            ></StudentTaskResponsesTable>
          </div>
        </div>
      </div>
    );
  }

  renderButtonsMenu(currentTaskRequest: TaskRequestBasic, course: CourseBasic) {
    return (
      <>
        <div className="fig-generic-pane-right-side">
          <Button className="fig-button in-modal-dialog w-100" variant="outlined" onClick={this.onDownloadGrades}>
            Download Grades
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          <Button className={'fig-button in-modal-dialog w-100 '} variant="outlined" onClick={this.onToggleReleaseGrades}>
            {currentTaskRequest.options.studentresultsaccess ? 'Undo ' : ''}Release Grades
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          <Button className="fig-button in-modal-dialog w-100" variant="outlined" onClick={this.onToggleTaskAvailability}>
            {currentTaskRequest.options.taskavaiability === undefined || currentTaskRequest.options.taskavaiability ? 'Close ' : 'Open '}
            Task
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          <Button className="fig-button in-modal-dialog w-100" variant="outlined" onClick={this.onAssignQuestions2Students}>
            Assign Questions
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          <Button
            className={'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.EditTask ? ' fig-button__grey' : '')}
            variant="outlined"
            onClick={this.onEditTask}
            /*disabled={!this.props.taskRubric || !this.props.taskQuestions}*/
          >
            Task Settings
            {/* todo ffs for instructor roles only */}
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          <Button
            className={
              'fig-button in-modal-dialog w-100' + (this.state.viewMode === ViewMode.ViewTaskStatistics ? ' fig-button__grey' : '')
            }
            variant="outlined"
            onClick={this.onViewTaskStats}
          >
            Task Statistics
          </Button>
        </div>
        <div className="fig-generic-pane-right-side">
          {/* <button
            className="fig-button-link "
            onClick={() => {
              if (course) this.props.history.push(`/courses/${course.id}`);
            }}
          >
            Course
          </button> */}
          <Button
            className={'fig-button in-modal-dialog w-100'}
            variant="outlined"
            onClick={() => {
              if (course) this.props.history.push(`/courses/${course.id}`);
            }}
          >
            Course
          </Button>
        </div>
      </>
    );
  }

  onDownloadGrades = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;

    const course = this.props.currentCourseFromMemory ? this.props.currentCourseFromMemory : this.props.currentCourseFromResponses;

    this.props.getRubricForTask(currentTaskRequest.id, this.props.authentication.token).then((ok) =>
      this.props.getTaskRequestEvaluations(currentTaskRequest.id, this.props.authentication.token).then(
        (evaluations) => {
          this.downloadCSV(
            this.toCsv(
              evaluations.map((evaluation) => ({
                email: evaluation.response.user.email,
                rubric: evaluation.rubric,
                question: evaluation.response.question.text,
              }))
            ),
            currentTaskRequest.id,
            course.id
          );
        },
        (err) => {}
      )
    );
  };

  downloadCSV = (data: string, taskId: number, courseId: number) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `grades___course${courseId}___task${taskId}___${toDateTimeSSLocalInput(new Date())}.csv`);
    a.click();
  };

  toCsv = (evals: EvalInfo[]) => {
    const header =
      this.props.taskRubric.questions.reduce(
        (accumulator, question, idx) => accumulator + `"rubric_${1 + idx}_grade",`,
        `"student_email","student_question",`
      ) + '"final_grade"';

    const csvRows = evals.map(
      (evaluation) =>
        `"${evaluation.email}"` +
        ',' +
        `"${evaluation.question.replace(`"`, `""`)}"` +
        ',' +
        Object.values(evaluation.rubric)
          .map((value) => `"${value}"`)
          .join(',') +
        ',' +
        `"${this.getAverageGrade(evaluation.rubric)}"`
    );
    return [header, csvRows.join('\n')].join('\n');
  };

  getAverageGrade = (rubric: number[]) => {
    if (rubric && this.props.taskRubric) {
      const res =
        this.props.taskRubric.questions.reduce((accumulator, question, idx) => accumulator + question.weight * rubric[idx], 0) /
        this.getScore();
      const hasNull = rubric.indexOf(null) != -1;
      return hasNull || isNaN(res) || res === 0 /*FFS 0 should be allowed but cases*/ ? null : res.toFixed(2);
    } else {
      return null;
    }
  };
  getScore = () => {
    if (this.props.taskRubric) {
      return this.props.taskRubric.questions[0].score;
    } else {
      return null;
    }
  };

  onToggleReleaseGrades = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;
    this.props
      .setStudentAccessToTaskGrades(
        currentTaskRequest.id,
        currentTaskRequest.options.studentresultsaccess ? false : true,
        this.props.authentication.token
      )
      .then(
        (ok) => {
          // successNotification('Toggled student access to results!');
        },
        (err) => {
          // errorNotification('Failed to toggle student access to results!');
        }
      );
  };
  onToggleTaskAvailability = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;
    this.props
      .setTaskAvailability(
        currentTaskRequest.id,
        currentTaskRequest.options.taskavaiability === undefined || currentTaskRequest.options.taskavaiability ? false : true,
        this.props.authentication.token
      )
      .then(
        (ok) => {
          // successNotification('Toggled task availability!');
        },
        (err) => {
          // errorNotification('Failed to toggle task availability!');
        }
      );
  };
  onEditTask = () => {
    this.setState({
      viewMode: ViewMode.EditTask,
    });
  };

  onViewTaskStats = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;
    this.load(currentTaskRequest.id);
    this.setState({
      viewMode: ViewMode.ViewTaskStatistics,
    });
  };

  onAssignQuestions2Students = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;
    this.props.assignTaskQuestions2Students(currentTaskRequest.id, this.props.authentication.token).then(
      (ok) => {
        // successNotification('Assigned questions to students !');
        this.load(currentTaskRequest.id);
      },
      (err) => {
        // errorNotification('Failed to assign questions to students!');
        this.load(currentTaskRequest.id);
      }
    );
  };
  onResetTask = () => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;

    this.props.resetTask(currentTaskRequest.id, this.props.authentication.token).then(
      (ok) => {
        this.load(currentTaskRequest.id);
      },
      (err) => {
        this.load(currentTaskRequest.id);
      }
    );
  };
  updateTask = (taskRequest: TaskRequestBasicWithQuestionBankAndRubric) => {
    //console.log('*******************taskRequest to be updated : ', taskRequest);
    this.props.updateTaskRequest(taskRequest, this.props.authentication.token).then(
      (uTaskRequest) => {
        this.props.updateRubric(taskRequest.rubricID, taskRequest.rubric, this.props.authentication.token).then(
          (baseRubric) => {
            this.props.replaceBankQuestions2Task(taskRequest.questionBank, taskRequest.id, this.props.authentication.token).then(
              (taskId) => {
                this.load(taskRequest.id); //reload all, better than updating state because evaluation or other stats might be affected
                this.onViewTaskStats();
              },
              (err) => {
                this.load(taskRequest.id); //reload all, better than updating state because evaluation or other stats might be affected
                this.onViewTaskStats();
              }
            );
          },
          (err) => {
            console.error('error updating task rubric', err);
            this.load(taskRequest.id); //reload all, better than updating state because evaluation or other stats might be affected
            this.onViewTaskStats();
          }
        );
      },
      (err) => {
        console.error('error updating task', err);
        this.load(taskRequest.id); //reload all, better than updating state because evaluation or other stats might be affected
        this.onViewTaskStats();
      }
    );
  };

  gotoResponse = (fullResponse, status) => {
    if (status === PENDING || status === STARTED) {
      //nothing to evaluate
    } else {
      this.props.history.push(`/taskeval/${fullResponse.id}`);
    }
  };

  resetResponse = (fullResponse, status) => {
    const currentTaskRequest = this.props.currentTaskRequestFromMemory
      ? this.props.currentTaskRequestFromMemory
      : this.props.currentTaskRequestFromResponses;

    // if (status !== PENDING TODO ASK WING
    this.props.resetTaskResponse(fullResponse.id, this.props.authentication.token).then(
      //probably status of this response changed and  evaluations too, this will affect statistics etc => better refresh all
      //pie chart should refresh also !!!!
      (ok) => {
        this.refreshResponses(currentTaskRequest.id);
      },
      (err) => {
        this.refreshResponses(currentTaskRequest.id);
      }
    );
  };

  deleteResponse = (fullResponse, id) => {};
}

const selectorCrtCourse = (state: IRootState) =>
  state.courses.selectedCourseId && state.courses.coursesMap[state.courses.selectedCourseId]
    ? makeCourseBasic(state.courses.coursesMap[state.courses.selectedCourseId])
    : null;

const selectorCrtTaskRequest = (state: IRootState) =>
  state.courses.selectedTaskRequestId && state.courses.taskRequestsMap[state.courses.selectedTaskRequestId]
    ? state.courses.taskRequestsMap[state.courses.selectedTaskRequestId]
    : null;

const selectorTaskQuestions = (state: IRootState) => state.courses.taskQuestions; //todo FFS EMPTY?

//todo FFS ar tb golit mapul inainte
const retrieveResponses = (state: IRootState) => {
  if (state.taskResponses.taskResponseMap != null) {
    return Object.values(state.taskResponses.taskResponseMap);
  }
  return ReviewerTaskPanel.EMPTY_RESPONSES;
};

const crtUser = (state: IRootState) => {
  if (state.authentication && state.authentication.user) {
    return state.authentication.user.id;
  }
  return null;
};

const selectorCrtTaskRequestFromResponses = createSelector([retrieveResponses], (responses) => {
  if (responses == null || responses.length === 0) return null;
  return responses[0].task;
});
const selectorCrtCourseFromResponses = createSelector([retrieveResponses], (responses) => {
  if (responses == null || responses.length === 0) return null;
  return responses[0].task.course;
});
const selectorTaskReqEvaluations = (state: IRootState) => {
  if (state.courses.taskRequestEvaluationsMap != null) {
    const vals = Object.values(state.courses.taskRequestEvaluationsMap);
    return vals.length ? vals : ReviewerTaskPanel.EMPTY_EVALUATIONS;
  }
  return null;
};

const selectorTaskGradedCnt = createSelector([selectorTaskReqEvaluations], (evals) => {
  return evals ? evals.length : 0;
});

export type QuestionSummaryMap = { [id: string]: QuestionSummary };
export type QuestionSummary = {
  question: QuestionBasic;
} & TaskSummary;
export type TaskSummary = {
  [PENDING]: number;
  [STARTED]: number;
  [SUBMITTED]: number;
  [GRADED]: number;
};

const getTotalQuestionsGradedCount = (qInfo: QuestionSummaryMap) => {
  if (qInfo != null) {
    return Object.values(qInfo).reduce((accumulator, questionSummary) => accumulator + questionSummary[GRADED], 0);
  }
  return 0;
};

const SUMMARY_INIT = { [PENDING]: 0, [STARTED]: 0, [SUBMITTED]: 0, [GRADED]: 0 } as TaskSummary;
const retrieveQuestionsData = createSelector(
  [selectorCrtTaskRequest, selectorCrtTaskRequestFromResponses, retrieveResponses, selectorTaskReqEvaluations, crtUser],
  (currentTaskRequest, currentTaskRequestFromResponses, responses, taskEvaluations, userId) => {
    // console.log(
    //   '###########retrieveQuestionsData called ',
    //   currentTaskRequest,
    //   currentTaskRequestFromResponses,
    //   responses,
    //   taskEvaluations,
    //   userId
    // );
    if (
      responses === ReviewerTaskPanel.EMPTY_RESPONSES ||
      (currentTaskRequest === null && currentTaskRequestFromResponses === null) ||
      taskEvaluations == null ||
      userId === null
    )
      return null;

    const resp2EvalMap = {} as TaskResponseToEvaluationMap;
    taskEvaluations.forEach((evaluation) => {
      resp2EvalMap[evaluation.responseId] = resp2EvalMap[evaluation.responseId]
        ? [...resp2EvalMap[evaluation.responseId], evaluation]
        : [evaluation];
    });
    const crtTaskReq = currentTaskRequest ? currentTaskRequest : currentTaskRequestFromResponses;
    const taskEndTimeMillis = new Date(crtTaskReq.endtime).getTime();
    const taskTotalTimeMIllis = crtTaskReq.options.totaltime * 60 * 1000;

    let questionsSummaries = {} as QuestionSummaryMap;
    let taskSummary = { ...SUMMARY_INIT };
    let studentResponses = responses.map((response) => {
      let diff = 0;
      let entry = {
        id: response.id,
        studentId: response.user ? response.user.id : '',
        student: response.user ? response.user.username : '',
        question: response.question ? response.question.text : '',
        late: response.submissiontime ? new Date(response.submissiontime).getTime() - taskEndTimeMillis : null,
        exceeded: response.submissiontime
          ? (diff = new Date(response.submissiontime).getTime() - new Date(response.starttime).getTime()) > taskTotalTimeMIllis
            ? diff
            : null
          : null,
        // status: getResponseStateAsReviewer(response),
        status: getResponseStateEvalAsReviewer(response, resp2EvalMap[response.id], userId),
        fullResponse: response,
      };

      if (response.question) {
        let summary = questionsSummaries[response.question.id]
          ? questionsSummaries[response.question.id]
          : { question: response.question, ...SUMMARY_INIT };
        summary[entry.status] = 1 + summary[entry.status];
        questionsSummaries[response.question.id] = summary;
      }
      taskSummary[entry.status] = 1 + taskSummary[entry.status];
      return entry;
    });
    return {
      studentResponses,
      questionsSummaries,
      taskSummary,
    };
  }
);

const mapStateToProps = (state: IRootState) => {
  console.log('ReviewerTaskPanel mapStateToProps ', state);
  return {
    authentication: state.authentication,
    currentCourseFromMemory: selectorCrtCourse(state),
    currentCourseFromResponses: selectorCrtCourseFromResponses(state),
    currentTaskRequestFromMemory: selectorCrtTaskRequest(state),
    currentTaskRequestFromResponses: selectorCrtTaskRequestFromResponses(state),
    responses: retrieveResponses(state),
    questionsData: retrieveQuestionsData(state),
    evaluationsCnt: selectorTaskGradedCnt(state),
    taskRubric: state.courses.taskRubric,
    taskQuestions: selectorTaskQuestions(state),
    windowHeight: state.utils.windowHeight,
  };
};

const mapDispatchToProps = {
  courseSelected,
  getCourseTaskRequests,
  taskRequestSelected,
  getResponsesForTaskRequest,
  setStudentAccessToTaskGrades,
  setTaskAvailability,
  getTaskRequestEvaluations,
  getRubricForTask,
  getTaskQuestions,
  setGlobalAlert,
  assignTaskQuestions2Students,
  resetTask,
  resetTaskResponse,
  updateTaskRequest,
  updateRubric,
  replaceBankQuestions2Task: placeBankQuestions2Task,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ReviewerTaskPanel);
